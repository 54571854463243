import Product from "./Product";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getAllProducts,
  getProduct,
  getProductsByIds,
} from "../redux/actions/product";
import ProductLoading from "./ProductLoading";
import { toast } from "react-toastify";

const RecentlyViewedList = () => {
  const { user, isUserLoading, isLoggedIn } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const { products } = useSelector((state) => state.product);
  const [isLoading, setIsLoading] = useState(true);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    dispatch(getAllProducts());
  }, []);

  //console.log(displayedProducts);

  useEffect(() => {
    //console.log(isUserLoading);
    if (isUserLoading === false) {
      if (user) {
        if (user.recently_viewed?.length > 3) {
          getProductsByIds(user.recently_viewed)
            .then((fetchedProducts) => {
              //console.log("Fetched Products:", fetchedProducts);
              setRecentlyViewedProducts(fetchedProducts);
              // Do something with the fetched products
            })
            .catch((error) => {
              // Handle errors
              console.error("Error:", error);
            });
        } else {
          //console.log("No user");
          setDisplayedProducts(products?.slice(0, 6));
          setTitle("Recommended for you");
        }
      } else {
        //console.log("No user");
        setDisplayedProducts(products?.slice(0, 6));
        setTitle("Recommended for you");
      }
    }
  }, [isUserLoading, products]);

  useEffect(() => {
    if (recentlyViewedProducts.length > 4) {
      setDisplayedProducts(
        recentlyViewedProducts.length > 6
          ? recentlyViewedProducts?.slice(0, 6)
          : recentlyViewedProducts
      );
      setTitle("Recently viewed");
    }

    //console.log(displayedProducts);
    if (displayedProducts?.length !== 0 && displayedProducts !== undefined) {
      //console.log(displayedProducts);
      setIsLoading(false);
    }
  }, [recentlyViewedProducts, displayedProducts]);

  // const displayedProducts = user
  //   ? recentlyViewedProducts.length > 12
  //     ? recentlyViewedProducts?.slice(0, 12)
  //     : recentlyViewedProducts
  //   : products?.slice(0, 12);

  return (
    <div className="self-stretch box-border w-[68%] overflow-hidden shrink-0 flex flex-col items-start justify-start py-9 px-14 gap-[24px] pl-[4%] pr-[4%] border-r-[2px] border-solid border-black border-b-[2px] lg:gap-[16px] lg:pt-6 lg:pb-6 lg:box-border lg:w-[70%] lg:pl-[4%] lg:pr-[4%] md:pl-[4%] md:pr-[4%] md:[border-right:0] md:w-full sm:self-stretch sm:w-auto sm:gap-[10px] sm:pt-6 sm:pb-5 sm:box-border sm:pl-[4%] sm:pr-[4%] sm:[border-right:0] mq840:self-stretch mq840:w-auto mq840:gap-[10px] mq840:pt-6 mq840:pb-5 mq840:box-border mq840:pl-[4%] mq840:pr-[4%] mq840:[border-right:0]">
      {isLoading ? (
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="skeleton-loader flex-1 max-w-[40%] min-h-[30px] mq840:min-h-[24px] sm:min-h-[24px]" />
        </div>
      ) : (
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="relative leading-[120%] sm:text-md mq840:text-md">
            {title}
          </div>
          <a
            className="[text-decoration:none] relative text-md leading-[130%] text-[inherit] [transition:0.5s] cursor-pointer hover:[text-decoration:underline] hover:[transition:0.2s] lg:text-regular"
            id="recentlyviewed_viewall_button"
          >
            View all
          </a>
        </div>
      )}

      {isLoading ? (
        <div className="self-stretch grid flex-row items-center justify-start gap-[42px] grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))] [row-gap:36px] lg:grid lg:grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))] lg:[row-gap:24px] lg:max-w-[96%] md:flex md:[column-gap:24px] md:max-w-full md:overflow-x-scroll md:pt-1.5 md:pb-1 md:pr-0 md:pl-[15px] sm:flex sm:max-w-full sm:[column-gap:16px] sm:[justify-content:start] sm:overflow-scroll sm:pt-1.5 sm:pb-1 sm:pr-0 sm:pl-[15px] mq840:flex mq840:[column-gap:24px] mq840:overflow-y-visible mq840:max-w-full mq840:overflow-x-scroll mq840:pt-1.5 mq840:pb-1 mq840:pr-0 mq840:pl-3">
          <ProductLoading />
          <ProductLoading />
          <ProductLoading />
          <ProductLoading />
          <ProductLoading />
          <ProductLoading />
        </div>
      ) : (
        <div className="self-stretch grid flex-row items-center justify-start gap-[42px] grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))] [row-gap:36px] lg:grid lg:grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))] lg:[row-gap:24px] lg:max-w-[96%] md:flex md:[column-gap:24px] md:max-w-full md:overflow-x-scroll md:pt-1.5 md:pb-1 md:pr-0 md:pl-[15px] sm:flex sm:max-w-full sm:[column-gap:16px] sm:[justify-content:start] sm:overflow-scroll sm:pt-1.5 sm:pb-1 sm:pr-0 sm:pl-[15px] mq840:flex mq840:[column-gap:24px] mq840:overflow-y-visible mq840:max-w-full mq840:overflow-x-scroll mq840:pt-1.5 mq840:pb-1 mq840:pr-0 mq840:pl-3">
          {displayedProducts?.map(
            (product, index) =>
              product.status.state === "active" && (
                <Product product={product} key={index} />
              )
          )}
        </div>
      )}
      {/* )} */}
    </div>
  );
};

export default RecentlyViewedList;
