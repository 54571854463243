import { useState, useEffect, useCallback } from "react";
import Makeanoffer from "../components/Modals/MakeAnOffer";
import OfferDeclined from "../components/Modals/OfferDeclined";
import OfferAccepted from "../components/Modals/OfferAccepted";
import OfferSent from "../components/Modals/OfferSent";
import OfferCountered from "../components/Modals/OfferCountered";
import Navbar from "../components/Navbar";
import ProductList from "../components/ProductList";
import { useNavigate, useParams } from "react-router";
import axiosInstance from "../axiosInstance";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUserCart } from "../redux/actions/cart";
import { toast } from "react-toastify";
import { getProduct, getUserOffer } from "../redux/actions/product";
import { addProductToCart, removeProductFromCart } from "../redux/actions/cart";
import { handleShipmentCost } from "../utils/ShipmentCost";
import { v4 as uuidv4 } from "uuid";
import ReactGA from "react-ga4";
import { useSearchParams, Link } from "react-router-dom";

const ProductPage = () => {
  const [isMakeanofferPopupOpen, setMakeanofferPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [productExists, setProductExists] = useState(false);
  let cart = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const map = require("../assets/new design/map.png");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [offer, setOffer] = useState(null);
  const [productCompressedImages, setProductCompressedImages] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [boughtByUser, setBoughtByUser] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const options = [
    { value: "NEW", label: "NEW", color: "newBlue" },
    { value: "USED", label: "USED", color: "usedYellow" },
    { value: "FOR PARTS", label: "FORPARTS", color: "partsRed" },
  ];
  const [formattedAddress, setFormattedAddress] = useState("");
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUserSeller, setIsUserSeller] = useState(false);

  ReactGA.send({
    hitType: "pageview",
    page: `/product/product=${product?._id}`,
    title: `${product?.title}`,
  });

  function numberWithIndianCommas(x) {
    // console.log(
    //   x.toLocaleString("en-US", { style: "currency", currency: "USD" })
    // );
    if (x) return x.toLocaleString("en-IN");
  }

  const openMakeanofferPopup = useCallback(() => {
    setMakeanofferPopupOpen(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch product data
        const productData = await getProduct(productId);
        //Check if user is seller

        const offerData = (await getUserOffer(productId)).data;
        if (offerData.lastOfferStatus) setOffer(offerData);
        console.log(offerData);
        setProduct(productData.data);
      } catch (error) {
        if (error.response) {
          // Handle server errors
          const status = error.response.status;
          switch (status) {
            default:
              toast.error("Oops, something went wrong");
              break;
          }
        } else {
          // Handle network errors
          toast.error("Couldn't reach the server");
        }
      } finally {
        // Update the loading state regardless of success or failure
        setIsLoading(false);
      }
    };

    // Call fetchData function
    fetchData();
  }, [productId]);

  useEffect(() => {
    if (cart.cart) {
      //console.log(JSON.stringify(cart));
      if (cart.cart.items.find((item) => item?.product?._id === product?._id)) {
        //console.log("Hello");
        setIsInCart(true);
      }

      //console.log(product?._id);
    }
  }, [cart, product]);

  useEffect(() => {
    if (product?.address.full) {
      const parts = product?.address.full.split(",");
      if (parts.length >= 3) {
        const lastIndex = parts.length - 2;
        const secondToLastIndex = lastIndex - 1;
        //console.log(lastIndex, secondToLastIndex);
        const result = `${parts[secondToLastIndex].trim()}, ${parts[
          lastIndex
        ].trim()}`;
        //console.log(result);

        setFormattedAddress(result); // This will contain the desired result
      }
    }

    setSelectedCondition(
      product?.condition
        ? options.find((option) => option.label === product?.condition)
        : null
    );
    // console.log(selectedCondition);
  }, [product]);

  useEffect(() => {
    //console.log(offer);
  }, [offer]);

  const handleAddToCart = async () => {
    try {
      setIsCartLoading(true);
      // Wait for the addProductToCart action to complete before setting loading to false
      await dispatch(addProductToCart(product?._id, user?.cartId));
      setIsInCart(true);
    } catch (error) {
    } finally {
      // Ensure that setIsCartLoading(false) is called even if there's an error
      setIsCartLoading(false);
    }
  };

  const handleRemoveFromCart = async () => {
    setIsCartLoading(true);
    await dispatch(removeProductFromCart(product?._id, user?.cartId));
    setIsCartLoading(false);
    setIsInCart(false);
    console.log(getUserCart(user.cartId));
  };

  const { images } = useSelector((state) => state.product);

  return (
    <>
      {product || isLoading ? (
        <div className="relative bg-text-white w-full overflow-hidden flex flex-col items-center justify-start pt-6 px-0 pb-0 box-border gap-[24px] text-left text-md text-text-white sm:pt-0 sm:gap-[16px] mq840:pt-0 font-general-text">
          <Navbar />
          <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
            {/* Code for div if item is sold */}
            {product?.status === "sold" ? (
              <div className="self-stretch hidden bg-nav-bar overflow-hidden flex flex-row items-center justify-center p-4 gap-[4px] my-0 mx-[4%]">
                <p className="relative">
                  {boughtByUser
                    ? "You bought this item on"
                    : "This listing is still available."}
                </p>
                <span
                  className="relative font-bold [text-decoration:underline]"
                  onClick={() => {
                    boughtByUser
                      ? navigate("/buying/purchaseHistory")
                      : navigate("/");
                  }}
                >
                  {boughtByUser
                    ? "View details"
                    : "View other items from this seller"}
                </span>
              </div>
            ) : offer && offer?.lastOfferStatus !== "buyer-cancelled" ? (
              // <div className="self-stretch bg-text-white overflow-hidden flex flex-col items-start justify-start text-left text-xl text-text-white font-inter my-0 mx-[4%] border-[1px] border-solid border-black">
              //   <div className="self-stretch bg-gray-200 overflow-hidden flex flex-row items-center justify-start py-3 px-4">
              //     <div className="relative text-md">
              //       {offer?.sentBy === user._id
              //         ? "Offer sent"
              //         : offer.lastOfferStatus === "declined"
              //         ? "Offer declined"
              //         : offer.lastOfferStatus === "accepted"
              //         ? "Offer accepted"
              //         : null}
              //     </div>
              //   </div>
              //   <div className="self-stretch flex flex-row items-start justify-center text-base text-black sm:flex-col sm:gap-[0px]">
              //     <div className="flex-1 flex flex-col items-start justify-start py-6 px-4 sm:flex-[unset] sm:self-stretch">
              //       <div className="self-stretch relative text-regular">
              //         {offer?.sentBy === user._id
              //           ? `We've sent your offer of ${offer.offerAmount}. If the seller does not respond within the next 48 hours
              //         or decline your offer, we’ll remove the authorisation hold
              //         from your card`
              //           : offer.lastOfferStatus === "declined"
              //           ? `You offer was declined by the seller. Please try making another offer or buying it at full price`
              //           : offer.lastOfferStatus === "accepted"
              //           ? "Your offer was accepted and your payment method has been charged. You're required to pay the remaining amount within the next 5 days to ensure the seller can ship the item in time."
              //           : null}
              //       </div>
              //     </div>
              //     <div className="flex flex-col items-start justify-start py-6 px-3 text-mini font-heading sm:self-stretch sm:w-auto sm:gap-[12px] sm:pt-0 sm:box-border">
              //       <div
              //         className="cursor-pointer self-stretch bg-primary flex flex-row text-regular items-center justify-center py-2 px-4 border-[1px] border-solid border-black"
              //         onClick={() => {
              //           if (offer?.sentBy === user._id) handleCancelOffer();
              //           else if (offer.lastOfferStatus === "declined")
              //             handleCreateNewOffer();
              //           else if (offer.lastOfferStatus === "accepted")
              //             handleCompletePayment();
              //         }}
              //       >
              //         {offer?.sentBy === user._id
              //           ? "Cancel offer"
              //           : offer.lastOfferStatus === "declined"
              //           ? "Create a new offer"
              //           : offer.lastOfferStatus === "accepted"
              //           ? "Pay remaining balance"
              //           : null}
              //       </div>
              //     </div>
              //   </div>
              // </div>

              <div className="self-stretch bg-text-white bg-gray-200 overflow-hidden flex flex-row items-center justify-between pr-2 my-0 mx-[4%] border-[1px] border-solid border-black">
                <div className="self-stretch text-black text-md overflow-hidden flex flex-row items-center justify-start py-3 px-4">
                  {offer?.sentBy === user._id
                    ? "Offer sent"
                    : offer.lastOfferStatus === "declined" ||
                      offer.lastOfferStatus === "seller-cancelled"
                    ? "Offer declined"
                    : offer.lastOfferStatus === "accepted"
                    ? "Offer accepted"
                    : offer.lastOfferStatus === "buyer-countered"
                    ? "Counter offer sent"
                    : offer.lastOfferStatus === "sent"
                    ? "Offer sent"
                    : offer.lastOfferStatus === "seller-countered"
                    ? "Offer received"
                    : null}
                </div>

                <div
                  className="cursor-pointer text-black bg-primary flex flex-row text-regular items-center justify-center py-2 px-4 border-[1px] border-solid border-black"
                  onClick={() => {
                    if (offer && offer?.lastOfferStatus !== "cancelled") {
                      setSearchParams({ offer: offer.lastOfferStatus });
                    } else setSearchParams({ offer: "new" });
                  }}
                >
                  View
                </div>
              </div>
            ) : null}
            {/* Listing starts here */}
            <div className="self-stretch flex flex-col items-center justify-start relative gap-[24px] py-0 px-[4%] text-regular sm:gap-[16px]">
              <div className="self-stretch bg-white flex flex-row items-center justify-start gap-[8px] overflow-x-scroll z-[0]">
                <div
                  className={`self-stretch flex-1 relative grow-[1] sm:h-[220px] sm:grow-[1] sm:aspect-[1.25] mq840:h-[306px] mq840:grow-[1] mq840:aspect-[1.33] ${
                    isLoading ? "" : ""
                  }`}
                >
                  {isLoading ? (
                    <div className="skeleton-loader"></div>
                  ) : (
                    <img
                      className="absolute top-[0px] left-[0px] w-full h-full object-cover"
                      alt=""
                      src={
                        Array.isArray(product?.images)
                          ? product.images[0] || null
                          : null
                      }
                    />
                  )}
                </div>
                <div className="flex flex-row items-center justify-start gap-[8px] lg:h-auto lg:flex-col sm:flex-row mq840:flex-row">
                  <div className="flex flex-col items-start justify-start gap-[8px]">
                    <div
                      className={`box-border w-[311px] h-52 flex flex-col items-start justify-start relative gap-[10px] lg:w-[225px] lg:h-[150px] md:w-[180px] md:h-[120px] sm:w-[142px] sm:h-[107px] mq840:w-[225px] mq840:h-[150px] ${
                        isLoading ? "" : ""
                      }`}
                    >
                      {isLoading ? (
                        <div className="skeleton-loader"></div>
                      ) : (
                        <img
                          className="absolute top-[0px] left-[0px] w-full h-full object-cover"
                          alt=""
                          src={
                            Array.isArray(product?.images)
                              ? product.images[1] || null
                              : null
                          }
                        />
                      )}
                      {/* <div className="my-0 mx-[!important] absolute top-[0] left-[0] bg-gray-1400 overflow-hidden flex flex-row items-center justify-center py-1 px-3 z-[1]">
                        <div className="relative mq840:text-xs">Wheels</div>
                      </div> */}
                    </div>
                    <div
                      className={`box-border w-[311px] h-52 flex flex-col items-start justify-start relative gap-[10px] lg:w-[225px] lg:h-[150px] md:w-[180px] md:h-[120px] sm:w-[142px] sm:h-[107px] mq840:w-[225px] mq840:h-[150px] ${
                        isLoading ? "" : ""
                      }`}
                    >
                      {isLoading ? (
                        <div className="skeleton-loader"></div>
                      ) : (
                        <img
                          className="absolute top-[0px] left-[0px] w-full h-full object-cover"
                          alt=""
                          src={
                            Array.isArray(product?.images)
                              ? product.images[2] || null
                              : null
                          }
                        />
                      )}
                      {/* <div className="my-0 mx-[!important] absolute top-[0] left-[0] bg-gray-1400 overflow-hidden flex flex-row items-center justify-center py-1 px-3 z-[1]">
                        <div className="relative mq840:text-xs">Wheels</div>
                      </div> */}
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-[8px]">
                    <div
                      className={`box-border w-[311px] h-52 flex flex-col items-start justify-start relative gap-[10px] lg:w-[225px] lg:h-[150px] md:w-[180px] md:h-[120px] sm:w-[142px] sm:h-[107px] mq840:w-[225px] mq840:h-[150px] ${
                        isLoading ? "" : ""
                      }`}
                    >
                      {isLoading ? (
                        <div className="skeleton-loader"></div>
                      ) : (
                        <img
                          className="absolute top-[0px] left-[0px] w-full h-full object-cover"
                          alt=""
                          src={
                            Array.isArray(product?.images)
                              ? product.images[3] || null
                              : null
                          }
                        />
                      )}
                      {/* <div className="my-0 mx-[!important] absolute top-[0] left-[0] bg-gray-1400 overflow-hidden flex flex-row items-center justify-center py-1 px-3 z-[1]">
                        <div className="relative mq840:text-xs">Wheels</div>
                      </div> */}
                    </div>
                    <div
                      className={`box-border w-[311px] h-52 flex flex-col items-start justify-start relative gap-[10px] lg:w-[225px] lg:h-[150px] md:w-[180px] md:h-[120px] sm:w-[142px] sm:h-[107px] mq840:w-[225px] mq840:h-[150px] ${
                        isLoading ? "" : ""
                      }`}
                    >
                      {isLoading ? (
                        <div className="skeleton-loader"></div>
                      ) : (
                        <img
                          className="absolute top-[0px] left-[0px] w-full h-full object-cover"
                          alt=""
                          src={
                            Array.isArray(product?.images)
                              ? product.images[4] || null
                              : null
                          }
                        />
                      )}
                      {/* <div className="my-0 mx-[!important] absolute top-[0] left-[0] bg-gray-1400 overflow-hidden flex flex-row items-center justify-center py-1 px-3 z-[1]">
                        <div className="relative mq840:text-xs">Wheels</div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div className="w-full flex flex-row gap-[8px]">
                  <div className="self-stretch flex-1 rounded-4xs min-h-[1000px] w-full skeleton-loader" />
                  <div className="self-stretch flex-0.3 rounded-4xs min-h-[1000px] w-full skeleton-loader sm:hidden mq840:hidden" />
                </div>
              ) : (
                <div className="self-stretch flex flex-row items-start justify-center z-[1] text-md text-black">
                  <div className="flex-1 box-border flex flex-col items-center justify-start min-w-[70%] border-[1px] border-solid border-black md:min-w-[60%]">
                    <div className="self-stretch bg-white flex flex-row items-center justify-start py-0 pr-4 pl-0 gap-[16px] text-regular">
                      <div
                        className={`self-stretch text-md overflow-hidden flex flex-row items-center py-5 justify-center px-5 border-r-[1px] border-solid border-black sm:px-4 ${
                          selectedCondition ? selectedCondition.color : ""
                        }`}
                      >
                        {isLoading ? (
                          <div className="skeleton-loader min-w-[50px]"></div>
                        ) : (
                          <div className="relative leading-[130%] sm:whitespace-break-spaces text-center sm:text-regular">
                            {selectedCondition.value}
                          </div>
                        )}
                      </div>
                      <div className="flex-1 flex flex-row items-center justify-start py-[16px] px-0 text-xl sm:text-title sm:py-[12px]">
                        {isLoading ? (
                          <div className="skeleton-loader min-h-[40px]"></div>
                        ) : (
                          <div className="relative leading-[130%]">
                            {product?.title}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="self-stretch bg-white hidden flex-row items-center justify-between p-6 text-regular text-gray-1200 border-t-[1px] border-solid border-black sm:flex sm:p-4 sm:gap-[8px] mq840:flex">
                      <div className="relative [text-decoration:underline] whitespace-nowrap overflow-hidden text-ellipsis leading-[130%]">
                        {product?.seller?.first_name}{" "}
                        {product?.seller?.last_name}
                      </div>
                      <div className="flex flex-row items-center justify-start gap-[4px] text-center text-md text-black">
                        <img
                          className="relative w-5 h-5 overflow-hidden shrink-0"
                          alt=""
                          src="/star-1.svg"
                        />
                        <img
                          className="relative w-5 h-5 overflow-hidden shrink-0"
                          alt=""
                          src="/star-1.svg"
                        />
                        <img
                          className="relative w-5 h-5 overflow-hidden shrink-0"
                          alt=""
                          src="/star-1.svg"
                        />
                        <img
                          className="relative w-5 h-5 overflow-hidden shrink-0"
                          alt=""
                          src="/star-1.svg"
                        />
                        <img
                          className="relative w-5 h-5 overflow-hidden shrink-0"
                          alt=""
                          src="/star-5.svg"
                        />
                        <div className="relative leading-[135%] font-medium">
                          4.5
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch bg-white hidden flex-col items-start justify-center py-4 px-6 gap-[16px] border-t-[1px] border-solid border-black sm:flex sm:px-4 mq840:flex">
                      <div className="relative leading-[130%] font-medium sm:font-regular">
                        Location
                      </div>
                      <div className="self-stretch flex flex-col items-center justify-center gap-[16px] text-regular">
                        <iframe
                          className="rounded-[1px]"
                          src={`https://maps.google.com/maps?q=${product?.address.lat}, ${product?.address.long}&z=15&output=embed`}
                          width="100%"
                          height="166px"
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                          tabIndex="-1"
                          title="temp"
                        ></iframe>
                        <div className="relative text-black leading-[130%]">
                          {formattedAddress}
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div className="self-stretch bg-white overflow-hidden flex flex-col items-start justify-start py-4 px-5 gap-[12px] border-t-[1px] border-solid border-black">
                        <div className="skeleton-loader min-w-[50px]"></div>
                        <div>
                          <div className="skeleton-loader w-[100%]"></div>
                          <div className="skeleton-loader w-[100%]"></div>
                          <div className="skeleton-loader w-[100%]"></div>
                          <div className="skeleton-loader w-[80%]"></div>
                        </div>
                      </div>
                    ) : (
                      <div className="self-stretch bg-white overflow-hidden flex flex-col items-start justify-start py-4 px-5 gap-[12px] border-t-[1px] border-solid border-black">
                        <div className="relative leading-[130%] font-medium">
                          Description
                        </div>
                        <div className="self-stretch relative text-regular leading-[140%] text-darkslategray-300">
                          {product?.description}
                        </div>
                      </div>
                    )}
                    <div className="self-stretch bg-white overflow-hidden flex flex-col items-start justify-start py-4 px-5 gap-[12px] border-t-[1px] border-solid border-black">
                      <div className="relative leading-[130%] font-medium">
                        Item specifics
                      </div>
                      <div className="self-stretch relative text-regular leading-[150%] text-darkslategray-300">
                        <p className="m-0">Manufacturer: Volkswagen</p>
                        <p className="m-0">Model: Vento</p>
                        <p className="m-0">Year: 2012</p>
                        <p className="m-0">Trim: Highline</p>
                        <p className="m-0">Fuel: Diesel</p>
                        <p className="m-0">Transmission: Automatic</p>
                        <p className="m-0">Service Records: Available</p>
                      </div>
                    </div>
                    <div className="self-stretch bg-white overflow-hidden flex flex-col items-start justify-start py-4 px-5 gap-[12px] border-t-[1px] border-solid border-black">
                      <div className="relative leading-[130%] font-medium">
                        Buyers Guide
                      </div>
                      <div className="self-stretch relative text-regular leading-[140%] text-darkslategray-300">
                        <p className="m-0">
                          Installation of these headlights is relatively easy.
                          You require two T12 screwdrivers and a trim removal
                          tool. Follow these steps
                        </p>
                        <p className="m-0">
                          Open the bonnet and find 2 T12 screws
                        </p>
                      </div>
                    </div>
                    <div className="self-stretch bg-white overflow-hidden flex flex-col items-start justify-start py-4 px-5 text-center border-t-[1px] border-solid border-black">
                      <div className="self-stretch flex flex-row items-center justify-between">
                        <div className="relative leading-[130%] font-medium">
                          Seller Ratings
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[10px] text-left text-regular">
                          <div className="flex flex-row items-start justify-start">
                            <img
                              className="relative w-5 h-5 overflow-hidden shrink-0"
                              alt=""
                              src="/star-1.svg"
                            />
                            <img
                              className="relative w-5 h-5 overflow-hidden shrink-0"
                              alt=""
                              src="/star-1.svg"
                            />
                            <img
                              className="relative w-5 h-5 overflow-hidden shrink-0"
                              alt=""
                              src="/star-1.svg"
                            />
                            <img
                              className="relative w-5 h-5 overflow-hidden shrink-0"
                              alt=""
                              src="/star-1.svg"
                            />
                            <img
                              className="relative w-5 h-5 overflow-hidden shrink-0"
                              alt=""
                              src="/star-5.svg"
                            />
                          </div>
                          <div className="relative">4.2 out of 5</div>
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start py-4 px-0 gap-[16px] text-left text-regular">
                        <div className="flex flex-col items-start justify-start">
                          <div className="flex flex-col items-start justify-start gap-[12px]">
                            <div className="flex flex-row items-center justify-start gap-[28px]">
                              <div className="relative">Positive feedback</div>
                              <div className="flex flex-row items-center justify-start gap-[4px]">
                                <div className="relative [background:linear-gradient(90.6deg,_#ffdc25,_rgba(255,_255,_255,_0))] box-border w-[116px] h-4 overflow-hidden shrink-0 border-[1px] border-solid border-black" />
                                <div className="relative">30%</div>
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-start gap-[22px]">
                              <div className="relative">Negative feedback</div>
                              <div className="flex flex-row items-center justify-start gap-[4px]">
                                <div className="relative box-border w-[116px] h-4 overflow-hidden shrink-0 border-[1px] border-solid border-black" />
                                <div className="relative">30%</div>
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-start gap-[99px]">
                              <div className="relative">Neutral</div>
                              <div className="flex flex-row items-center justify-start gap-[4px]">
                                <div className="relative box-border w-[116px] h-4 overflow-hidden shrink-0 border-[1px] border-solid border-black" />
                                <div className="relative">0%</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-black" />
                        <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                          <div className="relative leading-[130%] text-darkslategray-100">
                            Reviews as a seller(2)
                          </div>
                          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                            <div className="flex flex-row items-center justify-start gap-[16px]">
                              <div className="flex flex-row items-center justify-center gap-[16px]">
                                <div className="relative leading-[130%]">
                                  @Sart....mk
                                </div>
                                <div className="flex flex-row items-start justify-start gap-[4px]">
                                  <img
                                    className="relative w-5 h-5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/star-1.svg"
                                  />
                                  <img
                                    className="relative w-5 h-5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/star-1.svg"
                                  />
                                  <img
                                    className="relative w-5 h-5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/star-1.svg"
                                  />
                                  <img
                                    className="relative w-5 h-5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/star-1.svg"
                                  />
                                  <img
                                    className="relative w-5 h-5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/star-5.svg"
                                  />
                                </div>
                              </div>
                              <div className="relative text-xs [text-decoration:underline] leading-[130%]">
                                Maruti 800 2004 CNG fit
                              </div>
                            </div>
                            <div className="self-stretch relative text-xs leading-[150%] text-darkslategray-200">
                              Check who the seller is very carefully. If it says
                              Loop Mobile then the phone is most definitely used
                              even though they say in the phone specifications
                              that it is a new phone. When you open the box,
                              there is paperwork that specifically states that
                              it is a refurbished phone and that there is no
                              warranty on the phone.
                            </div>
                          </div>
                          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                            <div className="flex flex-row items-center justify-start gap-[16px]">
                              <div className="flex flex-row items-center justify-center gap-[16px]">
                                <div className="relative leading-[130%]">
                                  @Sart....mk
                                </div>
                                <div className="flex flex-row items-start justify-start gap-[4px]">
                                  <img
                                    className="relative w-5 h-5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/star-1.svg"
                                  />
                                  <img
                                    className="relative w-5 h-5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/star-1.svg"
                                  />
                                  <img
                                    className="relative w-5 h-5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/star-1.svg"
                                  />
                                  <img
                                    className="relative w-5 h-5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/star-1.svg"
                                  />
                                  <img
                                    className="relative w-5 h-5 overflow-hidden shrink-0"
                                    alt=""
                                    src="/star-5.svg"
                                  />
                                </div>
                              </div>
                              <div className="relative text-xs [text-decoration:underline] leading-[130%]">
                                Maruti 800 2004 CNG fit
                              </div>
                            </div>
                            <div className="self-stretch relative text-xs leading-[150%] text-darkslategray-200">
                              Check who the seller is very carefully. If it says
                              Loop Mobile then the phone is most definitely used
                              even though they say in the phone specifications
                              that it is a new phone. When you open the box,
                              there is paperwork that specifically states that
                              it is a refurbished phone and that there is no
                              warranty on the phone.
                            </div>
                          </div>
                          <div className="relative text-xs [text-decoration:underline] leading-[130%] text-mediumslateblue">
                            See all Reviews(02)
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 bg-background flex flex-col items-center justify-start max-w-[30%] text-title border-t-[1px] border-solid border-black border-r-[1px] border-b-[1px] sm:hidden mq840:hidden">
                    <div className="self-stretch bg-white flex flex-col items-end justify-center py-4 px-5 gap-[24px] text-right">
                      <div className="flex flex-col items-end justify-center gap-[12px]">
                        <div className="relative leading-[130%]">
                          &#8377;
                          {product
                            ? numberWithIndianCommas(
                                product?.cost ? product?.cost : product?.price
                              )
                            : null}
                        </div>
                        <div className="flex flex-col items-end justify-center gap-[4px] text-left text-regular font-inter">
                          <div className="relative">
                            Free delivery to Bhopal, 462026
                          </div>
                          <div className="relative">
                            <span>{`Arrives by `}</span>
                            <b>Nov 29</b>
                          </div>
                          <div className="relative [text-decoration:underline]">
                            Change
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-end justify-center gap-[12px] text-left text-regular">
                        <button
                          className="self-stretch cursor-pointer rounded-sm bg-primary flex flex-row items-center justify-center py-3 px-[52px] border-[1px] border-solid border-black"
                          onClick={() => {
                            if (user) {
                              try {
                                if (isCartLoading) {
                                  // Do nothing while the cart is loading
                                } else {
                                  if (isInCart) {
                                    handleRemoveFromCart(product?._id);
                                  } else {
                                    handleAddToCart();
                                  }
                                }
                              } catch (error) {
                                console.error("Error in onClick:", error);
                              }
                            } else {
                              navigate("/signin");
                            }
                            // if (user) {
                            //   console.log("here");
                            //   handleAddToCart();
                            //   console.log("here 2");
                            // } else navigate("/signin");
                          }}
                        >
                          {isCartLoading ? (
                            <div className="loader"></div>
                          ) : (
                            <a className="relative text-regular leading-[140%]">
                              {isInCart ? "Remove from cart" : "Add to cart"}
                            </a>
                          )}
                        </button>
                        <button
                          className="cursor-pointer py-3 px-[52px] bg-snow self-stretch rounded-sm flex flex-row items-center justify-center border-[1px] border-solid border-black"
                          id="make_offer_button"
                          onClick={() => {
                            if (
                              offer &&
                              offer?.lastOfferStatus !== "buyer-cancelled"
                            ) {
                              setSearchParams({ offer: offer.lastOfferStatus });
                            } else setSearchParams({ offer: "new" });
                          }}
                        >
                          {/* <Link to="?modal-type=offer-1">Make an offer</Link> */}
                          <a className="relative text-regular leading-[140%] font-general-text text-black text-left">
                            {offer &&
                            offer?.lastOfferStatus !== "buyer-cancelled"
                              ? "View offer status"
                              : "Make an Offer"}
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="self-stretch bg-white flex flex-row items-center justify-between p-6 text-regular text-gray-1200 border-t-[1px] border-solid border-black">
                      <div className="relative [text-decoration:underline] max-w-[calc(100%-150px)] whitespace-nowrap overflow-hidden text-ellipsis leading-[130%]">
                        {product?.sellerId?.first_name}{" "}
                        {product?.sellerId?.last_name}
                      </div>
                      <div className="flex flex-row items-center justify-start gap-[4px] text-center text-md text-black">
                        <img
                          className="relative w-5 h-5 overflow-hidden shrink-0"
                          alt=""
                          src="/star-1.svg"
                        />
                        <img
                          className="relative w-5 h-5 overflow-hidden shrink-0"
                          alt=""
                          src="/star-1.svg"
                        />
                        <img
                          className="relative w-5 h-5 overflow-hidden shrink-0"
                          alt=""
                          src="/star-1.svg"
                        />
                        <img
                          className="relative w-5 h-5 overflow-hidden shrink-0"
                          alt=""
                          src="/star-1.svg"
                        />
                        <img
                          className="relative w-5 h-5 overflow-hidden shrink-0"
                          alt=""
                          src="/star-5.svg"
                        />
                        <div className="relative leading-[135%] font-medium">
                          4.5
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch bg-white flex flex-col items-start justify-center py-4 px-5 gap-[12px] text-md border-t-[1px] border-solid border-black">
                      <div className="relative leading-[130%] font-medium">
                        Location
                      </div>
                      <div className="self-stretch flex flex-col items-center justify-center gap-[16px] text-regular">
                        <div className="flex flex-col self-stretch border-[1px] border-black border-solid rounded-sm">
                          <iframe
                            className="rounded-[1px]"
                            src={`https://maps.google.com/maps?q=${product?.address.lat}, ${product?.address.long}&z=15&output=embed`}
                            width="100%"
                            height="166px"
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            tabIndex="-1"
                            title="temp"
                          ></iframe>
                        </div>
                        <div className="relative text-black leading-[130%]">
                          {formattedAddress}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ProductList title={"Recommended Listings"} />
          <div
            className={`hidden bg-white box-border w-[100%] overflow-hidden flex-col items-start justify-start pt-2 px-4 pb-3 gap-[8px] text-left font-regular fixed border-t-[2px] border-solid border-primary bottom-[0px] left-[0px] z-[4] ${
              isMakeanofferPopupOpen ? "" : "sm:flex mq840:flex"
            }`}
          >
            <div className="flex flex-row items-end justify-start gap-[5px]">
              <div className="text-black leading-[130%] text-title font-medium">
                &#8377;
                {product
                  ? numberWithIndianCommas(
                      product?.cost ? product?.cost : product?.price
                    )
                  : null}
              </div>
              <a className="pb-1 text-black text-regular leading-[130%]">
                free shipping
              </a>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-regular gap-[12px] ">
              <div
                className="cursor-pointer flex-1 rounded-sm bg-primary overflow-hidden flex flex-row items-center justify-center py-4 text-black border-[1px] border-solid border-black sm:py-3"
                onClick={() => {
                  if (user) {
                    try {
                      if (isCartLoading) {
                        // Do nothing while the cart is loading
                      } else {
                        if (isInCart) {
                          handleRemoveFromCart(product?._id);
                        } else {
                          handleAddToCart();
                        }
                      }
                    } catch (error) {
                      console.error("Error in onClick:", error);
                    }
                  } else {
                    navigate("/signin");
                  }
                }}
              >
                {isCartLoading ? (
                  <div className="small-loader"></div>
                ) : (
                  <a className="relative text-regular">
                    {isInCart ? "Remove from cart" : "Add to cart"}
                  </a>
                )}
              </div>
              <div
                className="flex-1 rounded-sm bg-white overflow-hidden flex flex-row items-center justify-center py-4  beading-[130%] text-black border-[1px] border-solid border-black sm:py-3"
                onClick={openMakeanofferPopup}
              >
                Make an offer
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container mx-auto ">
          <h1 className="text-red-400 text-3xl">
            The Product You are trying to access, Does not Exist.
          </h1>
        </div>
      )}
      {/* {isMakeanofferPopupOpen && (
        <PortalPopup overlayColor="rgba(46, 46, 46, 0.8)" placement="Centered">
          <Makeanoffer
            product={product}
            image={productCompressedImages?.data[0]}
            onClose={setMakeanofferPopupOpen}
          />
        </PortalPopup>
      )} */}

      <Makeanoffer
        product={product}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        open={searchParams.get("offer") === "new" ? true : false}
        data={null}
        offer={offer}
        setOffer={setOffer}
      />

      <OfferSent
        product={product}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        open={
          searchParams.get("offer") === "sent" ||
          searchParams.get("offer") === "buyer-countered"
            ? true
            : false
        }
        offer={offer}
        type={"buyer"}
      />

      <OfferAccepted
        product={product}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        open={searchParams.get("offer") === "accepted" ? true : false}
        offer={offer}
      />

      <OfferDeclined
        product={product}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        open={
          searchParams.get("offer") === "declined" ||
          searchParams.get("offer") === "seller-cancelled"
            ? true
            : false
        }
        offer={offer}
      />
      <OfferCountered
        product={product}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        open={searchParams.get("offer") === "seller-countered" ? true : false}
        offer={offer}
        type={"buyer"}
      />
    </>
  );
};

export default ProductPage;

export const loader = ({ request }) => {
  const queryParams = new URL(request.url).searchParams;
  const modalType = queryParams.get("modal-type");
  if (modalType === "offer-1") {
    return {
      modalType: "offer-1",
    };
  }
  return null;
};
