import { useMemo } from "react";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { itemSpecifics } from "../redux/actions/category";

const DropdownMultiSelect = ({
  info,
  options,
  type,
  setInfo,
  outsideClick,
  title,
  previous,
  itemToEdit,
}) => {
  const dispatch = useDispatch();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  //const []
  const { item_specifics } = useSelector((state) => state.category);
  const [isClicked, setIsClicked] = useState(false);
  const [finalInput, setFinalInput] = useState("");
  const [isBackEnabled, setIsBackEnabled] = useState(false);
  const [previousOption, setPreviousOption] = useState(null);
  const [previousOptions, setPreviousOptions] = useState(null);
  const [previousOptionSelected, setPreviousOptionSelected] = useState(null);
  const [previousOptionsSelected, setPreviousOptionsSelected] = useState(null);
  const [optionsHistory, setOptionsHistory] = useState([]);
  const [didFinalInputChange, setDidFinalInputChange] = useState(false);
  let nextId = 0;
  const handleDropdownBlur = () => {
    setIsClicked(false);
  };
  const dropdownRef = useRef(null);

  const [isInputSet, setIsInputSet] = useState(false);

  useEffect(() => {
    if (options) {
      setDropdownOptions([options]);
    }
  }, [options]);

  // useEffect(() => {}, [dropdownOptions]);

  useEffect(() => {
    optionsHistory.length > 0 && setIsInputSet(true);
  }, [optionsHistory]);

  useEffect(() => {
    console.log(dropdownOptions);
  }, [dropdownOptions]);

  useEffect(() => {
    console.log(didFinalInputChange);
  }, [didFinalInputChange]);

  //detect outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the DropdownMultiSelect component
        setIsClicked(false);

        // Add any other logic you want to perform on outside click
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      className="flex flex-1 w-[100%] flex-col items-start justify-start gap-[8px] text-left text-regular font-general-text flex-1"
    >
      <div className="flex-1 flex flex-row items-center justify-start">
        <p
          className="m-0 relative leading-[135%] font-medium"
          id="primary_id_placeholder"
        >
          {title}
        </p>
      </div>
      <div
        className="self-stretch w-full relative inline-block flex flex-col items-start justify-start"
        id="dropdown_menu"
        //onBlur={handleDropdownBlur}
      >
        <div
          className={`self-stretch noselect cursor-pointer overflow-hidden bg-text-white flex flex-row items-center justify-between p-[9px] border-[1px] border-solid border-black ${
            isClicked ? "rounded-t-sm" : "rounded-sm"
          }`}
          onClick={() => {
            // console.log("e.value");
            setFinalInput("");
            setIsClicked(!isClicked);
          }}
          tabindex="0"
          //onBlur={handleDropdownBlur}
        >
          <div
            className={`relative leading-[135%] max-w-[calc(100%-20px)] whitespace-nowrap overflow-hidden text-ellipsis text-regular ${
              finalInput && item_specifics ? "text-black" : "text-gray-place"
            }`}
          >
            {optionsHistory.length > 0
              ? optionsHistory.map((item, index) => (
                  <span key={index}>
                    {item.option.name}
                    {index < optionsHistory.length && " > "}
                  </span>
                ))
              : !finalInput
              ? "Select Category"
              : null}
            {/* {finalInput && optionsHistory.length > 0 && " > "} */}
            {finalInput && <span>{finalInput}</span>}
          </div>
          {/* <div className="flex flex-row gap-4px"></div> */}

          {finalInput &&
          title === "Category" &&
          (!item_specifics || didFinalInputChange) ? (
            <div className="large-loader" />
          ) : (
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/chevron-down.svg"
            />
          )}

          <img
            className="relative w-6 h-6 overflow-hidden shrink-0 object-cover hidden"
            alt=""
            src="/chevron-up.svg"
          />
        </div>
        <div
          className={`w-[100%] top-[44px] z-[10] absolute rounded-t-none rounded-b-sm bg-text-white overflow-hidden flex flex-col items-start justify-start border-top-[1px] border-solid border-black ${
            isClicked ? "block dropdown " : "hidden"
          }`}
        >
          {optionsHistory.length > 0 && (
            <div
              className="cursor-pointer self-stretch bg-text-white flex flex-row items-center gap-[8px] mt-[-1px] justify-start py-2.5 px-3 border-[1px] border-solid border-black"
              onClick={() => {
                if (optionsHistory.length > 0) {
                  setOptionsHistory(
                    // remove last element from array as long as it's not empty
                    optionsHistory.slice(0, optionsHistory.length - 1)
                  );
                  setDropdownOptions(
                    // remove last element from array as long as it's not empty
                    dropdownOptions.slice(0, dropdownOptions.length - 1)
                  );
                }
                setFinalInput("");

                //setDropdownOptions(previousOptions);
                //setPreviousOptionSelected(previousOption);
                //setPreviousOption(previousOptionSelected);
                //setPreviousOptions(previousOptionsSelected);
              }}
            >
              <img
                className="relative w-[11.7px] h-[11.7px] object-cover"
                alt=""
                src="/back.svg"
              />
              <div className="relative leading-[135%]">
                {/* {console.log(optionsHistory.length - 1)} */}
                {optionsHistory[optionsHistory.length - 1].option.name}
              </div>
            </div>
          )}
          {dropdownOptions.length > 0 &&
            dropdownOptions[dropdownOptions.length - 1]?.map((e, i) =>
              e.sub_categories.length === 0 ? (
                <div
                  className="cursor-pointer bg-text-white block box-border self-stretch flex flex-row items-start justify-start py-2.5 px-3 mt-[-1px] border-[1px] border-solid border-black [transition:0.2s] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
                  onClick={() => {
                    // console.log(e.value);
                    // console.log("e.value");
                    //setIsClicked(!isClicked);
                    setInfo({
                      ...info,
                      [itemToEdit]: e._id,
                    });
                    setFinalInput(e.name);
                    setIsInputSet(true);
                    setIsClicked(false);

                    dispatch(itemSpecifics(e._id));
                  }}
                >
                  <div className="relative leading-[135%]">{e.name}</div>
                </div>
              ) : e.sub_categories.length > 0 ? (
                <div
                  className="cursor-pointer bg-text-white block box-border self-stretch flex flex-row items-start justify-between py-2.5 px-3 mt-[-1px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
                  onClick={() => {
                    setDropdownOptions([...dropdownOptions, e.sub_categories]);
                    setOptionsHistory([...optionsHistory, { option: e }]);
                    //setPreviousOptions(dropdownOptions);
                    //setPreviousOptionsSelected(previousOptions);
                    //setPreviousOption(e.name);
                    //setPreviousOptionSelected(previousOption);[]
                    //setIsBackEnabled(true);
                  }}
                >
                  <div className="relative leading-[135%]">{e.name}</div>
                  <img
                    className="relative w-5 h-5 overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/chevron-right.svg"
                  />
                </div>
              ) : (
                <div className="hidden bg-text-white block box-border self-stretch flex flex-row items-start justify-start py-2.5 px-3 mt-[-1px] border-[1px] border-solid border-black">
                  <div className="relative leading-[135%]">Electronics</div>
                </div>
              )
            )}

          <div className="hidden bg-text-white block box-border self-stretch flex flex-row items-start justify-start py-2.5 px-3 mt-[-1px] border-[1px] border-solid border-black">
            <div className="relative leading-[135%]">Electronics</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownMultiSelect;
