import { useSelector } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState, useMemo, useRef } from "react";

const OfferCountered = ({
  product,
  type,
  searchParams,
  setSearchParams,
  open,
  locked,
  children,
  offer,
  ...props
}) => {
  const { user } = useSelector((state) => state.auth);
  const [amount, setAmount] = useState("");
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [error, showerror] = useState("");
  const [isCounterLoading, setIsCounterLoading] = useState(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isDeclineLoading, setIsDeclineLoading] = useState(false);
  const [step, setStep] = useState("1");
  const [stepStatus, setStepStatus] = useState("");
  const modalRef = useRef(null);
  const [isSendCounterLoading, setIsSendCounterLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleSendCounter = async () => {
    try {
      setIsSendCounterLoading(true);
      const response = await axiosInstance.post("/product/counteroffer", {
        offerAmount: amount,
        arrayOfferHistoryId: offer.arrayOfferHistoryId,
      });
      //console.log(response);

      if (response?.data?.success) {
        setIsCounterLoading(false);
        setStep("4");
      } else {
        showerror(response.data.data);
        console.log(response?.data);
      }
    } catch (err) {
      //setIsPaymentProcessing(false);
      setIsCounterLoading(false);
      console.log(err);
    }
  };

  // Eventlistener: trigger onclose when cancel detected
  const onClose = useCallback((e) => {
    if (!isCounterLoading) {
      setSearchParams({});
      setStep("1");
      setStepStatus("");
    }
  }, []);

  // Eventlistener: trigger onclose when cancel detected
  const onCancel = useCallback(
    (e) => {
      e.preventDefault();
      if (!locked) onClose();
    },
    [locked, onClose]
  );

  const handleCounter = async () => {
    try {
      setIsCounterLoading(true);
      //console.log(product);
      console.log(offer.arrayOfferHistoryId);
      const response = await axiosInstance.post("/product/canceloffer", {
        arrayOfferHistoryId: offer.arrayOfferHistoryId,
      });
      console.log(response);

      if (response?.data?.success) {
        setIsCounterLoading(false);
        setSearchParams({});
      } else console.log(response?.data);
    } catch (err) {
      setIsCounterLoading(false);
      console.log(err);
    }
  };

  const handleAccept = async () => {
    try {
      setIsAcceptLoading(true);
      //console.log(product);
      console.log(offer.arrayOfferHistoryId);
      const response = await axiosInstance.post("/product/acceptoffer", {
        arrayOfferHistoryId: offer.arrayOfferHistoryId,
      });
      console.log(response);

      if (response?.data?.success) {
        setStep("3");
      }
      setIsAcceptLoading(false);
    } catch (err) {
      setIsAcceptLoading(false);
      console.log(err);
    }
  };

  const handleDecline = async () => {
    try {
      setIsDeclineLoading(true);
      //console.log(product);
      console.log(offer.arrayOfferHistoryId);
      const response = await axiosInstance.post("/product/declineoffer", {
        arrayOfferHistoryId: offer.arrayOfferHistoryId,
      });
      console.log(response);

      if (response?.data?.success) {
        setIsDeclineLoading(false);
        setSearchParams({});
      } else console.log(response?.data);
    } catch (err) {
      setIsDeclineLoading(false);
      console.log(err);
    }
  };

  return (
    <div
      className={`w-full self-stretch fixed inset-0 z-10 backdrop-blur-10 ${
        open ? "" : "hidden"
      }`}
      ref={modalRef}
    >
      <div
        className="w-full block fixed z-9 h-[100%] top-[0px] left-[0px] absolute bg-black opacity-80 "
        onClick={() => {
          onClose();
        }}
      ></div>
      <div className="w-[450px] absolute z-10 top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] rounded-sm bg-white box-border overflow-hidden flex flex-col items-center justify-start py-6 px-5 gap-[16px] text-title text-black font-general-text border-[1px] border-solid border-black">
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "1" ? "" : "hidden"
          }`}
        >
          <h3 className="self-stretch text-center justify-start font-medium m-0 relative text-title leading-[135%]">
            {type === "buyer"
              ? "Counter offer received "
              : type === "seller"
              ? "New offer"
              : null}
          </h3>
          <section className="self-stretch flex flex-col items-start justify-start gap-[12px] text-left text-regular">
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Seller's offer:"
                  : type === "seller"
                  ? "From:"
                  : null}
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px] whitespace-nowrap">
                {type === "buyer"
                  ? `₹${Number(offer?.amount).toLocaleString("en-IN")}`
                  : type === "seller"
                  ? `${offer?.buyerFirstName} ${offer?.buyerLastName}`
                  : null}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Shipping: "
                  : type === "seller"
                  ? "Buyer's rating"
                  : null}
              </div>
              <div className="flex-1 flex flex-row items-center gap-[4px] relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Free shipping"
                  : type === "seller"
                  ? offer?.buyerRating
                    ? `${offer.buyerRating}`
                    : "No rating"
                  : null}
                {offer?.buyerRating ? (
                  <img className="w-4 relative h-4" alt="" src="/star-1.svg" />
                ) : null}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Total:"
                  : type === "seller"
                  ? "Offer amount"
                  : null}
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px] whitespace-nowrap">
                ₹{Number(offer?.amount).toLocaleString("en-IN")}
              </div>
            </div>
          </section>
          <div className="self-stretch flex flex-row items-start justify-start gap-[16px] sm:flex-col">
            <button
              className="cursor-pointer p-3 bg-text-white flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-gainsboro hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400 sm:self-stretch"
              onClick={() => {
                setStep("2");
                setStepStatus("Declined");
              }}
            >
              <div className="flex-1 relative text-regular leading-[135%] text-black text-center">
                Decline
              </div>
            </button>
            <button
              className="cursor-pointer p-3 bg-black flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400 sm:flex-[unset] sm:self-stretch"
              onClick={() => {
                setStep("2");
                setStepStatus("Countered");
              }}
            >
              <div className="flex-1 relative text-regular leading-[135%] text-white text-center ">
                Counter
              </div>
            </button>
            <button
              className="cursor-pointer p-3 bg-text-white flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-gainsboro hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400 sm:self-stretch"
              onClick={() => {
                setStep("2");
                setStepStatus("Accepted");
              }}
            >
              <div className="flex-1 relative text-regular leading-[135%] text-black text-center ">
                Accept
              </div>
            </button>
          </div>
        </div>

        {/* Case - Countered*/}
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "2" && stepStatus === "Countered" ? "" : "hidden"
          }`}
        >
          <div className="self-stretch flex flex-col items-center justify-start">
            <h3 className="m-0 relative text-center text-title leading-[135%] font-medium">
              Send counter offer
            </h3>
            {type === "buyer" && (
              <span className="relative text-xs text-gray leading-[135%]">
                {`(${offer?.attempts} attempts)`}
              </span>
            )}
          </div>
          <section className="self-stretch flex flex-col items-center justify-end gap-[12px] text-left text-regular">
            <div className="self-stretch flex flex-row items-center justify-center gap-[2px] sm:flex-wrap">
              <span className="relative leading-[130%]">
                {type === "buyer"
                  ? "Seller's offer:"
                  : type === "seller"
                  ? "Buyer's offer:"
                  : null}
              </span>
              <span className="relative leading-[130%] text-right whitespace-nowrap">
                &#8377;{Number(offer?.amount).toLocaleString("en-IN")}
              </span>
            </div>
            <span className="relative leading-[135%]">Your offer</span>
            <input
              className="self-stretch relative text-title leading-[135%] text-center focus:[outline:none] focud:relative"
              id="signin_password_input"
              placeholder="Enter your offer"
              type="tel"
              value={"₹" + Number(amount).toLocaleString("en-IN")}
              onChange={(e) => {
                showerror("");
                setAmount(e.target.value.replace(/[^\d]/g, ""));
              }}
            />
          </section>
          {error && (
            <div
              className={`self-stretch overflow-hidden text-xs flex-row items-center justify-start p-3 bg-error`}
            >
              {error}
            </div>
          )}
          <button
            className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-white text-center bg-black flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400"
            onClick={() => {
              setStep("3");
            }}
          >
            Continue
          </button>
        </div>
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "3" && stepStatus === "Countered" ? "" : "hidden"
          }`}
        >
          <h3 className="self-stretch text-center justify-start text-title font-medium m-0 relative leading-[135%]">
            Review your offer
          </h3>
          <section className="self-stretch flex flex-col items-start justify-start gap-[12px] text-left text-regular">
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                Your offer:
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px] whitespace-nowrap">
                ₹{Number(amount).toLocaleString("en-IN")}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Shipping:"
                  : type === "seller"
                  ? "WWHM fee:"
                  : null}
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Free shipping"
                  : type === "seller"
                  ? `₹${Number(amount / 20).toLocaleString("en-IN")}`
                  : null}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Total:"
                  : type === "seller"
                  ? "You will receive:"
                  : null}
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px] whitespace-nowrap">
                {type === "buyer"
                  ? `₹${Number(amount).toLocaleString("en-IN")}`
                  : type === "seller"
                  ? `₹${Number(amount - amount / 20).toLocaleString("en-IN")}`
                  : null}
              </div>
            </div>
          </section>
          <section className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full text-left text-regular text-black font-heading">
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px] max-w-full text-xs">
              <input
                className="m-0 h-4 w-4 relative rounded-sm box-border overflow-hidden shrink-0 border-[1px] border-solid border-black"
                type="checkbox"
                value=""
                onClick={() => setTermsAccepted(!termsAccepted)}
                styles={{
                  webkitOuterSpinButton: "none",
                  webkitInnerSpinButton: "none",
                  mozAppearnace: "none",
                }}
              />
              <div className="flex-1 relative leading-[135%] inline-block min-w-[230px] max-w-full">
                {`I accept the `}
                <span className="[text-decoration:underline]">
                  terms and conditions
                </span>{" "}
                and commit to this counteroffer.
              </div>
            </div>
          </section>
          <div className="self-stretch flex flex-row items-start justify-start gap-[16px] sm:flex-col">
            <button
              className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-black text-center bg-white flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400"
              onClick={() => {
                setStep("1");
              }}
              disabled={isSendCounterLoading}
            >
              Back
            </button>
            <button
              className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-white text-center bg-black flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400 disabled:bg-gray-100 disabled:cursor-default"
              onClick={() => {
                handleSendCounter();
              }}
              disabled={!termsAccepted || isSendCounterLoading}
            >
              {isSendCounterLoading ? <div className="loader" /> : "Send"}
            </button>
          </div>
        </div>
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "4" && stepStatus === "Countered" ? "" : "hidden"
          }`}
        >
          <h3 className="self-stretch text-center justify-start font-medium m-0 text-title relative leading-[135%]">
            Counter offer sent
          </h3>
          <section className="self-stretch relative text-regular leading-[130%] text-black text-left">
            <span>{`Please wait upto 48 hours for the ${
              type === "buyer" ? "seller" : "buyer"
            } to respond, we’ll notify you once they do.`}</span>
          </section>
          <button
            className="self-stretch cursor-pointer p-3 bg-black flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400"
            onClick={() => {
              onClose();
            }}
          >
            <a className="flex-1 relative text-regular leading-[135%] text-white text-center ">
              Close
            </a>
          </button>
        </div>

        {/* Case - Declined*/}
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "2" && stepStatus === "Declined" ? "" : "hidden"
          }`}
        >
          <h3 className="self-stretch text-center justify-start font-medium m-0 relative text-title leading-[135%]">
            {`Decline ${
              type === "buyer" ? "seller" : type === "seller" ? "buyer" : null
            }'s offer`}
          </h3>
          <section className="self-stretch flex flex-col items-start justify-start gap-[12px] text-left text-regular">
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Seller's offer:"
                  : type === "seller"
                  ? "Buyer's offer:"
                  : null}
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px] whitespace-nowrap">
                ₹{Number(offer?.amount).toLocaleString("en-IN")}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Shipping:"
                  : type === "seller"
                  ? "WWHM fee:"
                  : null}
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Free shipping"
                  : type === "seller"
                  ? `₹${Number(offer?.amount / 10).toLocaleString("en-IN")}`
                  : null}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Total:"
                  : type === "seller"
                  ? "Final amount"
                  : null}
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px] whitespace-nowrap">
                {type === "buyer"
                  ? `₹${Number(offer?.amount / 10).toLocaleString("en-IN")}`
                  : type === "seller"
                  ? `₹${Number(
                      offer?.amount - offer?.amount / 10
                    ).toLocaleString("en-IN")}`
                  : null}
              </div>
            </div>
          </section>
          <div className="self-stretch flex flex-row items-start justify-start gap-[16px] sm:flex-col">
            <button
              className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-black text-center bg-white flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400 disabled:bg-gray-100 disabled:cursor-default"
              onClick={() => {
                setStep("1");
              }}
            >
              Back
            </button>

            <button
              className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-white text-center bg-black flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400 disabled:bg-gray-100 disabled:cursor-default"
              onClick={() => {
                handleDecline();
              }}
              disabled={isDeclineLoading}
            >
              {isDeclineLoading ? <div className="loader" /> : "Decline"}
            </button>
          </div>
        </div>
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "3" && stepStatus === "Declined" ? "" : "hidden"
          }`}
        >
          <h3 className="self-stretch text-center justify-start font-medium m-0 relative text-title leading-[135%]">
            Offer declined
          </h3>
          <section className="self-stretch relative text-regular leading-[130%] text-black text-left">
            <a>
              {`You have accepted the seller’s counteroffer for ${Number(
                offer?.amount
              ).toLocaleString("en-IN")}. Your payment
            method has been charged for the deposit amount. Please make a
            payment for the remaining amount within the next 72 hours`}
            </a>
          </section>
          <button
            className="self-stretch cursor-pointer p-3 bg-white flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400"
            onClick={() => {
              setSearchParams({});
            }}
          >
            <a className="flex-1 relative text-regular leading-[135%] text-black text-center ">
              Close
            </a>
          </button>
        </div>

        {/* Case - Accepted*/}
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "2" && stepStatus === "Accepted" ? "" : "hidden"
          }`}
        >
          <h3 className="self-stretch text-center justify-start font-medium m-0 relative text-title leading-[135%]">
            Confirm seller's offer
          </h3>
          <section className="self-stretch flex flex-col items-start justify-start gap-[12px] text-left text-regular">
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Seller's offer:"
                  : type === "seller"
                  ? "Buyer's offer:"
                  : null}
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px] whitespace-nowrap">
                ₹{Number(offer?.amount).toLocaleString("en-IN")}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Shipping:"
                  : type === "seller"
                  ? "WWHM fee:"
                  : null}
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Free shipping"
                  : type === "seller"
                  ? `₹${Number(offer?.amount / 10).toLocaleString("en-IN")}`
                  : null}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                {type === "buyer"
                  ? "Total:"
                  : type === "seller"
                  ? "Final amount"
                  : null}
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px] whitespace-nowrap">
                {type === "buyer"
                  ? `₹${Number(offer?.amount / 10).toLocaleString("en-IN")}`
                  : type === "seller"
                  ? `₹${Number(
                      offer?.amount - offer?.amount / 10
                    ).toLocaleString("en-IN")}`
                  : null}
              </div>
            </div>
          </section>
          <span className="self-stretch relative text-regular leading-[130%] text-black text-left">
            {type === "buyer"
              ? "If you accept this offer, we will convert your deposit to a charge on your payment method."
              : type === "seller"
              ? "If you accept this offer, all other offers will be cancelled and the product will be marked as sold. The buyer will be required to pay in the next 24-48 hours."
              : null}
          </span>
          <div className="self-stretch flex flex-row items-start justify-start gap-[16px] sm:flex-col">
            <button
              className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-black text-center bg-white flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400 disabled:bg-gray-100 disabled:cursor-default"
              onClick={() => {
                setStep("1");
              }}
            >
              Back
            </button>

            <button
              className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-white text-center bg-black flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400 disabled:bg-gray-100 disabled:cursor-default"
              onClick={() => {
                handleAccept();
              }}
              disabled={isAcceptLoading}
            >
              {isAcceptLoading ? <div className="loader" /> : "Accept"}
            </button>
          </div>
        </div>
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "3" && stepStatus === "Accepted" ? "" : "hidden"
          }`}
        >
          <h3 className="self-stretch text-center justify-start font-medium m-0 relative text-title leading-[135%]">
            Offer accepted
          </h3>
          <section className="self-stretch relative text-regular leading-[130%] text-black text-left">
            <a>
              {`You have accepted the seller’s counteroffer for ${Number(
                offer?.amount
              ).toLocaleString("en-IN")}. Your payment
            method has been charged for the deposit amount. Please make a
            payment for the remaining amount within the next 72 hours`}
            </a>
          </section>
          <button
            className="self-stretch cursor-pointer p-3 bg-white flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400"
            onClick={() => {
              setSearchParams({});
            }}
          >
            <a className="flex-1 relative text-regular leading-[135%] text-black text-center ">
              Close
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferCountered;
