import { useState } from "react";
import Navbar from "../components/Navbar";

const TermsPrivacyPolicy = () => {
  return (
    <div className="relative bg-whitesmoke-100 w-full overflow-hidden flex flex-col items-start justify-start pt-6 px-0 pb-6 box-border gap-[24px] text-left text-regular text-black font-general-text sm:pt-0 mq840:pt-0 sm:box-border">
      <Navbar
        icon1="/icon1.svg"
        vector="/vector.svg"
        ellipse5="/ellipse-5@2x.png"
        signUpFramePadding="16px"
        signUpFrameGap="16px"
        frameDivCursor="pointer"
        vector1="/vector1.svg"
        vector2="/vector2.svg"
        navbarAlignSelf="stretch"
        navbarWidth="unset"
        signedInFrameMarginTop="unset"
        frame1165="/frame-1165.svg"
        communicationContactEMail="/communicationcontactemailsvgrepocom-1.svg"
        offerSvgrepoCom21="/offersvgrepocom2-1.svg"
        orderSvgrepoCom1="/ordersvgrepocom-1.svg"
        rewindButtonSvgrepoCom1="/rewindbuttonsvgrepocom-1.svg"
        saleSvgrepoCom1="/salesvgrepocom-1.svg"
        idWalletSvgrepoCom1="/idwalletsvgrepocom-1.svg"
      />
      <div className="self-stretch flex flex-col items-start justify-start gap-[16px] pl-[4%] min-h-[95vh] pr-[4%]">
        <div className="flex flex-row items-center justify-start gap-[4px]">
          <a className="cursor-pointer relative [text-decoration:underline] leading-[140%]">
            Contact Us
          </a>
        </div>
        <div className="flex flex-col relative leading-[140%] font-bold items-start justify-start text-title">
          Contact Us
        </div>
        <div className="self-stretch bg-text-white flex flex-col items-start justify-start py-4 px-5 text-regular border-[1px] border-solid border-black">
          <p className="self-stretch relative leading-[50%]">
            <span className="font-regular text-regular leading-[150%]">
              If you can’t find the information you need in our help articles,
              and would like to get in touch with us, we’re here to help.
            </span>
            <br />
            <span className="font-regular text-regular leading-[150%]">
              You can get in touch with us in the following ways:
            </span>
            <br />
            <br />
            <ul className="leading-[150%] pl-4">
              <li className="">
                Starting a chat with WWHM customer support on whatsapp by
                clicking{" "}
                <span
                  className="cursor-pointer [text-decoration:underline]"
                  href=""
                  target=""
                >
                  here
                </span>
                .
              </li>
              <li className="mb-0">
                Find the WWHM help article that deals with your issue. If you
                can’t find the answer you need in the article, scroll to the
                bottom of the page and choose a contact to open a chat with WWHM
                support on whatsapp with your query automatically filled in.
              </li>
              <li className="mb-0">
                Call us on <span className="font-bold ">7067733014 </span>{" "}
                between 9am and 5pm, Monday to Friday. If you experience call
                waiting, please leave a message on our official whatsapp support
                channel and we will get back to you as soon as possible.
              </li>
            </ul>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsPrivacyPolicy;
