import { useMemo } from "react";
import { useState, useEffect } from "react";

const Dropdown = ({
  info,
  options,
  type,
  setInfo,
  outsideClick,
  title,
  previous,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const handleDropdownBlur = () => {
    setIsClicked(false);
  };

  const [isInputSet, setIsInputSet] = useState(false);

  return (
    <div className="flex flex-1 w-[100%] flex-col items-start justify-start gap-[8px] text-left text-regular font-general-text flex-1">
      <div className="flex-1 flex flex-row items-center justify-start">
        <p
          className="m-0 relative leading-[135%] font-medium"
          id="primary_id_placeholder"
        >
          {title}
        </p>
      </div>
      <div
        className="self-stretch w-full relative inline-block flex flex-col items-start justify-start"
        id="dropdown_menu"
        //onBlur={handleDropdownBlur}
      >
        <div
          className={`self-stretch noselect cursor-pointer overflow-hidden bg-text-white flex flex-row items-center justify-between p-[9px] border-[1px] border-solid border-black ${
            isClicked ? "rounded-t-sm" : "rounded-sm"
          }`}
          tabindex="0"
          onClick={() => {
            console.log("e.value");
            setIsClicked(!isClicked);
          }}
        >
          <div
            className={`relative leading-[135%] max-w-[calc(100%-20px)] whitespace-nowrap overflow-hidden text-ellipsis text-regular ${
              isInputSet ? "text-black" : "text-gray-place"
            }`}
          >
            {previous ? previous : "Select"}
          </div>
          <img
            className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
            alt=""
            src="/chevron-down.svg"
          />
          <img
            className="relative w-6 h-6 overflow-hidden shrink-0 object-cover hidden"
            alt=""
            src="/chevron-up.svg"
          />
        </div>
        <div
          className={`w-[100%] top-[44px] z-[10] absolute rounded-t-none rounded-b-sm bg-text-white overflow-hidden flex flex-col items-start justify-start border-top-[1px] border-solid border-black ${
            isClicked ? "block dropdown" : "hidden"
          }`}
        >
          {type === "end" &&
            options?.map((e, i) => (
              <div
                className="cursor-pointer bg-text-white block box-border self-stretch flex flex-row items-start justify-start py-2.5 px-3 mt-[-1px] border-[1px] border-solid border-black [transition:0.2s] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
                onMouseDown={() => {
                  console.log(e.value);
                  console.log("e.value");
                  setIsClicked(!isClicked);
                  setInfo(e.value);
                  setIsInputSet(true);
                }}
              >
                <div className="relative leading-[135%]">{e.value}</div>
              </div>
            ))}
          <div className="hidden self-stretch bg-text-white flex flex-row items-center justify-start py-2.5 px-3 border-[1px] border-solid border-black">
            <div className="shrink-0 block flex flex-row items-center justify-start gap-[8px]">
              <img
                className="relative w-[11.7px] h-[11.7px] object-cover"
                alt=""
                src="/back.svg"
              />
              <div className="relative leading-[135%]">Electronics</div>
            </div>
          </div>
          <div className="hidden bg-text-white block box-border self-stretch flex flex-row items-start justify-between py-2.5 px-3 mt-[-1px] border-[1px] border-solid border-black">
            <div className="relative leading-[135%]">Electronics</div>
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/chevron-right.svg"
            />
          </div>
          <div className="hidden bg-text-white block box-border self-stretch flex flex-row items-start justify-start py-2.5 px-3 mt-[-1px] border-[1px] border-solid border-black">
            <div className="relative leading-[135%]">Electronics</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
