import { useSelector } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState, useMemo, useRef } from "react";

const OfferSent = ({
  product,
  searchParams,
  setSearchParams,
  open,
  locked,
  children,
  offer,
  type,
  ...props
}) => {
  const { user } = useSelector((state) => state.auth);
  const [amount, setAmount] = useState("");
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [error, showerror] = useState("");
  const [step, setStep] = useState("1");
  const modalRef = useRef(null);

  // Eventlistener: trigger onclose when cancel detected
  const onClose = useCallback((e) => {
    if (!isCancelLoading) {
      setSearchParams({});
      setStep("1");
    }
  }, []);

  //console.log(offer);

  // Eventlistener: trigger onclose when cancel detected
  const onCancel = useCallback(
    (e) => {
      e.preventDefault();
      if (!locked) onClose();
    },
    [locked, onClose]
  );

  // Eventlistener: trigger onclose when click outside
  const onClick = useCallback(
    ({ target }) => {
      const { current: el } = modalRef;
      if (target === el) onClose();
    },
    [onClose]
  );

  const handleCancel = async () => {
    try {
      setIsCancelLoading(true);
      //console.log(product);
      console.log(offer.arrayOfferHistoryId);
      const response = await axiosInstance.post("/product/canceloffer", {
        arrayOfferHistoryId: offer.arrayOfferHistoryId,
      });
      console.log(response);

      if (response?.data?.success) {
        setIsCancelLoading(false);
        setSearchParams({});
      } else console.log(response?.data);
    } catch (err) {
      setIsCancelLoading(false);
      console.log(err);
    }
  };

  return (
    // <dialog
    //   className="top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]"
    //   ref={modalRef}
    //   onClose={onClose}
    //   onCancel={onCancel}
    //   onClick={onClick}
    //   onAnimationEnd={onAnimEnd}
    // >
    //   <div className="w-[450px] rounded-sm bg-white box-border overflow-hidden flex flex-col items-center justify-start py-6 px-5 gap-[16px] text-title text-black font-general-text border-[1px] border-solid border-black">
    //     <h3 className="self-stretch text-center justify-start font-medium m-0 relative text-title leading-[135%]">
    //       Offer sent
    //     </h3>
    //     <section className="self-stretch relative text-regular leading-[130%] text-black text-left">
    //       <span>{`The seller has accepted your offer for 5,000. Your payment method has been charged for the deposit amount. Please make a payment for the remaining amount within the next `}</span>
    //       <span className="font-medium">72 hours</span>
    //     </section>
    //     <button
    //       className="self-stretch cursor-pointer p-3 bg-white flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400"
    //       onClick={() => {}}
    //     >
    //       <a className="flex-1 relative text-regular leading-[135%] text-black text-center ">
    //         Cancel offer
    //       </a>
    //     </button>
    //   </div>
    // </dialog>
    <div
      className={`w-full self-stretch fixed inset-0 z-10 backdrop-blur-10 ${
        open ? "" : "hidden"
      }`}
      ref={modalRef}
    >
      <div
        className="w-full block fixed z-9 h-[100%] top-[0px] left-[0px] absolute bg-black opacity-80 "
        onClick={() => {
          onClose();
        }}
      ></div>
      <div className="w-[450px] absolute z-10 top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] rounded-sm bg-white box-border overflow-hidden flex flex-col items-center justify-start py-6 px-5 gap-[16px] text-title text-black font-general-text border-[1px] border-solid border-black">
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "1" ? "" : "hidden"
          }`}
        >
          <h3 className="self-stretch text-center justify-start font-medium m-0 relative text-title leading-[135%]">
            Offer sent
          </h3>
          <a className="self-stretch relative text-regular leading-[130%] text-black text-left">
            {`
              Please wait upto 48 hours for the ${
                type === "buyer" ? "seller" : "buyer"
              } to respond, we’ll notify
              you once they do. If they fail to respond, your hold will be
              released.`}
          </a>
          <button
            className="self-stretch cursor-pointer p-3 bg-white flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400"
            onClick={() => {
              setStep("2");
            }}
          >
            <a className="flex-1 relative text-regular leading-[135%] text-black text-center ">
              Cancel offer
            </a>
          </button>
        </div>
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "2" ? "" : "hidden"
          }`}
        >
          <h3 className="self-stretch text-center justify-start font-medium m-0 relative text-title leading-[135%]">
            Cancel offer
          </h3>
          <section className="self-stretch flex flex-col items-start justify-start gap-[12px] text-left text-regular">
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                Your offer:
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px] whitespace-nowrap">
                ₹{Number(offer?.amount).toLocaleString("en-IN")}
              </div>
            </div>
          </section>
          <a className="self-stretch relative text-regular leading-[130%] text-black text-left">
            {type === "buyer"
              ? `If you cancel this offer, your deposit will be refunded within 72 hours. If you wish to make another offer, you would need to make another deposit.`
              : type === "seller"
              ? `If you cancel this offer, the buyer will be notified and their deposit will be refunded.`
              : null}
          </a>
          <div className="self-stretch flex flex-row items-start justify-start gap-[16px] sm:flex-col">
            <button
              className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-black text-center bg-white flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400"
              onClick={() => {
                setStep("1");
              }}
              disabled={isCancelLoading}
            >
              Back
            </button>
            <button
              className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-white text-center bg-black flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400 disabled:bg-gray-100 disabled:cursor-default"
              onClick={() => {
                handleCancel();
              }}
              disabled={isCancelLoading}
            >
              {isCancelLoading ? <div className="loader" /> : "Cancel offer"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferSent;
