import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { connect, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import DefaultLayout from "./pages/layouts/DefaultLayout";
import { GET_CURRENT_USER, USER_NOT_LOGGED_IN } from "./redux/actions/types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getCurrentUser,
  getUserNotifications,
  userOrders,
} from "./redux/actions/auth";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import checkTokens, { getTokens } from "./utils/auth/auth";
import { socket } from "./service/socket";

import { getUserCart } from "./redux/actions/cart";
import { BASE_URL, NOTIFICATION_SOCKET, SOCKET_URL } from "./utils/envs";
//import { io } from "socket.io-client";
const ENDPOINT = "https://api.stakeatlas.com/api/v1/";

ReactGA.initialize("G-NJELKFCRQ5");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
    },
  },
});

function App({ modal }) {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  //socket.io initialization
  //const SocketContext = React.createContext();
  //const socket = io("http://localhost:4000", {});
  // socket.on("connect", () => {
  //   console.log(`${socket.id}`);
  // });

  useEffect(async () => {
    const result = await checkTokens();
    //console.log({ result });
    if (result) {
      dispatch(getCurrentUser());
      //console.log("User logged in");
    } else {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: USER_NOT_LOGGED_IN });
          toast.error("User not logged in, dispatch sent");
          resolve();
        } catch {
          toast.error("Error dispatching");
          reject();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      //console.log("fetching cart");
      dispatch(getUserCart(user?.cartId));
      socket.connect();
      return () => {
        socket.disconnect();
      };

      //console.log(process.env.REACT_APP_BASE_URL);
    }
  }, [user]);

  return (
    <>
      {/* <SocketContext.Provider value={socket}> */}
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          {/* <Toasts /> */}
          <ToastContainer position="top-right" autoClose={2000} />
          <DefaultLayout />
        </BrowserRouter>
      </QueryClientProvider>
      {/* </SocketContext.Provider> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  };
};

export default connect(mapStateToProps)(App);
// export const SocketContext = React.createContext();
