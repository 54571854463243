import { useMemo } from "react";
import { useNavigate } from "react-router";

const Endcredits = ({
  vector,
  instagramSocialsSocialMed,
  twitterX1,
  endcreditsAlignSelf,
  endcreditsWidth,
}) => {
  const endcreditsStyle = useMemo(() => {
    return {
      alignSelf: endcreditsAlignSelf,
      width: endcreditsWidth,
    };
  }, [endcreditsAlignSelf, endcreditsWidth]);
  const navigate = useNavigate();

  return (
    <footer
      className="bg-black overflow-hidden flex flex-row items-start justify-between py-12 px-10 box-border pl-[5%] pr-[5%] text-left text-17xl text-text-white font-general-text self-stretch md:w-full md:pl-[5%] md:pr-[5%] sm:w-full sm:flex-col-reverse sm:gap-[36px] sm:items-center sm:justify-between sm:flex mq840:flex-col-reverse mq840:gap-[48px]"
      id="end_credits"
      style={endcreditsStyle}
    >
      <div className="flex flex-col items-start justify-end gap-[24px] sm:gap-[16px] sm:items-center sm:justify-center mq840:gap-[16px]">
        <div className="relative font-light text-title">WhoWhatHowMuch</div>
        <div className="flex flex-row items-center justify-start gap-[13px]">
          <a className="[text-decoration:none] overflow-hidden flex flex-row items-center justify-start py-1 pr-[9px] pl-0">
            <img
              className="relative w-[17.4px] h-[26.8px]"
              alt=""
              src={vector}
            />
          </a>
          <a
            className="[text-decoration:none] relative w-9 h-9 overflow-hidden shrink-0"
            src={instagramSocialsSocialMed}
          >
            <img
              className="absolute h-[78.06%] w-[78.06%] top-[10.83%] right-[11.11%] bottom-[11.11%] left-[10.83%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/vector4.svg"
            />
            <img
              className="absolute h-[36.39%] w-[36.39%] top-[31.67%] right-[31.94%] bottom-[31.94%] left-[31.67%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/vector5.svg"
            />
            <img
              className="absolute h-[10.83%] w-[10.83%] top-[21.94%] right-[25%] bottom-[67.22%] left-[64.17%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/vector6.svg"
            />
          </a>
          <a
            className="[text-decoration:none] relative w-10 h-[34px] overflow-hidden shrink-0"
            src={twitterX1}
          >
            <img
              className="absolute h-[76.76%] w-3/5 top-[11.76%] right-[20%] bottom-[11.47%] left-[20%] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/path1009.svg"
            />
          </a>
        </div>
        <div className="flex flex-col items-start justify-end gap-[24px] text-xs sm:gap-[8px] sm:items-center sm:justify-center mq840:gap-[4px]">
          <div className="relative leading-[150%]">Ⓒ 2023 Stake Atlas LLP</div>
          <div className="flex flex-row items-end justify-start gap-[13px] text-text-gray mq840:flex-row mq840:gap-[12px]">
            <a className="[text-decoration:underline] relative leading-[130%] text-[inherit] cursor-pointer md:text-3xs">
              Privacy
            </a>
            <a className="[text-decoration:underline] relative leading-[130%] text-[inherit] cursor-pointer md:text-3xs">
              User agreement
            </a>
            <a className="[text-decoration:underline] relative leading-[130%] text-[inherit] cursor-pointer md:text-3xs">
              Payments terms of use
            </a>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-row items-start justify-between grow-[2] text-center text-mini text-khaki lg:flex-1 lg:flex lg:justify-between lg:grow-[4] md:flex-row md:gap-[30px] md:flex md:justify-between sm:flex-row sm:grid sm:grid-cols-[auto_auto] sm:justify-between sm:flex-[unset] sm:self-stretch mq840:flex mq840:justify-between mq840:flex-wrap mq840:flex-[unset] mq840:self-stretch">
        <div className="flex flex-col items-start justify-start gap-[16px]">
          <div className="relative leading-[135%] text-regular">Shop</div>
          <div className="flex flex-col items-start justify-start gap-[12px] text-xs text-text-white">
            <a className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs">
              How it works
            </a>
            <a className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs">
              Atlas trust and safety
            </a>
            <a className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs">
              Safe meetup spots
            </a>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[16px]">
          <div className="relative leading-[135%] text-regular">Sell</div>
          <div className="flex flex-col items-start justify-start gap-[12px] text-xs text-text-white">
            <a className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs">
              Post an item
            </a>
            <a className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs">
              Payment schedules
            </a>
          </div>
        </div>
        <div className="overflow-hidden flex flex-col items-start justify-start gap-[16px] text-left">
          <div className="relative leading-[135%] text-regular">About</div>
          <div className="flex flex-col items-start justify-start gap-[12px] text-center text-xs text-text-white">
            <a className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs">
              Our story
            </a>
            <a className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs">
              Careers
            </a>
            <a className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs">
              Blog
            </a>
            <a
              className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs"
              onClick={() => {
                navigate("/privacypolicy");
              }}
            >
              Privacy policy
            </a>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[16px] text-left">
          <div className="relative leading-[135%] text-regular">Help</div>
          <div className="flex flex-col items-start justify-start gap-[12px] text-center text-xs text-text-white">
            <a className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs">
              Help center
            </a>
            <a
              className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs"
              onClick={() => {
                navigate("/help/cancellation");
              }}
            >
              Cancellation and refunds
            </a>
            <a
              className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs"
              onClick={() => {
                navigate("/help/shipping");
              }}
            >
              Shipping and delivery
            </a>
            <a className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs">
              Open a dispute
            </a>
            <a
              className="[text-decoration:none] relative leading-[135%] text-[inherit] cursor-pointer hover:[text-decoration:underline] md:text-3xs sm:text-xs"
              onClick={() => {
                navigate("/help/contact");
              }}
            >
              Contact us
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Endcredits;
