import { GET_ALL_CATEGORIES, ITEM_SPECIFICS } from "./types";
import axiosInstance from "../../axiosInstance";
import { toast } from "react-toastify";

export const getAllCategories = () => {
  return function (dispatch) {
    axiosInstance
      .get(`/admin/category/all?select=name,sub_categories,shipping&sort=asc`)
      .then((res) => {
        const { data } = res.data;
        if (res.data.success || res.data.token)
          dispatch({
            type: GET_ALL_CATEGORIES,
            payload: data,
          });
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      });
  };
};

export const itemSpecifics = (category) => {
  return function (dispatch) {
    axiosInstance
      .get(
        `admin/itemSpecific/all/${category}?select=name,sub_categories,is_subItemSpecific,categoryId,heading&sort=asc`
      )
      .then((res) => {
        const { data } = res.data;
        dispatch({
          type: ITEM_SPECIFICS,
          payload: data,
        });
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            case 400:
              //   add a toast
              toast.error("Invalid Data!");

              break;
            case 401:
              //   add a toast
              toast.error("Invalid credentials!");

              break;
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      });
  };
};
