import React from "react";

const ProductLoading = () => {
  return (
    <div>
      <div className="flex flex-row gap-[8px]">
        <div className="self-stretch overflow-hidden flex flex-col items-start justify-start max-h-[309.59px] gap-[0px] w-[228px] lg:w-[199px] lg:max-h-[282px] sm:w-[164px] sm:max-h-[241.59px] mq840:w-[199px] mq840:max-h-[280.59]">
          <div className="skeleton-loader !w-[210px] !h-[210px] m-2 rounded-sm z-[3] lg:!w-[185px] lg:!h-[185px] lg:m-1.5 lg:rounded-sm sm:!w-[150px] sm:!h-[150px] sm:m-1.5 sm:rounded-sm mq840:!w-[185px] mq840:!h-[185px] mq840:m-1.5"></div>
          <div className="self-stretch flex flex-col mb-2 mx-2 lg:mb-1.5 lg:mx-1.5 sm:mb-1.5 sm:mx-1.5 mq840:mb-1.5 mq840:mx-1.5 overflow-hidden gap-[4px]">
            <div className="skeleton-loader min-h-[23px] "></div>
            <div className="skeleton-loader min-h-[23px] "></div>
            <div className="skeleton-loader max-w-[80%] min-h-[23.59px] "></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLoading;
