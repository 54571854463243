import React from "react";
import { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import Navbar from "../components/Navbar";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";
import { LOGIN_USER } from "../redux/actions/types";
import { getCurrentUser, signupUser } from "../redux/actions/auth";

const SignIn = ({ pageStatus }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, showerror] = useState("");
  const [timeLeft, setTimeLeft] = useState(null);
  const [firstOTPSent, setFirstOTPSent] = useState(false);
  const [passwordOrOTP, setpasswordOrOTP] = useState(true);
  const [viewPassword, setViewPassword] = useState(false);
  const [errorOrMessage, setErrorOrMessage] = useState(true);
  const [fullName, setFullName] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [fullNameValid, setFullNameValid] = useState(true);
  const [signupPhone, setSignupPhone] = useState("");
  const [signupEmailValid, setSignupEmailValid] = useState(true);
  const [signupPasswordValid, setSignupPasswordValid] = useState(true);
  const [signupPhoneValid, setSignupPhoneValid] = useState(true);
  const [backEnabled, setBackEnabled] = useState(false);
  const [backTitle, setBackTitle] = useState("");
  const [otpcount, setotpcount] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [processingOTP, setProcessingOTP] = useState(false);
  const [signupPasswordVerify, setSignUpPassswordVerify] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone: "",
    otp: "",
  });
  const [submitClicked, setSubmitClicked] = useState(false);
  useEffect(() => {
    if (!timeLeft) return;
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const handleButtonClick = (e) => {
    if (title === "Sign in") {
      Submit(e);
    } else if (title === "Sign up") {
      submitSignUp(e);
    } else if (title === "Verify your phone") {
      submitSignUpPhone(e);
    }

    // Add more conditions as needed
  };

  const validateFullName = () => {
    // Your full name validation logic using the regex
    const isValid = /^[a-zA-Z]{2,}(?:\s[a-zA-Z]{2,})+$/.test(fullName);
    setFullNameValid(isValid);
  };

  const validateEmail = () => {
    // Your email validation logic using the regex
    const isValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
      signupEmail
    );
    setSignupEmailValid(isValid);
  };

  const validatePhone = () => {
    // Your email validation logic using the regex
    const isValid = /^\d{10}$/.test(signupPhone);
    setSignupPhoneValid(isValid);
  };

  const validatePassword = () => {
    // Your password validation logic using the regex
    if (
      signupPasswordVerify[0] &&
      signupPasswordVerify[1] &&
      signupPasswordVerify[2] &&
      signupPasswordVerify[3]
    ) {
      setSignupPasswordValid(true);
    } else setSignupPasswordValid(false);
  };

  //To check password on the fly
  useEffect(() => {
    let verifypass = [
      /[A-Z]/.test(signupPassword),
      /[a-z]/.test(signupPassword),
      /\d/.test(signupPassword),
      signupPassword.length >= 8,
    ];
    setSignUpPassswordVerify(verifypass);
  }, [signupPassword]);
  //

  useEffect(() => {
    validateFullName();
    validateEmail();
    validatePassword();
    validatePhone();
  }, [fullName, signupEmail, signupPassword, signupPhone]);

  const togglePasswordOrOTP = () => {
    setpasswordOrOTP(!passwordOrOTP);
  };

  const toggleViewPassword = () => {
    setViewPassword(!viewPassword);
  };

  const [otp, sentotp] = useState(false);

  const [title, setTitle] = useState(pageStatus);

  const [otpvalue, setOtpValue] = useState(0);

  const sendOTP = () => {
    setProcessingOTP(true);
    console.log("Send OTP called");
    showerror("");
    const userid = document.getElementById("signin_ID_input").value;
    if (validator.isEmail(userid)) {
      // Checking if email exists
      axiosInstance
        .post(`/auth/checkUserExist`, { email: userid })
        .then((res) => {
          axiosInstance
            .post(`/user/login/emailOTP/${userid}`)
            .then((response) => {
              sentotp(true);
              setFirstOTPSent(true);
              setTimeLeft(30);
              const otpReceived = response.data.data;
              setOtpValue(otpReceived);
              setProcessingOTP(false);
              setErrorOrMessage(false);
              showerror("OTP has been sent to your email");
            })
            .catch(({ response }) => {
              try {
                switch (response.status) {
                  case 400:
                    //   add a toast
                    setProcessingOTP(false);
                    toast.error("Invalid Data!");
                    sentotp(false);
                    break;
                  case 401:
                    //   add a toast
                    setProcessingOTP(false);
                    toast.error("Invalid credentials!");
                    sentotp(false);
                    break;
                  default:
                    // server error
                    setProcessingOTP(false);
                    toast.error("Oops, something went wrong");
                    sentotp(false);
                    break;
                }
              } catch (e) {
                setProcessingOTP(false);
                toast.error("Couldn't reach the server");
              }
            });
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                // Add a toast
                setProcessingOTP(false);
                toast.error("Invalid Data!");
                break;
              case 401:
                // Add a toast
                setProcessingOTP(false);
                toast.error("Invalid credentials!");
                break;
              case 404:
                // Add a toast
                setProcessingOTP(false);
                showerror("The email you entered doesn't belong to an account");
                break;
              default:
                // Server error
                setProcessingOTP(false);
                toast.error("Oops, something went wrong");
                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        });
    } else if (validator.isMobilePhone(userid)) {
      sentotp(false);
      // code for otp verification
      axiosInstance
        .post(`/auth/checkUserExist`, { phone: userid })
        .then((res) => {
          console.log(res.data);
          axiosInstance
            .post(`/auth/login/phoneOTP/${userid}`)
            .then((response) => {
              sentotp(true);
              setFirstOTPSent(true);
              setTimeLeft(30);
              const otpReceived = response.data.data;
              setOtpValue(otpReceived);
              setErrorOrMessage(false);
              setProcessingOTP(false);
              showerror("OTP has been sent to your number");
            })
            .catch((err) => {
              console.log(err);
              showerror(err.response.data.error);
            });
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                // Add a toast
                toast.error("Invalid Data!");
                break;
              case 401:
                // Add a toast
                toast.error("Invalid credentials!");
                break;
              case 404:
                // Add a toast
                showerror(
                  "The phone number you entered doesn't belong to an account"
                );
                break;
              default:
                // Server error
                toast.error("Oops, something went wrong");
                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        });
    } else {
      setProcessingOTP(false);
      setErrorOrMessage(true);
      showerror("Please enter a valid email or phone number");
    }
  };
  //Send combo to server and check
  const Submit = (e) => {
    e.preventDefault();
    setProcessing(true);
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneRegex = /^\d{10}$/;
    const pswrd = document.getElementById("signin_password_input").value;
    const userid = document.getElementById("signin_ID_input").value;
    if (emailRegex.test(userid)) {
      passwordOrOTP
        ? axiosInstance
            .post("/user/signin", { email: userid, password: pswrd })
            .then((response) => {
              if (response.data.token) {
                console.log(
                  "Before Setting Access Token on Login" + response.data.token
                );
                localStorage.setItem("accessToken", response.data.token);
                console.log(
                  "After Setting Access Token on Login" + response.data.token
                );
                dispatch({
                  type: LOGIN_USER,
                  payload: response.data,
                });
                dispatch(getCurrentUser());
                toast.success("Logged in Successfully");
                navigate("/");
              }
            })
            .catch((err) => {
              setProcessing(false);
              setErrorOrMessage(true);
              showerror(err.response.data.error);
            })
        : pswrd === otpvalue.toString()
        ? axiosInstance
            .post("/user/signin", { email: userid })
            .then((response) => {
              if (response.data.token) {
                localStorage.setItem("accessToken", response.data.token);
                dispatch({
                  type: LOGIN_USER,
                  payload: response.data,
                });
                dispatch(getCurrentUser());
                toast.success("Logged in Successfully");
                navigate("/");
              }
            })
            .catch((err) => {
              setProcessing(false);
              setErrorOrMessage(true);
              showerror(err.response.data.error);
            })
        : (() => {
            setProcessing(false);
            setErrorOrMessage(true);
            showerror("Incorrect OTP, please try again");
          })();
    } else if (phoneRegex.test(userid)) {
      passwordOrOTP
        ? axiosInstance
            .post("/user/signin", { phone: userid, password: pswrd })
            .then((response) => {
              if (response.data.token) {
                localStorage.setItem("accessToken", response.data.token);
                dispatch({
                  type: LOGIN_USER,
                  payload: response.data,
                });
                toast.success("Logged in Successfully");
                navigate("/");
              }
            })
            .catch((err) => {
              setProcessing(false);
              setErrorOrMessage(true);
              showerror(err.response.data.error);
            })
        : pswrd === otpvalue.toString()
        ? axiosInstance
            .post("/user/signin", { phone: userid })
            .then((response) => {
              if (response.data.token) {
                localStorage.setItem("accessToken", response.data.token);
                dispatch({
                  type: LOGIN_USER,
                  payload: response.data,
                });
                toast.success("Logged in Successfully");
                navigate("/");
              }
            })
            .catch((err) => {
              setProcessing(false);
              setErrorOrMessage(true);
              showerror(err.response.data.error);
            })
        : (() => {
            setProcessing(false);
            setErrorOrMessage(true);
            showerror("Wrong OTP! Please Try Again");
          })();
    } else {
      setProcessing(false);
      setErrorOrMessage(true);
      showerror("Please enter a valid email or phone number");
    }
  };

  const sendSignupOTP = () => {
    sentotp(false);
    if (otpcount >= 5) {
      showerror("You have requested for OTP 5 times. Please try again later!");
    } else if (signupPhoneValid) {
      // code for otp verification
      axiosInstance
        .post(`/auth/sendPhoneOtp/${signupPhone}`, { phone: signupPhone })
        .then(() => {
          console.log(`/auth/sendPhoneOtp/${signupPhone}`);
          setotpcount(otpcount + 1);
          setFirstOTPSent(true);
          showerror("");
          sentotp(true);
          setTimeLeft(30);
        })
        .catch((err) => {
          console.log(`/auth/sendPhoneOtp/${signupPhone}`);
          console.log(err);
          showerror(err.response.data.error);
        });
    } else {
      showerror("You have entered an incorrect phone number.");
    }
  };

  const submitSignUp = (e) => {
    e.preventDefault();
    setSubmitClicked(true);
    setProcessing(true);

    // If any field is invalid, prevent submission
    if (!fullNameValid || !signupEmailValid || !signupPasswordValid) {
      setProcessing(false);
      setErrorOrMessage(true);
      showerror("Please check the required fields and try again");
    } else {
      showerror("");
      const nameArray = fullName.split(" ");
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      let newData = { ...data }; // Create a copy of the existing data
      newData.first_name = firstName;
      newData.last_name = lastName;
      newData.email = signupEmail;
      newData.password = signupPassword;
      newData.phone = "";
      newData.otp = "";
      setData(newData);

      axiosInstance
        .post(`/auth/checkUserExist`, { email: signupEmail })
        .then((res) => {
          setProcessing(false);
          setErrorOrMessage(true);
          showerror("The email you entered is already in use");
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                // Add a toast
                setProcessing(false);
                toast.error("Invalid Data!");
                break;
              case 401:
                // Add a toast
                setProcessing(false);
                toast.error("Invalid credentials!");
                break;
              case 404:
                // Add a toast
                setProcessing(false);
                setBackEnabled(true);
                setBackTitle("Sign up");
                setTitle("Verify your phone");
                //navigate("/signup/verify", { state: { data: data } });
                break;
              default:
                // Server error
                setProcessing(false);
                toast.error("Oops, something went wrong");
                break;
            }
          } catch (e) {
            setProcessing(false);
            toast.error("Couldn't reach the server");
          }
        });
    }
  };

  const submitSignUpPhone = (e) => {
    e.preventDefault();
    setProcessing(true);
    const enteredOTP = document.getElementById("signup_OTP_input").value;
    let newData = { ...data };
    newData.phone = signupPhone;
    newData.otp = enteredOTP;
    axiosInstance
      .post("/auth/register", newData)
      .then((data) => {
        if (data.data.token) {
          console.log(
            "Before Setting Access Token on SignUp " + data.data.token
          );
          localStorage.setItem("accessToken", data.data.token);
          console.log(
            "After Setting Access Token on SignUp " + data.data.token
          );
        }
        navigate("/");
      })
      .catch((err) => {
        setProcessing(false);
        showerror(err.response.data.error);
      });
  };

  return (
    <div
      className="relative bg-whitesmoke-100 w-full overflow-hidden flex flex-col items-start justify-start pt-6 px-0 pb-0 box-border text-left text-regular
     text-black font-general-text sm:pt-0 sm:box-border mq840:pt-0 mq840:box-border"
    >
      <Navbar
        icon1="/icon1.svg"
        vector="/vector.svg"
        ellipse5="/ellipse-5@2x.png"
        signUpFramePadding="16px"
        signUpFrameGap="16px"
        frameDivCursor="pointer"
        vector1="/vector1.svg"
        vector2="/vector2.svg"
        navbarAlignSelf="stretch"
        navbarWidth="unset"
        signedInFrameMarginTop="unset"
        frame1165="/frame-1165.svg"
        communicationContactEMail="/communicationcontactemailsvgrepocom-1.svg"
        offerSvgrepoCom21="/offersvgrepocom2-1.svg"
        orderSvgrepoCom1="/ordersvgrepocom-1.svg"
        rewindButtonSvgrepoCom1="/rewindbuttonsvgrepocom-1.svg"
        saleSvgrepoCom1="/salesvgrepocom-1.svg"
        idWalletSvgrepoCom1="/idwalletsvgrepocom-1.svg"
        showSignedInFrame
      />
      <div className="self-stretch bg-whitesmoke-100 overflow-hidden flex flex-col items-center justify-start py-[60px] px-0 box-border h-[95vh] sm:pl-[6%] sm:pr-[6%] sm:h-screen mq840:h-screen">
        <div className="bg-text-white box-border w-[450px] overflow-hidden flex flex-col items-start justify-start p-6 gap-[24px] overflow-y-auto border-[1px] border-solid border-black hover:gap-[24px] sm:self-stretch sm:w-auto sm:gap-[16px] sm:pl-[4%] sm:pr-[4%] sm:hover:gap-[16px]">
          <div className="flex flex-row items-end justify-center gap-[8px] text-md sm:items-center sm:justify-center">
            <button
              className={`cursor-pointer [border:none] p-0 bg-[transparent] relative w-[30px] h-[30px] overflow-hidden shrink-0 
            ${backEnabled ? "" : "hidden"}`}
              onClick={() => {
                setFirstOTPSent(false);
                setBackEnabled(false);
                setTitle(backTitle);
              }}
            >
              <img
                className="absolute h-[62.67%] w-[62.67%] top-[18.67%] right-[18.67%] bottom-[18.67%] left-[18.67%] max-w-full overflow-hidden max-h-full"
                alt=""
                src="/back.svg"
              />
            </button>
            <div className="relative tracking-[-0.01em] leading-[130%] font-medium text-title">
              {title}
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
              {title === "Sign in" && (
                <div className="self-stretch flex flex-col items-start justify-start gap-[16px] pb-0">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative text-regular leading-[135%]">
                      Email ID/Phone Number
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                      <input
                        className={`bg-text-white m-0 flex-1 text-regular box-border overflow-scroll h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                          passwordOrOTP
                            ? "rounded-sm"
                            : "rounded-tl-sm rounded-bl-sm rounded-none" // Conditional class based on focus state
                        }`}
                        id="signin_ID_input"
                        disabled={timeLeft}
                        placeholder="Enter your email"
                        type="email"
                      />
                      <button
                        className={`cursor-pointer py-3 px-2 bg-primary rounded-tl-none rounded-tr-sm rounded-br-sm rounded-bl-none box-border h-11 overflow-hidden inline-block flex flex-row items-center justify-center [transition:0.2s] border-[1px] border-solid border-black hover:enabled:top-[-4px] hover:enabled:left-[-4px] hover:enabled:bottom-[4px] hover:enabled:shadow-[4px_4px] hover:enabled:[transition:0.2s] hover:enabled:relative active:enabled:top-[-1px] active:enabled:left-[-1px] active:enabled:bottom-[1px] active:enabled:shadow-[1px_1px] active:enabled:[transition:0.2s] disabled:bg-disabled disabled:cursor-default ${
                          passwordOrOTP ? "hidden" : "" // Conditional class based on focus state
                        }`}
                        onClick={() => {
                          sendOTP();
                        }}
                        disabled={timeLeft}
                      >
                        {processingOTP ? (
                          // Loading animation (e.g., a spinner)
                          <div className="loader"></div>
                        ) : (
                          <div className="relative text-regular leading-[135%] font-general-text text-black text-centre whitespace-nowrap">
                            {firstOTPSent
                              ? timeLeft
                                ? `Resend OTP(${timeLeft})`
                                : "Resend OTP"
                              : "Send OTP"}
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-end justify-end gap-[8px]">
                    <div className="self-stretch flex flex-row items-end justify-between">
                      <div className="relative leading-[135%]">
                        {`${passwordOrOTP ? "Password" : "OTP"}`}
                      </div>
                      <button
                        className="cursor-pointer [border:none] p-0 bg-[transparent] relative text-xs leading-[135%] font-general-text text-text-gray text-left inline-block hover:[text-decoration:underline]"
                        onClick={() => togglePasswordOrOTP()}
                      >
                        Sign in with {`${passwordOrOTP ? "OTP" : "password"}`}
                      </button>
                    </div>
                    <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                      <input
                        className="bg-text-white m-0 flex-1 text-regular rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                        id="signin_password_input"
                        placeholder={`${
                          passwordOrOTP ? "Enter your password" : "Enter OTP"
                        }`}
                        type={`${viewPassword ? "text" : "password"}`}
                      />
                      <button
                        className={`cursor-pointer py-2.5 px-3 box-border bg-white w-12 h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center relative [transition:0.2s] top-[0] left-[0] bottom-[0] border-[1px] border-solid border-black hover:[transition:0.2s] hover:relative hover:top-[-4px] hover:left-[-4px] hover:bottom-[4px] hover:shadow-[4px_4px] sm:hover:shadow-[0px_0px] sm:hover:top-[0] sm:hover:left-[0] sm:hover:bottom-[0] active:[transition:0.2s] active:relative active:top-[-1px] active:left-[-1px] active:bottom-[1px] active:shadow-[1px_1px] ${
                          passwordOrOTP ? "" : "hidden"
                        }`}
                        onClick={() => toggleViewPassword()}
                      >
                        <img
                          className="absolute w-6 h-6 overflow-hidden shrink-0 z-[100] right-[10px]"
                          id="show_password"
                          alt=""
                          src={`${
                            viewPassword
                              ? "/hidePassword.svg"
                              : "/viewPassword.svg"
                          }`}
                        />
                      </button>
                    </div>
                    <div className="flex flex-row items-end justify-start">
                      <button className="cursor-pointer [border:none] p-0 bg-[transparent] relative text-xs leading-[135%] font-general-text text-text-gray text-left inline-block hover:cursor-pointer hover:[text-decoration:underline]">
                        Forgot Password?
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {title === "Sign up" && (
                <div className="self-stretch flex flex-col items-start justify-start gap-[16px] pb-0">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative text-regular leading-[135%]">
                      Full name
                    </div>
                    <input
                      className={`bg-text-white text-regular self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                        submitClicked && !fullNameValid ? "border-red-500" : ""
                      }`}
                      placeholder="Enter your name"
                      type="text"
                      id="signup_name_input"
                      value={fullName}
                      onChange={(e) => {
                        setFullNameValid(true);
                        setFullName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative text-regular leading-[135%]">
                      Email
                    </div>
                    <input
                      className={`bg-text-white text-regular self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                        submitClicked && !signupEmailValid
                          ? "border-red-500"
                          : ""
                      }`}
                      placeholder="Enter your email"
                      type="email"
                      id="signup_email_input"
                      value={signupEmail}
                      onChange={(e) => {
                        setSignupEmailValid(true);
                        setSignupEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-[402px] flex flex-col items-end justify-end gap-[8px]">
                    <div className="self-stretch relative h-5 hover:flex focus:items-end focus:justify-center">
                      <p
                        className="m-0 absolute top-[0px] left-[0px] leading-[135%] font-medium"
                        id="password_placeholder"
                      >
                        Password
                      </p>
                      <div className="absolute top-[2px] left-[73px] w-4 h-4 text-xs text-gray-900 hover:block hover:z-[1000] [&_.passwordpattern]:hover:flex">
                        <img
                          className="absolute top-[0px] left-[0px] w-4 h-4 overflow-hidden"
                          alt=""
                          src="/info.svg"
                        />
                        <div className="passwordpattern absolute top-[-2px] left-[22px] rounded-sm bg-text-white hidden flex-col items-start justify-start py-1.5 px-2 gap-[6px] ml-2 mt-[-10px] border-[1px] border-solid border-black">
                          <div className="flex flex-row items-center justify-start gap-[6px] whitespace-nowrap">
                            <img
                              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                              alt=""
                              src={`${
                                signupPasswordVerify[0]
                                  ? "/right.svg"
                                  : "/wrong.svg"
                              }`}
                            />
                            <div className="relative leading-[135%]">
                              One upper case character
                            </div>
                          </div>
                          <div className="flex flex-row items-start justify-start gap-[6px]">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src={`${
                                signupPasswordVerify[1]
                                  ? "/right.svg"
                                  : "/wrong.svg"
                              }`}
                            />
                            <div className="relative leading-[135%]">
                              One lower case character
                            </div>
                          </div>
                          <div className="flex flex-row items-start justify-start gap-[6px]">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src={`${
                                signupPasswordVerify[2]
                                  ? "/right.svg"
                                  : "/wrong.svg"
                              }`}
                            />
                            <div className="relative leading-[135%]">
                              One number
                            </div>
                          </div>
                          <div className="flex flex-row items-start justify-start gap-[6px]">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src={`${
                                signupPasswordVerify[3]
                                  ? "/right.svg"
                                  : "/wrong.svg"
                              }`}
                            />
                            <div className="relative leading-[135%]">
                              8 character minimum
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                      <input
                        className={`bg-text-white text-regular flex-1 rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                          submitClicked && !signupPasswordValid
                            ? "border-red-500"
                            : ""
                        }`}
                        placeholder="Enter your password"
                        value={signupPassword}
                        onChange={(e) => {
                          setSignupPasswordValid(true);
                          setSignupPassword(e.target.value);
                        }}
                        type={`${viewPassword ? "text" : "password"}`}
                      />
                      <button
                        className="cursor-pointer py-2.5 px-3 bg-text-white box-border w-12 h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center relative [transition:0.2s] top-[0] left-[0] bottom-[0] border-[1px] border-solid border-black hover:[transition:0.2s] hover:relative hover:top-[-4px] hover:left-[-4px] hover:bottom-[4px] hover:shadow-[4px_4px] active:[transition:0.2s] active:relative active:top-[-1px] active:left-[-1px] active:bottom-[1px] active:shadow-[1px_1px]"
                        onClick={() => toggleViewPassword()}
                      >
                        <img
                          className="absolute w-6 h-6 overflow-hidden shrink-0 z-[100] right-[10px]"
                          id="show_password"
                          alt=""
                          src={`${
                            viewPassword
                              ? "/hidePassword.svg"
                              : "/viewPassword.svg"
                          }`}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {title === "Verify your phone" && (
                <div className="self-stretch flex flex-col items-start justify-start gap-[16px] pb-0">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative text-regular leading-[135%]">
                      Phone number
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                      <input
                        className={`bg-text-white m-0 flex-1 text-regular box-border overflow-scroll h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                          passwordOrOTP
                            ? "rounded-sm"
                            : "rounded-tl-sm rounded-bl-sm rounded-none" // Conditional class based on focus state
                        }`}
                        id="signup_phone_input"
                        value={signupPhone}
                        disabled={timeLeft}
                        placeholder="Enter your phone number"
                        type="tel"
                        onChange={(e) => {
                          // setSignupPhoneValid(true);
                          setSignupPhone(e.target.value);
                        }}
                      />
                      <button
                        className="cursor-pointer py-3 px-2 bg-primary rounded-tl-none rounded-tr-sm rounded-br-sm rounded-bl-none box-border h-11 overflow-hidden inline-block flex flex-row items-center justify-center [transition:0.2s] border-[1px] border-solid border-black hover:enabled:top-[-4px] hover:enabled:left-[-4px] hover:enabled:bottom-[4px] hover:enabled:shadow-[4px_4px] hover:enabled:[transition:0.2s] hover:enabled:relative active:enabled:top-[-1px] active:enabled:left-[-1px] active:enabled:bottom-[1px] active:enabled:shadow-[1px_1px] active:enabled:[transition:0.2s] disabled:bg-disabled disabled:cursor-default"
                        onClick={() => {
                          sendSignupOTP();
                        }}
                        disabled={timeLeft}
                      >
                        <div className="relative text-regular leading-[135%] font-general-text text-black text-centre whitespace-nowrap">
                          {firstOTPSent
                            ? timeLeft
                              ? `Resend OTP(${timeLeft})`
                              : "Resend OTP"
                            : "Send OTP"}
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative text-regular leading-[135%]">
                      OTP
                    </div>
                    <input
                      className="bg-text-white text-regular self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                      placeholder="Enter OTP"
                      type="tel"
                      id="signup_OTP_input"
                    />
                  </div>
                </div>
              )}
              {title === "Recover password" && (
                <div className="self-stretch flex-col items-start justify-start">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative leading-[135%]">Email/Phone</div>
                    <input
                      className="bg-text-white self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                      placeholder="Enter your password"
                      type="email"
                    />
                  </div>
                </div>
              )}
              {title === "Verify OTP" && (
                <div className="self-stretch hidden flex-col items-start justify-start gap-[16px]">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative leading-[135%]">heading</div>
                    <input
                      className="bg-text-white self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                      placeholder="Enter your password"
                      type="email"
                    />
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative leading-[135%]">heading</div>
                    <input
                      className="bg-text-white self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                      placeholder="Enter your password"
                      type="email"
                    />
                  </div>
                </div>
              )}
              {title === "New password" && (
                <div className="self-stretch hidden flex-col items-start justify-start gap-[16px]">
                  <div className="w-[402px] flex flex-col items-end justify-end gap-[8px]">
                    <div className="self-stretch relative h-5 hover:flex focus:items-end focus:justify-center">
                      <p
                        className="m-0 absolute top-[0px] left-[0px] leading-[135%] font-medium"
                        id="password_plaeholder"
                      >
                        Enter new password
                      </p>
                      <div className="absolute top-[2px] left-[73px] w-4 h-4 text-xs text-gray-900 hover:block hover:z-[1000] [&_.passwordpattern]:hover:flex">
                        <img
                          className="absolute top-[0px] left-[0px] w-4 h-4 overflow-hidden"
                          alt=""
                          src="/infocirclesvgrepocom-1.svg"
                        />
                        <div className="passwordpattern absolute top-[-2px] left-[22px] rounded-sm bg-text-white hidden flex-col items-start justify-start py-1.5 px-2 gap-[6px] ml-2 mt-[-10px] border-[1px] border-solid border-black">
                          <div className="flex flex-row items-center justify-start gap-[6px] whitespace-nowrap">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/checksvgrepocom.svg"
                            />
                            <img
                              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                              alt=""
                              src="/crosssvgrepocom4-11.svg"
                            />
                            <div className="relative leading-[135%]">
                              One upper case character
                            </div>
                          </div>
                          <div className="flex flex-row items-start justify-start gap-[6px]">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/checksvgrepocom.svg"
                            />
                            <img
                              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                              alt=""
                              src="/crosssvgrepocom4-12.svg"
                            />
                            <div className="relative leading-[135%]">
                              One lower case character
                            </div>
                          </div>
                          <div className="flex flex-row items-start justify-start gap-[6px]">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/checksvgrepocom.svg"
                            />
                            <img
                              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                              alt=""
                              src="/crosssvgrepocom4-12.svg"
                            />
                            <div className="relative leading-[135%]">
                              One number
                            </div>
                          </div>
                          <div className="flex flex-row items-start justify-start gap-[6px]">
                            <img
                              className="relative w-4 h-4 overflow-hidden shrink-0"
                              alt=""
                              src="/checksvgrepocom.svg"
                            />
                            <img
                              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                              alt=""
                              src="/crosssvgrepocom4-12.svg"
                            />
                            <div className="relative leading-[135%]">
                              8 character minimum
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                      <input
                        className="bg-text-white flex-1 rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                        placeholder="Enter new password"
                        type="email"
                      />
                      <button className="cursor-pointer py-2.5 px-3 bg-text-white box-border w-12 h-11 overflow-hidden shrink-0 flex flex-row items-center justify-center relative [transition:0.2s] top-[0] left-[0] bottom-[0] border-[1px] border-solid border-black hover:[transition:0.2s] hover:relative hover:top-[-4px] hover:left-[-4px] hover:bottom-[4px] hover:shadow-[4px_4px] active:[transition:0.2s] active:relative active:top-[-1px] active:left-[-1px] active:bottom-[1px] active:shadow-[1px_1px]">
                        <img
                          className="absolute w-6 h-6 overflow-hidden shrink-0 z-[100] right-[10px]"
                          id="show_password"
                          alt=""
                          src="/eyepasswordshowsvgrepocom-1.svg"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative leading-[135%]">
                      Reenter new password
                    </div>
                    <input
                      className="bg-text-white self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                      placeholder="Enter your password"
                      type="password"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-xs">
              {error && (
                <div
                  className={`self-stretch overflow-hidden flex-row items-center justify-start p-3 ${
                    errorOrMessage ? "bg-error" : "bg-message" // Conditional class based on focus state
                  }`}
                >
                  <div className="flex-1 relative">{error}</div>
                </div>
              )}
              <button
                className="cursor-pointer py-3 px-[68px] bg-black self-stretch rounded-sm flex flex-row items-center justify-center top-[0px] left-[0px] bottom-[0px] [transition:0.2s] border-[1px] border-solid border-gray-700 hover:enabled:shadow-[4px_4px_#FFDC25] hover:enabled:[transition:all_0.2s] hover:enabled:left-[-4px] hover:enabled:top-[-4px] hover:enabled:bottom-[4px] hover:enabled:relative active:[transition:0.2s] active:shadow-[2px_2px_#FFDC25] active:left-[-2px] active:top-[-2px] active:bottom-[2px] active:relative sm:relative sm:top-[-2px] sm:left-[-2px] sm:bottom-[2px] sm:[transition:0.2s] sm:shadow-[4px_4px_#FFDC25] sm:hover:top-[-2px] sm:hover:left-[-2px] sm:active:relative sm:active:top-[-1px] sm:active:left-[-1px] sm:active:bottom-[1px] sm:active:[transition:0.2s] sm:active:shadow-[2px_2px_#FFDC252]"
                onClick={handleButtonClick}
                disabled={processing}
              >
                {processing ? (
                  // Loading animation (e.g., a spinner)
                  <div className="loader"></div>
                ) : (
                  <div
                    className="relative text-regular
                 font-medium font-general-text text-text-white text-left"
                  >
                    {title === "Sign in" && "Sign in"}
                    {title === "Sign up" && "Next"}
                    {title === "Verify your phone" && "Sign up"}
                    {title === "Recover password" && "Send OTP"}
                    {title === "Verify OTP" && "Next"}
                    {title === "New password" && "Confirm"}
                  </div>
                )}
              </button>
            </div>
          </div>
          {(title === "Sign in" || title === "Sign up") && (
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <div className="relative leading-[140%]">
                {title === "Sign in"
                  ? "New to CoinOrBill?"
                  : "Already have an account?"}
              </div>
              <span
                className="cursor-pointer relative leading-[140%] [text-decoration:underline]"
                onClick={() => {
                  setFirstOTPSent(false);
                  showerror("");
                  setViewPassword(false);
                  title === "Sign in"
                    ? navigate("/signup")
                    : navigate("/signin");
                }}
              >
                {title === "Sign in" ? "Sign up" : "Sign In"}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
