import { useEffect, useState, useCallback } from "react";
import Navbar from "../components/Navbar";
import ProductList from "../components/ProductList";
import PortalPopup from "../components/PortalPopup";
import SelectAddress from "../components/SelectAddress";
import { useDispatch, useSelector } from "react-redux";
import { getUserCart, removeProductFromCart } from "../redux/actions/cart";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../redux/actions/auth";

const Cart = () => {
  const Navigate = useNavigate();
  const [frameInputValue, setFrameInputValue] = useState("");
  const [inputFieldValue, setInputFieldValue] = useState("");
  const [editCouponInputDisabled, setEditCouponInputDisabled] = useState(false);
  const [nameErrorOrMessage, setNameErrorOrMessage] = useState(false);
  const [couponInput, setCouponInput] = useState("");
  const [couponError, setCouponError] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [cartLoading, setCartLoading] = useState(true);
  const [currentCart, setCurrentCart] = useState(null);
  const { user, isUserLoading, isLoggedIn } = useSelector(
    (state) => state.auth
  );
  const { cart } = useSelector((state) => state.cart);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState({
    isValid: false,
    address: "",
    name: "",
    phone: "",
    pincode: "",
    city: "",
    state: "",
  });
  const [isSelectAddressPopupOpen, setIsSelectAddressPopupOpen] =
    useState(false);
  const dispatch = useDispatch();

  const [product, setProduct] = useState(null);
  const openSelectAddressPopup = () => {
    setIsSelectAddressPopupOpen(true);
  };

  useEffect(() => {
    console.log(cart);
  }, [cart]);

  function numberWithIndianCommas(x) {
    // console.log(x.toLocaleString("en-IN"));
    return x.toLocaleString("en-IN");
  }

  const handleRemoveFromCart = (productId) => {
    dispatch(removeProductFromCart(productId, user?.cartId));
  };

  window.invokePayPageRedirect = (tokenUrl) => {
    window.PhonePeCheckout.transact({ tokenUrl });
  };

  // Example function to invoke PayPage in IFrame Mode
  window.invokePayPageIFrame = (tokenUrl, callback) => {
    if (window.PhonePeCheckout?.transact) {
      console.log(tokenUrl);
      console.log(callback);
      window.PhonePeCheckout.transact({ tokenUrl, callback, type: "IFRAME" });
      // Your code here
    } else {
      console.error("PhonePeCheckout object or transact property is undefined");
    }
  };

  useEffect(() => {
    console.log(isUserLoading);
  }, [isUserLoading]);

  // Example function to close PayPage explicitly
  window.closePayPage = () => {
    window.PhonePeCheckout.closePage();
  };

  const handleClose = () => {
    window.closePayPage();
  };

  const handleIFrame = () => {
    const tokenUrl =
      "https://mercury-uat.phonepe.com/transact?token=MjdkNmQ0NjM2MTk5ZTlmNDcxYjY3NTAxNTY5MDFhZDk2ZjFjMDY0YTRiN2VhMjgzNjIwMjBmNzUwN2JiNTkxOWUwNDVkMTM2YTllOTpkNzNkNmM2NWQ2MWNiZjVhM2MwOWMzODU0ZGEzMDczNA"; // Fetch the URL from your server
    const callback = (response) => {
      handleClose();
      // Handle response (USER_CANCEL or CONCLUDED) as needed
    };
    window.invokePayPageIFrame(tokenUrl, callback);
  };

  useEffect(() => {
    // setIsPageLoading(false);
    setCartLoading(false);
  }, [currentCart]);

  useEffect(() => {
    console.log(isUserLoading);
    if (!isUserLoading) {
      setIsPageLoading(false);
    }
  }, [isUserLoading]);

  useEffect(() => {
    if (!isUserLoading) {
      setIsPageLoading(false);
    }
    // console.log(isPageLoading);
    // console.log(user);
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          console.log("one");
          await dispatch(getUserCart(user.cartId));
          console.log("two");
        }
        setIsPageLoading(false);
      } catch (error) {
        console.error("Error in fetchData:", error);
        setIsPageLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="relative bg-whitesmoke-100 w-full overflow-hidden flex flex-col items-start justify-start pt-6 px-0 pb-0 box-border text-left text-11xl text-black font-general-text sm:pt-0 sm:box-border mq840:gap-[0px] mq840:pt-0 mq840:box-border">
      <Navbar
        icon1="/icon1.svg"
        vector="/vector.svg"
        ellipse5="/ellipse-5@2x.png"
        signUpFramePadding="16px"
        signUpFrameGap="16px"
        frameDivCursor="pointer"
        vector1="/vector1.svg"
        vector2="/vector2.svg"
        navbarAlignSelf="stretch"
        navbarWidth="unset"
        signedInFrameMarginTop="unset"
        frame1165="/frame-1165.svg"
        communicationContactEMail="/communicationcontactemailsvgrepocom-1.svg"
        offerSvgrepoCom21="/offersvgrepocom2-1.svg"
        orderSvgrepoCom1="/ordersvgrepocom-1.svg"
        rewindButtonSvgrepoCom1="/rewindbuttonsvgrepocom-1.svg"
        saleSvgrepoCom1="/salesvgrepocom-1.svg"
        idWalletSvgrepoCom1="/idwalletsvgrepocom-1.svg"
      />

      <div className="self-stretch bg-whitesmoke-100 overflow-hidden text-regular flex flex-col items-start justify-start py-6 px-0 box-border gap-[16px] min-h-[95vh] pl-[4%] pr-[4%] overflow-y-scroll lg:min-h-[95vh] lg:pl-[4%] lg:pr-[4%] sm:pl-[6%] sm:pr-[6%] sm:min-h-[100vh] mq840:min-h-[100vh]">
        <div className="relative leading-[130%] text-title font-medium">
          Your cart
        </div>
        {isUserLoading ? (
          <div className="w-full flex flex-row gap-[12px] sm:flex-col mq840:flex-col">
            <div className="flex-1 bg-text-white flex flex-col py-4 px-5 gap-[24px] items-center border-[0px] border-solid border-black sm:self-stretch mq840:flex-[unset] mq840:self-stretch">
              <div className="self-stretch flex-1 rounded-4xs min-h-[60px] skeleton-loader" />

              <div className="flex self-stretch flex-col gap-[8px]">
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] max-w-[80%] skeleton-loader" />
              </div>
              <div className="flex self-stretch flex-col gap-[8px]">
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] max-w-[80%] skeleton-loader" />
              </div>
              <div className="flex self-stretch flex-col gap-[8px]">
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                <div className="self-stretch flex-1 rounded-4xs min-h-[10px] max-w-[80%] skeleton-loader" />
              </div>
            </div>
            <div className="self-stretch max-h-[180px] flex-0.3 bg-text-white flex flex-col py-4 px-5 gap-[8px] items-center border-[0px] border-solid border-black">
              <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
              <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
              <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
              <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
              <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
              <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
              <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
              <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
              <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
              <div className=" self-stretch flex-1 rounded-4xs min-h-[10px] max-w-[80%] skeleton-loader " />
            </div>
          </div>
        ) : (
          <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-center text-5xl">
            {user ? (
              !cart?.items ? (
                <div className="w-full flex flex-row gap-[12px] sm:flex-col mq840:flex-col">
                  <div className="flex-1 bg-text-white flex flex-col py-4 px-5 gap-[24px] items-center border-[0px] border-solid border-black sm:self-stretch mq840:flex-[unset] mq840:self-stretch">
                    <div className="self-stretch flex-1 rounded-4xs min-h-[60px] skeleton-loader" />

                    <div className="flex self-stretch flex-col gap-[8px]">
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] max-w-[80%] skeleton-loader" />
                    </div>
                    <div className="flex self-stretch flex-col gap-[8px]">
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] max-w-[80%] skeleton-loader" />
                    </div>
                    <div className="flex self-stretch flex-col gap-[8px]">
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader" />
                      <div className="self-stretch flex-1 rounded-4xs min-h-[10px] max-w-[80%] skeleton-loader" />
                    </div>
                  </div>
                  <div className="self-stretch max-h-[180px] flex-0.3 bg-text-white flex flex-col py-4 px-5 gap-[8px] items-center border-[0px] border-solid border-black">
                    <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
                    <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
                    <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
                    <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
                    <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
                    <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
                    <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
                    <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
                    <div className="flex-1 rounded-4xs min-h-[10px] w-full skeleton-loader " />
                    <div className=" self-stretch flex-1 rounded-4xs min-h-[10px] max-w-[80%] skeleton-loader " />
                  </div>
                </div>
              ) : cart?.items.length === 0 ? (
                <div className="self-stretch  flex flex-col items-center justify-start py-[60px] px-0">
                  <div className="flex flex-col items-center justify-start gap-[12px]">
                    <div className="relative leading-[130%]">
                      Your cart is empty, browse around and add some item
                    </div>
                    <button className="cursor-pointer py-3 px-[68px] bg-black self-stretch rounded-sm shrink-0 flex flex-row items-center justify-center top-[0px] left-[0px] bottom-[0px] [transition:0.2s] border-[1px] border-solid border-gray-700 hover:shadow-[4px_4px_#FFDC25] hover:[transition:all_0.2s] hover:left-[-4px] hover:top-[-4px] hover:bottom-[4px] hover:relative active:[transition:0.2s] active:shadow-[2px_2px_#FFDC25] active:left-[-2px] active:top-[-2px] active:bottom-[2px] active:relative sm:relative sm:top-[-2px] sm:left-[-2px] sm:bottom-[2px] sm:[transition:0.2s] sm:shadow-[4px_4px_#FFDC25] sm:hover:top-[-2px] sm:hover:left-[-2px] sm:active:relative sm:active:top-[-1px] sm:active:left-[-1px] sm:active:bottom-[1px] sm:active:[transition:0.2s] sm:active:shadow-[2px_2px_#FFDC252]">
                      <div className="relative text-regular font-medium font-general-text text-text-white text-left">
                        See whats trending
                      </div>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="self-stretch flex flex-row items-start justify-center gap-[12px] text-left text-base mq840:flex-col sm:flex-col">
                  <div className="flex-1 bg-text-white flex flex-col items-center border-[0px] border-solid border-black sm:self-stretch mq840:flex-[unset] mq840:self-stretch">
                    <div className="self-stretch flex flex-row justify-between py-4 px-5 sm:px-3 border-[1px] border-solid border-black items-start ">
                      <div className="flex w-full flex-col items-start justify-end gap-[4px]">
                        <div
                          className="relative leading-[130%] font-bold
                  "
                        >
                          Deliver to:
                        </div>
                        <div className=" flex w-full flex-col items-start justify-center gap-[4px]">
                          <div className="relative leading-[130%] twolinetext">
                            {selectedAddress.isValid
                              ? selectedAddress.name +
                                " " +
                                selectedAddress.phone
                              : ""}
                          </div>
                          <div
                            className={`relative leading-[130%] threelinetext ${
                              !selectedAddress.isValid
                                ? "[text-decoration:underline] cursor-pointer"
                                : ""
                            }`}
                            onClick={(e) => {
                              selectedAddress.isValid
                                ? e.preventDefault()
                                : openSelectAddressPopup();
                            }}
                          >
                            {selectedAddress.isValid
                              ? selectedAddress.address +
                                ", " +
                                selectedAddress.city +
                                ", " +
                                selectedAddress.state +
                                " " +
                                selectedAddress.pincode
                              : "Select a delivery address"}
                          </div>
                        </div>
                      </div>
                      <a
                        className={`cursor-pointer relative [text-decoration:underline] leading-[130%] text-right ${
                          selectedAddress.isValid ? "" : "hidden"
                        } `}
                        onClick={() => {
                          openSelectAddressPopup();
                        }}
                      >
                        Change
                      </a>
                    </div>

                    {/* <div className="self-stretch flex flex-row items-center justify-start py-2 px-5 sm:px-4 gap-[4px]">
                    <div className="relative leading-[130%] font-bold">
                      Seller:
                    </div>
                    <div className="relative text-base leading-[130%] text-black text-left">
                      Akshat Tripathi
                    </div>
                  </div>
                  <div className="w-[95%] border-black border-solid border-b-[1px]" /> */}
                    {cart?.items.length > 0 &&
                      cart?.items.map((item, index) => (
                        <div className="self-stretch border-[1px] mt-[-1px] border-solid border-black flex flex-row items-center justify-start py-4 px-5 sm:px-3 gap-[12px] text-regular">
                          <div className="shrink-0 flex flex-col items-start justify-center relative">
                            <div className="flex flex-col items-center justify-start absolute gap-[10px] z-[4] top-[10px] left-[-8px]">
                              {item.product?.condition === "NEW" ? (
                                <img
                                  className="relative w-[54px] h-[27px] z-[0]"
                                  alt=""
                                  src={
                                    require("../assets/icons/vector8.svg")
                                      .default
                                  }
                                />
                              ) : item.product?.condition === "FORPARTS" ? (
                                <img
                                  className="relative w-[97px] h-[27px] z-[0]"
                                  alt=""
                                  src={
                                    require("../assets/icons/forparts.svg")
                                      .default
                                  }
                                />
                              ) : (
                                <img
                                  className="relative w-[62px] h-[27px] z-[0]"
                                  alt=""
                                  src={
                                    require("../assets/icons/vector3.svg")
                                      .default
                                  }
                                />
                              )}

                              <div
                                className={`self-stretch absolute leading-[120%] top-[calc(50%_-_8px)] font-regular text-xs pt-0 pr-0 pb-0 z-[1] ${
                                  product?.condition === "NEW"
                                    ? "pl-2.5"
                                    : product?.condition === "FOR PARTS"
                                    ? "pl-2.5"
                                    : "pl-2.5"
                                }`}
                              >
                                {item.product?.condition === "FORPARTS"
                                  ? "FOR PARTS"
                                  : item.product?.condition}
                              </div>
                            </div>
                            <div className="relative box-border w-[130px] h-[130px] sm:w-[110px] sm:h-[110px] rounded-sm z-[1] border-[1px] border-solid border-black">
                              <img
                                className="absolute top-[0px] left-[0px] w-full h-full object-cover"
                                alt=""
                                src={
                                  Array.isArray(item?.product?.images)
                                    ? item?.product.images[0] || null
                                    : null
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="self-stretch flex-1 flex flex-col items-start justify-between text-base sm:max-w-[calc(100%-112px)]
                "
                          >
                            <div className="self-stretch flex flex-row items-start justify-between sm:flex-col sm:items-start sm:justify-between sm:gap-[4px] mq840:flex-col mq840:items-start mq840:justify-between mq840:gap-[4px]">
                              <div className=" flex flex-col items-start mq840:max-w-[100%] sm:max-w-[100%] max-w-[calc(100%-100px)] justify-start gap-[4px]">
                                <p className="relative leading-[130%] twolinetext">
                                  {item?.product.title}
                                </p>
                                <i className="relative text-mini leading-[130%] font-light lg:whitespace-nowrap lg:overflow-hidden lg:text-ellipsis lg:max-w-[100%] text-dimgray-200 mq840:hidden sm:hidden">
                                  {item?.product.description}
                                </i>
                              </div>
                              <div className="shrink-0 flex flex-col items-end justify-start gap-[4px] text-right mq840:flex-row sm:flex-col sm:items-start sm:gap-0">
                                <div className="relative leading-[130%] font-medium">
                                  &#8377;
                                  {numberWithIndianCommas(item?.product.cost)}
                                </div>
                                <div className="relative leading-[130%] text-xs ">
                                  Free Shipping
                                </div>
                              </div>
                            </div>
                            <div className="self-stretch flex flex-row items-center justify-between text-center text-xs text-wishlist-link">
                              <div className="relative [text-decoration:underline] leading-[130%]">
                                Save for later
                              </div>
                              <div
                                className="cursor-pointer relative text-partsRed [text-decoration:underline] leading-[130%]"
                                onClick={() => {
                                  console.log(product, cart);
                                  handleRemoveFromCart(item.product._id);
                                  //window.location.reload();
                                }}
                              >
                                Delete
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="flex-[0.4] flex flex-col items-center justify-start gap-[12px] text-center sm:self-stretch mq840:self-stretch">
                    <div className="self-stretch bg-text-white flex flex-col items-start justify-start py-4 px-5 border-[1px] border-solid border-black sm:px-3">
                      <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                        <div className="relative text-regular leading-[135%]">
                          Promo code
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                          <input
                            className="bg-text-white w-full rounded-tl-sm rounded-tr-none rounded-br-none rounded-bl-sm m-0 text-regular box-border overflow-scroll h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                            id="namef_edit_input"
                            disabled={editCouponInputDisabled}
                            onChange={(e) => setCouponInput(e.target.value)}
                            placeholder="Enter promo here"
                            value={couponInput}
                            type="text"
                          />
                          <button
                            className="cursor-pointer py-3 px-4 bg-primary text-black text-regular rounded-tl-none rounded-tr-sm rounded-br-sm rounded-bl-none box-border h-11 overflow-hidden inline-block flex flex-row items-center justify-center [transition:0.2s] border-[1px] border-solid border-black hover:enabled:top-[-4px] hover:enabled:left-[-4px] hover:enabled:bottom-[4px] hover:enabled:shadow-[4px_4px] hover:enabled:[transition:0.2s] hover:enabled:relative active:enabled:top-[-1px] active:enabled:left-[-1px] active:enabled:bottom-[1px] active:enabled:shadow-[1px_1px] active:enabled:[transition:0.2s] disabled:{background-color:black} disabled:cursor-default"
                            onClick={() => {}}
                          >
                            Apply
                          </button>
                        </div>
                        {couponError && (
                          <div
                            className={`self-stretch overflow-hidden flex-row items-center justify-start p-3 ${
                              nameErrorOrMessage ? "bg-error" : "bg-message" // Conditional class based on focus state
                            }`}
                          >
                            <div className="flex-1 relative">{couponError}</div>
                          </div>
                        )}
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start pt-4 pb-3 px-0 text-left text-md border-b-[1px] border-solid border-black">
                        <div className="relative leading-[130%] font-medium">
                          Your cart (
                          {cart?.total_qty > 1
                            ? `${cart?.total_qty} items`
                            : `${cart?.total_qty} item`}
                          )
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start">
                        <div className="self-stretch flex flex-col items-start justify-start">
                          <div className="self-stretch flex flex-row items-center justify-between pt-2 px-0 pb-1">
                            <div className="relative leading-[130%]">
                              Subtotal
                            </div>
                            <div className="relative leading-[130%] text-gray-500 text-right">
                              &#8377;
                              {numberWithIndianCommas(cart?.total_price)}
                            </div>
                          </div>
                          <div className="self-stretch flex flex-row items-center justify-between pt-1 px-0 pb-2">
                            <div className="relative leading-[130%]">
                              Shipping
                            </div>
                            <div className="relative leading-[130%]  text-gray-500 text-right">
                              {cart?.totalshipping?.price
                                ? "cart?.totalshipping?.price"
                                : "Free shipping"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch flex flex-row items-center border-t-[1px] border-solid border-black justify-between pt-2  px-0">
                        <div className="relative leading-[130%]">Total</div>
                        <div className="relative leading-[130%] font-medium text-gray-500 text-right">
                          &#8377;
                          {numberWithIndianCommas(
                            cart?.total_price +
                              (cart?.totalshipping?.price
                                ? "cart?.totalshipping?.price"
                                : 0)
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      className="cursor-pointer py-3 px-[68px] bg-black self-stretch rounded-sm flex flex-row items-center justify-center top-[0px] left-[0px] bottom-[0px] [transition:0.2s] border-[1px] border-solid border-gray-700 hover:shadow-[4px_4px_#FFDC25] hover:[transition:all_0.2s] hover:left-[-4px] hover:top-[-4px] hover:bottom-[4px] hover:relative active:[transition:0.2s] active:shadow-[2px_2px_#FFDC25] active:left-[-2px] active:top-[-2px] active:bottom-[2px] active:relative sm:relative sm:top-[-2px] sm:left-[-2px] sm:bottom-[2px] sm:[transition:0.2s] sm:shadow-[4px_4px_#FFDC25] sm:hover:top-[-2px] sm:hover:left-[-2px] sm:active:relative sm:active:top-[-1px] sm:active:left-[-1px] sm:active:bottom-[1px] sm:active:[transition:0.2s] sm:active:shadow-[2px_2px_#FFDC252]"
                      onClick={() => {
                        handleIFrame();
                      }}
                    >
                      <div className="relative text-regular font-medium font-general-text text-text-white text-left">
                        Pay now
                      </div>
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div className="self-stretch  flex flex-col items-center justify-start py-[60px] px-0">
                <div className="flex flex-col items-center justify-start gap-[12px]">
                  <div className="relative leading-[130%]">
                    Please sign in to view your cart
                  </div>
                  <button className="cursor-pointer py-3 px-[68px] bg-black self-stretch rounded-sm shrink-0 flex flex-row items-center justify-center top-[0px] left-[0px] bottom-[0px] [transition:0.2s] border-[1px] border-solid border-gray-700 hover:shadow-[4px_4px_#FFDC25] hover:[transition:all_0.2s] hover:left-[-4px] hover:top-[-4px] hover:bottom-[4px] hover:relative active:[transition:0.2s] active:shadow-[2px_2px_#FFDC25] active:left-[-2px] active:top-[-2px] active:bottom-[2px] active:relative sm:relative sm:top-[-2px] sm:left-[-2px] sm:bottom-[2px] sm:[transition:0.2s] sm:shadow-[4px_4px_#FFDC25] sm:hover:top-[-2px] sm:hover:left-[-2px] sm:active:relative sm:active:top-[-1px] sm:active:left-[-1px] sm:active:bottom-[1px] sm:active:[transition:0.2s] sm:active:shadow-[2px_2px_#FFDC252]">
                    <div className="relative text-regular font-medium font-general-text text-text-white text-left">
                      Sign in
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {isSelectAddressPopupOpen && (
        <PortalPopup overlayColor="rgba(46, 46, 46, 0.8)" placement="Centered">
          <SelectAddress
            onClose={setIsSelectAddressPopupOpen}
            setSelectedAddress={setSelectedAddress}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        </PortalPopup>
      )}

      <ProductList title={"Recommended listings"} />
    </div>
  );
};

export default Cart;
