import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import TermsOrderCancellation from "../../containers/TermsOrderCancellation";
import TermsShippingDelivery from "../../containers/TermsShippingDelivery";
import ContactUs from "../../containers/ContactUs";
import TermsPrivacyPolicy from "../../containers/TermsPrivacyPolicy";
import Footer from "../../components/Endcredits";
import SmoothScroll from "../../components/SmoothScroll";
import Cart from "../../containers/Cart";
import Landing from "../../containers/Landing";
import SignIn from "../../containers/SignIn";
import Error404 from "../Error404";
import ProductError from "../ProductError";
import ProductPage from "../../containers/ProductPage";
import PostAnItem from "../../containers/PostAnItem";
import Account from "../../containers/Account";
import Navbar from "../../components/Navbar";

const DefaultLayout = () => {
  const location = useLocation();
  const [isNavHidden, setIsNavHidden] = useState(false);

  useEffect(() => {
    const hiddenNavRoutes = ["/login"];
    const result = hiddenNavRoutes.includes(location.pathname);
    setIsNavHidden(result);
  }, [location.pathname]);

  return (
    <div className="layout  flex flex-col justify-between ">
      {/* // checking if childs inside it is possible for each*/}
      <SmoothScroll>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/product/:productId" element={<ProductPage />} />

          <Route path="help">
            <Route path="cancellation" element={<TermsOrderCancellation />} />
            <Route path="shipping" element={<TermsShippingDelivery />} />
            <Route path="contact" element={<ContactUs />} />
          </Route>

          <Route path="privacypolicy" element={<TermsPrivacyPolicy />} />

          <Route path="sellnow">
            <Route path="drafts" element={<PostAnItem />} />
          </Route>
          <Route path="account">
            <Route path="" element={<Account subMenu="summary" />} />
            <Route path="summary" element={<Account subMenu="summary" />} />
            <Route path="info" element={<Account subMenu="info" />} />
            <Route path="orders" element={<Account subMenu="orders" />} />
            <Route path="selling">
              <Route
                path="offers"
                element={<Account subMenu="selling.offers" />}
              />
              <Route
                path="listings"
                element={<Account subMenu="selling.listings" />}
              />
            </Route>
          </Route>

          <Route path="signin">
            <Route path="" element={<SignIn pageStatus={"Sign in"} />} />
            <Route
              path="forgotPassword"
              element={<SignIn pageStatus={"forgotpassword"} />}
            />
          </Route>

          <Route path="signup" element={<SignIn pageStatus={"Sign up"} />} />
          <Route
            path="postanitem"
            element={<PostAnItem pageStatus={"postanitem"} />}
          />

          <Route path="/404" element={<Error404 />} />
          <Route path="/product/*" element={<ProductError />} />
          <Route path="*" element={<Navigate to="/404" replace={true} />} />
          {/* <Route path="*" element={<Error404 />} /> */}
        </Routes>
        <Footer />
      </SmoothScroll>
    </div>
  );
};

export default DefaultLayout;
