import { useSelector } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import ReactDom from "react-dom";

const Makeanoffer = ({
  product,
  searchParams,
  setSearchParams,
  open,
  locked,
  children,
  data,
  type,
  offer,
  setOffer,
  ...props
}) => {
  const { user } = useSelector((state) => state.auth);
  const [amount, setAmount] = useState("");
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  //const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [paymentSent, setPaymentSent] = useState(false);
  const [step, setStep] = useState("1");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, showerror] = useState("");
  const modalRef = useRef(null);

  const checkAmount = () => {
    if (amount > product?.price / 2) setStep("2");
    else showerror("Please enter a valid amount");
  };

  // Eventlistener: trigger onclose when cancel detected
  const onClose = useCallback((e) => {
    if (!isPaymentLoading) {
      setSearchParams({});
      setStep("1");
    }
  }, []);

  // Eventlistener: trigger onclose when cancel detected
  const onCancel = useCallback(
    (e) => {
      e.preventDefault();
      if (!locked) onClose();
    },
    [locked, onClose]
  );

  // Eventlistener: trigger onclose when click outside
  const onClick = useCallback(
    ({ target }) => {
      const { current: el } = modalRef;
      if (target === el) onClose();
    },
    [onClose]
  );

  //   // Eventlistener: trigger close click on anim end
  //   const onAnimEnd = useCallback(() => {
  //     const { current: el } = modalRef;
  //     if (!open) el.close();
  //   }, [open]);

  //   // when open changes run open/close command
  //   useEffect(() => {
  //     const { current: el } = modalRef;
  //     if (open) el.showModal();
  //   }, [open]);

  const initializePayment = (data) => {
    console.log(data);
    const options = {
      key: "rzp_test_Yjas4yyrRospJ4",
      amount: data.amount,
      currency: data.currency,
      name: product?.title,
      description: "Test Transaction",
      image: product?.images[0],
      order_id: data.id,
      method: {
        netbanking: false,
        card: true,
        wallet: true,
        upi: true,
      },
      handler: async (response) => {
        try {
          console.log(response);
          console.log(user);
          const { data } = await axiosInstance.post(
            "/order/verifyrazorpaypayment",
            {
              ...response,
              transactionType: "offer",
              offerAmount: amount,
              productId: product?._id,
              userId: user?._id,
            }
          );
          console.log(data);
          if (data.success) {
            setStep("3");
            setPaymentSent(true);
            setOffer({
              sentBy: user._id,
              offerAmount: amount,
              status: "sent",
            });
          }
          setIsPaymentLoading(false);
        } catch (error) {
          setStep("3");
          setPaymentSent(true);
          console.log(error);
        }
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    setIsPaymentLoading(false);
  };

  const handlePayment = async () => {
    try {
      setIsPaymentLoading(true);
      //console.log(product);
      const response = await axiosInstance.post("/order/createrazorpayorder", {
        amount: amount,
        productId: product?._id,
      });
      //console.log(response);

      if (response?.data?.success) {
        //setIsPaymentLoading(false);
        //setIsPaymentProcessing(true);
        initializePayment(response?.data.order);
        //setIsPaymentLoading(false);
        //setIsPaymentProcessing(false);
        return;
      } else console.log(response?.data);
    } catch (err) {
      //setIsPaymentProcessing(false);
      setIsPaymentLoading(false);
      console.log(err);
    }
  };

  const CheckOfferHistory = async () => {
    try {
      setIsContinueLoading(true);
      const response = await axiosInstance.post("product/checkOfferHistory", {
        productId: product._id,
      });
      console.log(response);
      if (response?.data?.success) setStep("2");
      setIsContinueLoading(false);
    } catch (err) {
      //setIsPaymentProcessing(false);
      setIsContinueLoading(false);
      console.log(err);
    }
  };

  return (
    <div
      className={`w-full self-stretch fixed inset-0 z-10 backdrop-blur-10 ${
        open ? "" : "hidden"
      }`}
      ref={modalRef}
    >
      <div
        className="w-full block fixed z-9 h-[100%] top-[0px] left-[0px] absolute bg-black opacity-80 "
        onClick={() => {
          onClose();
        }}
      ></div>
      <div className="w-[450px] absolute z-10 top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] rounded-sm bg-white box-border overflow-hidden flex flex-col items-center justify-start py-6 px-5 gap-[16px] text-title text-black font-general-text border-[1px] border-solid border-black">
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "1" ? "" : "hidden"
          }`}
        >
          <div className="self-stretch flex flex-col items-center justify-start">
            <h3 className="m-0 relative text-center text-title leading-[135%] font-medium">
              Make an offer
            </h3>
            <div className="relative text-xs text-gray leading-[135%]">
              <span>{`(${offer?.attempts ? offer?.attempts : 15} `}</span>
              attempts left)
            </div>
          </div>
          <section className="self-stretch flex flex-col items-center justify-end gap-[12px] text-left text-regular">
            <div className="self-stretch flex flex-row items-center justify-center gap-[2px] sm:flex-wrap">
              <div className="relative leading-[130%]">{`Buy it now price: `}</div>
              <div className="relative leading-[130%] text-right whitespace-nowrap">
                &#8377;{Number(product?.price).toLocaleString("en-IN")}
              </div>
            </div>
            <div className="relative leading-[135%]">Your offer</div>
            <input
              className="self-stretch relative text-title leading-[135%] text-center focus:[outline:none] focud:relative"
              id="signin_password_input"
              placeholder="Enter your offer"
              type="tel"
              value={"₹" + Number(amount).toLocaleString("en-IN")}
              onChange={(e) => {
                showerror("");
                setAmount(e.target.value.replace(/[^\d]/g, ""));
                console.log(amount.toLocaleString("en-IN"));
              }}
            />
            <div className="flex flex-row items-start justify-start gap-[8px] text-xs">
              <div className="relative leading-[135%]">2 competing offers</div>
              <div className="flex flex-row items-start justify-start py-0 pr-1 pl-2 gap-[4px] border-l-[1px] border-solid border-black">
                <div className="relative leading-[135%]">Last offer:</div>
                <div className="relative leading-[135%]">24 Nov</div>
              </div>
            </div>
          </section>
          {error && (
            <div
              className={`self-stretch overflow-hidden text-xs flex-row items-center justify-start p-3 bg-error`}
            >
              {error}
            </div>
          )}
          <button
            className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-white text-center bg-black flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400"
            onClick={() => {
              CheckOfferHistory();
            }}
            disabled={isContinueLoading}
          >
            {isContinueLoading ? <div className="loader" /> : "Continue"}
          </button>
        </div>
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "2" ? "" : "hidden"
          }`}
        >
          <h3 className="self-stretch text-center justify-start text-title font-medium m-0 relative leading-[135%]">
            Review your offer
          </h3>
          <section className="self-stretch flex flex-col items-start justify-start gap-[12px] text-left text-regular">
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                Your offer:
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px] whitespace-nowrap">
                ₹{Number(amount).toLocaleString("en-IN")}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                Shipping:
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                Free shipping
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px]">
                Total
              </div>
              <div className="flex-1 relative leading-[130%] inline-block min-w-[122px] whitespace-nowrap">
                ₹{Number(amount).toLocaleString("en-IN")}
              </div>
            </div>
          </section>
          <section className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full text-left text-regular text-black font-heading">
            <div className="self-stretch flex flex-col items-center justify-start gap-[4px]">
              <div className="relative leading-[135%]">
                Refundable deposit due
              </div>
              <div className="self-stretch relative text-md leading-[135%] font-medium text-center whitespace-nowrap">
                ₹{Number(amount / 10).toLocaleString("en-IN")}
              </div>
            </div>
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px] max-w-full text-xs">
              <input
                className="m-0 h-4 w-4 relative rounded-sm box-border overflow-hidden shrink-0 border-[1px] border-solid border-black"
                type="checkbox"
                value=""
                onClick={() => setTermsAccepted(!termsAccepted)}
                styles={{
                  webkitOuterSpinButton: "none",
                  webkitInnerSpinButton: "none",
                  mozAppearnace: "none",
                }}
              />
              <div className="flex-1 relative leading-[135%] inline-block min-w-[230px] max-w-full">
                {`I accept the `}
                <span className="[text-decoration:underline]">
                  terms and conditions
                </span>{" "}
                and allow WhoWhatHowMuch to place a pre-authorization charge and
                agree to be charged if this offer or any subsequent offer is
                accepted by the seller.
              </div>
            </div>
          </section>
          <div className="self-stretch flex flex-row items-start justify-start gap-[16px] sm:flex-col">
            <button
              className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-black text-center bg-white flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400"
              onClick={() => {
                setStep("1");
              }}
              disabled={isPaymentLoading}
            >
              Back
            </button>
            <button
              className="self-stretch cursor-pointer p-3 text-regular leading-[135%] text-white text-center bg-black flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400 disabled:bg-gray-100 disabled:cursor-default"
              onClick={() => {
                handlePayment();
              }}
              disabled={!termsAccepted || isPaymentLoading}
            >
              {isPaymentLoading ? <div className="loader" /> : "Pay now"}
            </button>
          </div>
        </div>
        <div
          className={`self-stretch flex flex-col gap-[16px] ${
            step === "3" ? "" : "hidden"
          }`}
        >
          <h3 className="self-stretch text-center justify-start font-medium m-0 relative text-title leading-[135%]">
            {paymentSent ? "Offer sent" : "Payment unsuccessful"}
          </h3>
          <section className="self-stretch relative text-regular leading-[130%] text-black text-left">
            {paymentSent ? (
              <a>
                {`Your offer for ₹${amount} has been sent. Please wait up to `}
                <span className="font-medium">48 hours</span>
                {` for the seller to respond. We'll notify you once they do. If they fail to respond, your payment method will not be charged.`}
              </a>
            ) : (
              <a>
                Your last payment attempt was unsuccessful, please use a
                different payment method or try again later.
              </a>
            )}
          </section>
          <button
            className="self-stretch cursor-pointer p-3 bg-black flex-1 flex flex-row text-regular leading-[135%] text-white text-center items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400"
            onClick={() => {
              paymentSent ? onClose() : handlePayment();
            }}
          >
            {paymentSent ? "Close" : "Retry payment"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Makeanoffer;
