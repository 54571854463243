import { useState } from "react";
import Navbar from "../components/Navbar";

const TermsPrivacyPolicy = () => {
  return (
    <div className="relative bg-whitesmoke-100 w-full overflow-hidden flex flex-col items-start justify-start pt-6 px-0 pb-6 box-border gap-[24px] text-left text-regular text-black font-general-text sm:pt-0 mq840:pt-0 sm:box-border">
      <Navbar
        icon1="/icon1.svg"
        vector="/vector.svg"
        ellipse5="/ellipse-5@2x.png"
        signUpFramePadding="16px"
        signUpFrameGap="16px"
        frameDivCursor="pointer"
        vector1="/vector1.svg"
        vector2="/vector2.svg"
        navbarAlignSelf="stretch"
        navbarWidth="unset"
        signedInFrameMarginTop="unset"
        frame1165="/frame-1165.svg"
        communicationContactEMail="/communicationcontactemailsvgrepocom-1.svg"
        offerSvgrepoCom21="/offersvgrepocom2-1.svg"
        orderSvgrepoCom1="/ordersvgrepocom-1.svg"
        rewindButtonSvgrepoCom1="/rewindbuttonsvgrepocom-1.svg"
        saleSvgrepoCom1="/salesvgrepocom-1.svg"
        idWalletSvgrepoCom1="/idwalletsvgrepocom-1.svg"
      />
      <div className="self-stretch flex flex-col items-start justify-center gap-[16px] pl-[4%] pr-[4%]">
        <div className="flex flex-row items-center justify-start gap-[4px]">
          <a className="cursor-pointer relative [text-decoration:underline] leading-[140%]">
            Privacy policy
          </a>
        </div>
        <div className="flex flex-col relative leading-[140%] font-bold items-start justify-start text-title">
          Privacy Policy
        </div>
        <div className="self-stretch bg-text-white flex flex-col items-start justify-start py-4 px-5 text-regular border-[1px] border-solid border-black">
          <p className="self-stretch relative leading-[50%]">
            <span className="font-regular text-regular leading-[150%]">
              Welcome to WhoWhatHowMuch. This Privacy Policy outlines the types
              of personal information we collect, how we use and protect that
              information, and your rights regarding your personal data.
            </span>
            <br />
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              Information We Collect:
            </span>
            <br />
            <br />
            <ul className="leading-[150%] pl-4">
              <li className="">
                Account Information: When you register on our Platform, we
                collect your name, email address, and phone number.
              </li>
              <li className="mb-0">
                Verification Information: For security and verification
                purposes, we may collect government-issued identification
                details, which will be deleted immediately after the
                verification process.
              </li>
              <li className="mb-0">
                Automatically Collected Information: We use Google Analytics to
                collect information about your use of the Platform, such as your
                IP address, browser type, and device information. This data is
                collected through cookies and other tracking technologies
              </li>
            </ul>
            <br />
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              How We Use Your Information:
            </span>
            <br />
            <br />
            <ul className="leading-[150%] pl-4">
              <li className="mb-0">
                Providing Services: We use your account information to create
                and manage your account, and to provide you with the services
                offered on our Platform.
              </li>
              <li className="mb-0">
                Verification: The collected identification information is used
                solely for verification purposes and is promptly deleted after
                completion of the verification process.
              </li>
              <li className="mb-0">
                Improving Services: We use Google Analytics to analyze user
                behavior and improve our services. This includes understanding
                user preferences, optimizing user experience, and enhancing the
                overall functionality of the Platform.
              </li>
            </ul>
            <br />
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              Sharing Your Information:
            </span>
            <br />
            <br />
            <ul className="leading-[150%] pl-4">
              <li className="mb-0">
                Service Providers: We may share your information with trusted
                third-party service providers who assist us in delivering our
                services, such as payment processors and identity verification
                services.
              </li>
              <li className="mb-0">
                Legal Compliance: We may disclose your information if required
                by law or in response to a valid legal request.
              </li>
              <li className="mb-0">
                Improving Services: We use Google Analytics to analyze user
                behavior and improve our services. This includes understanding
                user preferences, optimizing user experience, and enhancing the
                overall functionality of the Platform.
              </li>
            </ul>
            <br />
            <span className="leading-[150%]">
              Cookies and Tracking Technologies:
            </span>
            <ul className="leading-[150%] pl-4">
              <li className="mb-0">
                Google Analytics: We use Google Analytics to collect and analyze
                information about your usage of the Platform through cookies.
                You can control the use of cookies through your browser
                settings.
              </li>
            </ul>
            <br />
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              Your Rights:
            </span>
            <br />
            <br />
            <ul className="leading-[150%] pl-4">
              <li className="mb-0">
                Access and Correction: You have the right to access and correct
                your personal information. You can do this by logging into your
                account or contacting our support team.
              </li>
              <li className="mb-0">
                Data Deletion: You can request the deletion of your account and
                personal information by contacting our support team.
              </li>
            </ul>
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              Security:
            </span>
            <br />
            <br />
            <ul className="leading-[150%] pl-4">
              <li className="mb-0">
                We implement reasonable security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, and destruction.
              </li>
            </ul>
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              Changes to Privacy Policy:
            </span>
            <br />
            <br />
            <ul className="leading-[150%] pl-4">
              <li className="mb-0">
                We may update this Privacy Policy from time to time. We will
                notify you of any significant changes through email or a
                prominent notice on the Platform.
              </li>
            </ul>
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              Contact Us:
            </span>
            <br />
            <br />
            <ul className="leading-[150%] pl-4">
              <li className="mb-0">
                If you have any questions or concerns regarding this Privacy
                Policy, please contact us at support@WhoWhatHowMuch.com. By
                using our Platform, you agree to the terms outlined in this
                Privacy Policy.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsPrivacyPolicy;
