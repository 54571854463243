import { useSelector } from "react-redux";
import {
  ADD_ADDRESS,
  ADD_TO_CART,
  SET_CART_ADDRESS,
  REMOVE_FROM_CART,
  USER_CART,
} from "../actions/types";

const initialState = {
  cart: null,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case SET_CART_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case ADD_TO_CART:
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    case REMOVE_FROM_CART:
      const productIdToRemove = action.payload._id;
      const updatedProducts = state.products.filter(
        (product) => product._id !== productIdToRemove
      );

      return {
        ...state,
        products: updatedProducts,
      };
    case USER_CART:
      return {
        ...state,
        cart: action.payload,
      };
    default:
      return state;
  }
};

export default cartReducer;
