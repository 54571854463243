import { useEffect, useState } from "react";
import { getCurrentUser } from "../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import checkTokens, { getTokens } from "../utils/auth/auth";
import { addAddress } from "../redux/actions/auth";

const SelectAddress = ({
  onClose,
  setSelectedAddress,
  selectedIndex,
  setSelectedIndex,
}) => {
  const [firstlineInput, setFirstlineInput] = useState("");
  const [firstlineInputValid, setFirstlineInputValid] = useState(true);
  const [fullNameInput, setFullNameInput] = useState("");
  const [fullNameInputValid, setFullNameInputValid] = useState(true);
  const [phoneInput, setPhoneInput] = useState("");
  const [phoneInputValid, setPhoneInputValid] = useState(true);
  const [cityInput, setCityInput] = useState("");
  const [cityInputValid, setCityInputValid] = useState(true);
  const [stateInput, setStateInput] = useState("");
  const [stateInputValid, setStateInputValid] = useState(true);
  const [pincodeInput, setPincodeInput] = useState("");
  const [pincodeInputValid, setPincodeInputValid] = useState(true);
  const [tempAddress, setTempAddress] = useState("");
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPrimaryAddress, setIsPrimaryAddress] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [newAddress, setNewAddress] = useState({
    name: "",
    phone: "",
    address_line1: "",
    state: "",
    city: "",
    pincode: "",
    primary: false,
  });
  const [newAddressValid, setNewAddressValid] = useState({
    name: true,
    phone: true,
    address_line1: true,
    state: true,
    city: true,
    pincode: true,
  });
  const [newAddressValidError, setNewAddressValidError] = useState({
    name: true,
    phone: true,
    address_line1: true,
    state: true,
    city: true,
    pincode: true,
  });

  const setPrimaryAddress = () => {
    console.log("Primary address set");
  };

  useEffect(() => {
    console.log(user?.addresses);
    (async () => {
      // if (checkTokens()) {
      //   await setUser(await dispatch(await getCurrentUser()));
      //   console.log("User set");
      // } else {
      //   user = null;
      // }
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    // ignore the first render, and then check if all keys in newAddressValid are true
    setNewAddressValidError({ ...newAddressValid });
    if (fullNameInput) {
      if (
        Object.keys(newAddressValid).every((k) => newAddressValid[k] === true)
      ) {
        console.log("Valid");
        dispatch(addAddress(newAddress));
        setAddNewAddress(false);
      }
    }
  }, [newAddressValid]);

  const submitAddNewAddress = () => {
    setNewAddress({
      name: fullNameInput,
      phone: phoneInput,
      address_line1: firstlineInput,
      state: stateInput,
      city: cityInput,
      pincode: pincodeInput,
      primary: true,
    });
    setNewAddressValid({
      ...newAddressValid,
      name: /^[a-zA-Z]{2,}(?:\s[a-zA-Z]{2,})+$/.test(fullNameInput),
      phone: /^\d{10}$/.test(phoneInput),
      address_line1: firstlineInput.length > 0,
      state: stateInput.length > 0,
      city: cityInput.length > 0,
      pincode: /^\d{6}$/.test(pincodeInput),
    });
    // copy all the values from newAddressValid to newAddressValidError

    console.log(newAddressValidError);
  };
  const submitConfirmAddress = () => {
    setSelectedAddress({
      isValid: true,
      name: user.addresses[selectedIndex].address.name,
      phone: user.addresses[selectedIndex].address.phone,
      address: user.addresses[selectedIndex].address.address_line1,
      state: user.addresses[selectedIndex].address.state,
      city: user.addresses[selectedIndex].address.city,
      pincode: user.addresses[selectedIndex].address.pincode,
    });
    onClose();
  };

  return (
    <div className="rounded-sm bg-text-white box-border w-[450px] max-h-[80vh] max-w-full  flex flex-col items-center justify-start text-left text-lg text-text-white font-general-text border-[1px] border-solid border-black">
      <div className="self-stretch bg-modalHeading flex flex-row items-center justify-between py-2 px-3 text-regular text-white border-b-[1px] border-solid border-black">
        <span className="relative leading-[135%]">
          Select a delivery address
        </span>

        <div className="flex partsRed items-center justify-center rounded-sm w-7 h-7">
          <img
            className="relative overflow-hidden cursor-pointer"
            alt=""
            src="/x.svg"
            onClick={() => {
              onClose(false);
            }}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="skeleton-loader self-stretch ">
          <div className="self-stretch w-[450px] h-[40vh]"></div>
        </div>
      ) : (
        <div className="self-stretch flex-1 flex flex-col overflow-scroll items-center justify-start p-4 gap-[16px] text-base text-black">
          {!user && user?.addresses?.length === 0 ? (
            <div className="self-stretch hidden flex flex-row items-center justify-center py-10 px-0">
              <div className="relative leading-[130%]">
                {!user ? "Please sign in to continue" : ""}
                {user?.addresses?.length === 0 ? "No saved addresses" : ""}
              </div>
            </div>
          ) : null}

          {user?.addresses?.length > 0 && !addNewAddress
            ? user?.addresses?.map((e, i) => (
                <div
                  className={`self-stretch cursor-pointer rounded-sm flex flex-row items-start justify-between py-3 px-4 border-[1px] border-solid border-black  ${
                    i === selectedIndex
                      ? "mt-[-3px] ml-[-3px] mb-[3px] mr-[3px] shadow-[4px_4px] bg-background"
                      : "bg-text-white  hover:[transition:0.2s] hover:mt-[-3px] hover:ml-[-3px] hover:mb-[3px] hover:mr-[3px] hover:shadow-[4px_4px] active:[transition:0.2s] active:mt-[-1px] active:ml-[-1px] active:mb-[1px] active:mr-[1px] active:shadow-[2px_2px]"
                  }`}
                  onClick={() => {
                    setSelectedIndex(i);
                  }}
                >
                  <div className="shrink-0 flex flex-col items-start justify-start gap-[6px]">
                    <div className="relative leading-[130%]">
                      {e.address.name}
                    </div>
                    <div className="relative leading-[130%]">
                      {e.address.phone}
                    </div>
                    <div className="relative leading-[130%]">
                      <p className="m-0 max-w-[300px] whitespace-nowrap overflow-hidden text-ellipsis">
                        {e.address.address_line1},
                      </p>
                      <p className="m-0">
                        {e.address.city}, {e.address.state}
                      </p>
                      <p className="m-0">{e.address.pincode}</p>
                    </div>
                  </div>
                  <div className="self-stretch shrink-0 flex flex-col items-end justify-between text-smi">
                    <div className="relative [text-decoration:underline] leading-[130%]">
                      Edit
                    </div>
                    {e.is_primary ? (
                      <div className="relative leading-[130%]">Primary </div>
                    ) : (
                      <div
                        className="relative leading-[130%] [text-decoration:underline] cursor-pointer"
                        onClick={() => {
                          setPrimaryAddress(i);
                        }}
                      >
                        Set default{" "}
                      </div>
                    )}
                  </div>
                </div>
              ))
            : null}

          {addNewAddress ? (
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-end justify-between">
                  <p
                    className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                    id="primary_id_placeholder"
                  >
                    Full name
                  </p>
                </div>
                <input
                  className={`bg-text-white text-regular placeholder-gray-place self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                    newAddressValidError.name ? "" : "border-red-500"
                  }`}
                  placeholder="i.e. Prakash Iyer"
                  type="text"
                  id="signup_name_input"
                  value={fullNameInput}
                  onChange={(e) => {
                    setFullNameInput(e.target.value);
                    setNewAddress({ ...tempAddress, name: e.target.value });
                    setNewAddressValidError({
                      ...newAddressValidError,
                      name: true,
                    });
                  }}
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-end justify-between">
                  <p
                    className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                    id="primary_id_placeholder"
                  >
                    Phone
                  </p>
                </div>
                <input
                  className={`bg-text-white text-regular placeholder-gray-place self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                    newAddressValidError.phone ? "" : "border-red-500"
                  }`}
                  placeholder="i.e. 9876543210"
                  type="text"
                  id="signup_name_input"
                  value={phoneInput}
                  onChange={(e) => {
                    setPhoneInput(e.target.value);
                    setNewAddress({ ...tempAddress, phone: e.target.value });
                    setNewAddressValidError({
                      ...newAddressValidError,
                      phone: true,
                    });
                  }}
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-end justify-between">
                  <div className="flex-1 flex flex-row items-center justify-start">
                    <p
                      className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                      id="primary_id_placeholder"
                    >
                      Address
                    </p>
                  </div>
                  <div className="bg-tomato shrink-0 hidden flex-row items-center justify-end py-0 px-1">
                    <p
                      className="m-0 relative text-xs leading-[135%] font-general-text text-text text-left"
                      id="primary_id_placeholder"
                    >
                      invalid
                    </p>
                  </div>
                </div>
                <input
                  className={`bg-text-white text-regular placeholder-gray-place self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                    newAddressValidError.address_line1 ? "" : "border-red-500"
                  }`}
                  placeholder="i.e. mohalla, street, colony, etc."
                  type="text"
                  id="signup_name_input"
                  value={firstlineInput}
                  onChange={(e) => {
                    setFirstlineInput(e.target.value);
                    setNewAddress({
                      ...tempAddress,
                      address_line1: e.target.value,
                    });
                    setNewAddressValidError({
                      ...newAddressValidError,
                      address_line1: true,
                    });
                  }}
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-end justify-between">
                  <div className="flex-1 flex flex-row items-center justify-start">
                    <p
                      className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                      id="primary_id_placeholder"
                    >
                      City
                    </p>
                  </div>
                  <div className="bg-tomato shrink-0 hidden flex-row items-center justify-end py-0 px-1">
                    <p
                      className="m-0 relative text-xs leading-[135%] font-general-text text-text text-left"
                      id="primary_id_placeholder"
                    >
                      invalid
                    </p>
                  </div>
                </div>
                <input
                  className={`bg-text-white text-regular placeholder-gray-place self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                    newAddressValidError.city ? "" : "border-red-500"
                  }`}
                  placeholder="i.e. Bhopal"
                  type="text"
                  id="signup_name_input"
                  value={cityInput}
                  onChange={(e) => {
                    setCityInput(e.target.value);
                    setNewAddress({ ...tempAddress, city: e.target.value });
                    setNewAddressValidError({
                      ...newAddressValidError,
                      city: true,
                    });
                  }}
                />
              </div>
              <div className="flex self-stretch flex-row items-start justify-center gap-[8px]">
                <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch flex flex-row items-end justify-between">
                    <div className="flex-1 flex flex-row items-center justify-start">
                      <p
                        className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                        id="primary_id_placeholder"
                      >
                        State
                      </p>
                    </div>
                    <div className="bg-tomato shrink-0 hidden flex-row items-center justify-end py-0 px-1">
                      <p
                        className="m-0 relative text-xs leading-[135%] font-general-text text-text text-left"
                        id="primary_id_placeholder"
                      >
                        invalid
                      </p>
                    </div>
                  </div>
                  <input
                    className={`bg-text-white text-regular w-[100%] placeholder-gray-place self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                      newAddressValidError.state ? "" : "border-red-500"
                    }`}
                    placeholder="i.e. Madhya Pradesh"
                    type="text"
                    id="signup_name_input"
                    value={stateInput}
                    onChange={(e) => {
                      setStateInput(e.target.value);
                      setNewAddress({ ...tempAddress, state: e.target.value });
                      setNewAddressValidError({
                        ...newAddressValidError,
                        state: true,
                      });
                    }}
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                  <div className="flex-1 flex flex-row items-center justify-start">
                    <p
                      className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                      id="primary_id_placeholder"
                    >
                      Pincode
                    </p>
                  </div>
                  <input
                    className={`bg-text-white text-regular w-[100%] placeholder-gray-place self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                      newAddressValidError.pincode ? "" : "border-red-500"
                    }`}
                    placeholder="i.e. 462016"
                    type="text"
                    id="signup_name_input"
                    value={pincodeInput}
                    onChange={(e) => {
                      setPincodeInput(e.target.value);
                      setNewAddress({
                        ...tempAddress,
                        pincode: e.target.value,
                      });
                      setNewAddressValidError({
                        ...newAddressValidError,
                        pincode: true,
                      });
                    }}
                  />
                </div>
              </div>
              <div class="flex items-center">
                <input
                  id="default-address-checkbox"
                  type="checkbox"
                  value=""
                  onClick={() =>
                    setNewAddress({ ...newAddress, primary: true })
                  }
                  onChange={() => {
                    setIsPrimaryAddress(!isPrimaryAddress);
                    setNewAddress({ ...newAddress, primary: isPrimaryAddress });
                    console.log({ isPrimaryAddress }, { newAddress });
                  }}
                  styles={{
                    webkitOuterSpinButton: "none",
                    webkitInnerSpinButton: "none",
                    mozAppearnace: "none",
                  }}
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-black rounded focus:ring-blue-500 focus:ring-1"
                />
                <label
                  for="default-address-checkbox"
                  class="ml-2 relative text-xs leading-[135%] text-left"
                >
                  Set primary address
                </label>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className="self-stretch mx-4 my-3 flex flex-row items-start justify-center gap-[12px] sm:flex-col">
        <button
          className={`flex-1 cursor-pointer rounded-sm text-regular bg-text-white shrink-0 flex flex-row items-center justify-center p-3 border-[1px] border-solid border-black sm:flex-[unset] sm:self-stretch hover:[transition:0.2s] hover:mt-[-3px] hover:ml-[-3px] hover:mb-[3px] hover:mr-[3px] hover:shadow-[4px_4px] active:[transition:0.2s] active:mt-[-1px] active:ml-[-1px] active:mb-[1px] active:mr-[1px] active:shadow-[2px_2px]
              ${addNewAddress ? "" : ""}`}
          onClick={() => {
            addNewAddress ? setAddNewAddress(false) : setAddNewAddress(true);
          }}
        >
          <div className="relative leading-[130%]">
            {addNewAddress ? "Cancel" : "Add a new address"}
          </div>
        </button>
        <button
          className={`flex-1 cursor-pointer rounded-sm text-regular shrink-0 flex flex-row items-center justify-center p-3 border-[1px] border-solid border-black sm:flex-[unset] sm:self-stretch hover:[transition:0.2s] hover:mt-[-3px] hover:ml-[-3px] hover:mb-[3px] hover:mr-[3px] hover:shadow-[4px_4px_#FFCD94] active:[transition:0.2s] active:mt-[-1px] active:ml-[-1px] active:mb-[1px] active:mr-[1px] active:shadow-[2px_2px_#FFCD94]
             ${addNewAddress ? "bg-black" : "bg-black"}`}
          onClick={() => {
            addNewAddress ? submitAddNewAddress() : submitConfirmAddress();
          }}
        >
          <div className="relative leading-[130%] text-white">
            {addNewAddress ? "Add" : "Confirm"}
          </div>
        </button>
      </div>
    </div>
  );
};

export default SelectAddress;
