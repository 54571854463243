// modal
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

// Authorization
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const USER_NOT_LOGGED_IN = "USER_NOT_LOGGED_IN";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const ACTIVE_LISTINGS = "ACTIVE_LISTINGS";
export const USER_OFFERS = "USER_OFFERS";
export const USER_NOTIFICATIONS = "USER_NOTIFICATIONS";
export const USER_CHATS = "USER_CHATS";
export const USER_ORDERS = "USER_ORDERS";

// Product
export const SAVE_DETAILS = "SAVE_DETAILS";
export const CLEAR_DETAILS = "CLEAR_DETAILS";
export const LIST_PRODUCT = "LIST_PRODUCT";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const LISTING_OFFERS = "LISTING_OFFERS";
export const PRODUCT_COMPRESSED_IMAGES = "PRODUCT_COMPRESSED_IMAGES";
export const PRODUCT_ORIGINAL_IMAGES = "PRODUCT_ORIGINAL_IMAGES";
export const CLEAR_IMAGES = "CLEAR_IMAGES";

//drafts
export const USER_DRAFTS = "USER_DRAFTS";
export const DELETE_DRAFT = "DELETE_DRAFT";

// Cart
export const ADD_ADDRESS = "ADD_ADDRESS";
export const SET_CART_ADDRESS = "SET_CART_ADDRESS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const USER_CART = "USER_CART";

//categories
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";

// item Specifics
export const ITEM_SPECIFICS = "ITEM_SPECIFICS";

// admin
export const GET_USERS = "GET_USERS";
