import Product from "./Product";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { uploadProductImage } from "../redux/actions/product";
import { getAllProducts } from "../redux/actions/product";
import { toast } from "react-toastify";

const ProductList = ({ title, product }) => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log(isLoading);
    // try {
    dispatch(getAllProducts());
    // } catch (error) {
    //   toast.error("Couldn't fetch items from the server: " + error);
    // } finally {
    //   setIsLoading(false);
    //   console.log(isLoading);
    // }
    //console.log(products);
    setIsLoading(false);
  }, []);

  const displayedProducts = products?.slice(0, 12);
  // console.log(displayedProducts);

  return (
    <div className="self-stretch bg-text-white overflow-hidden flex flex-col items-start justify-start pt-9 px-14 pb-8 gap-[18px] pl-[4%] pr-[4%] text-center text-5xl text-black font-general-text border-b-[1.5px] border-t-[1.5px] border-solid border-black lg:gap-[16px] lg:pt-6 lg:pb-6 lg:box-border sm:gap-[10px] sm:border-t-[1.5px] mq840:border-t-[1.5px] sm:pt-6 sm:pb-5 sm:box-border mq840:gap-[16px] mq840:pt-6 mq840:pb-6 mq840:box-border">
      <div className="self-stretch flex flex-row items-center justify-between">
        <div className="relative leading-[120%] lg:text-title sm:text-md mq840:text-md">
          {title}
        </div>
        <a className="[text-decoration:none] relative text-md leading-[130%] text-[inherit] text-left cursor-pointer hover:cursor-pointer hover:[text-decoration:underline] lg:text-regular">
          View all
        </a>
      </div>
      {isLoading || !displayedProducts ? (
        <div className="flex flex-row gap-[8px]">
          <div className="self-stretch overflow-hidden flex flex-col items-start justify-start gap-[0px] w-[228px] lg:w-[199px] sm:w-[164px] mq840:w-[199px]">
            <div className="skeleton-loader !w-[210px] !h-[210px] m-2 rounded-sm z-[3] lg:!w-[185px] lg:!h-[185px] lg:m-1.5 lg:rounded-sm sm:!w-[150px] sm:!h-[150px] sm:m-1.5 sm:rounded-sm mq840:!w-[185px] mq840:!h-[185px] mq840:m-1.5"></div>
            <div className="self-stretch flex flex-col mb-2 mx-2 lg:mb-1.5 lg:mx-1.5 sm:mb-1.5 sm:mx-1.5 mq840:mb-1.5 mq840:mx-1.5 overflow-hidden gap-[4px]">
              <div className="skeleton-loader min-h-[25px] "></div>
              <div className="skeleton-loader min-h-[25px] "></div>
              <div className="skeleton-loader max-w-[80%] min-h-[25px] "></div>
            </div>
          </div>
        </div>
      ) : !displayedProducts ? (
        <div className="self-stretch flex flex-row items-center justify-center h-[300px] gap-[8px]">
          <a className="text-md leading-[130%] text-[inherit] text-left">
            {/* {console.log(!displayedProducts)} */}
            Nothing to see here
          </a>
        </div>
      ) : (
        <div className="self-stretch flex flex-row items-center justify-start gap-[42px] [column-gap:24px] overflow-x-auto pt-1.5 pb-1 pr-0 pl-[15px] lg:flex lg:[column-gap:24px] lg:overflow-x-auto sm:flex sm:[column-gap:16px] sm:overflow-x-auto sm:pt-1.5 sm:pb-1 sm:pr-0 sm:pl-3 mq840:flex mq840:[column-gap:24px] mq840:overflow-x-auto mq840:pt-1.5 mq840:pb-1 mq840:pr-0 mq840:pl-3">
          {displayedProducts?.map(
            (product, index) =>
              product.status.state === "active" && (
                <Product product={product} key={index} isBuying={true} />
              )
          )}
        </div>
      )}
    </div>
  );
};

export default ProductList;
