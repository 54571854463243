import Navbar from "../components/Navbar";
import Product from "../components/Product";
import ProductList from "../components/ProductList";
import Endcredits from "../components/Endcredits";
import RecentlyViewedList from "../components/RecentlyViewedList";
import { useEffect } from "react";

const Landing = () => {
  useEffect(() => {
    //console.log({ loading });
  }, []);

  return (
    <div className="relative bg-primary w-full flex flex-col items-start justify-start pt-6 px-0 pb-0 box-border text-left text-title text-black font-general-text sm:pt-0 sm:box-border mq840:pt-0 mq840:box-border">
      <Navbar
        icon1="/icon1.svg"
        vector="/vector.svg"
        ellipse5="/ellipse-5@2x.png"
        signUpFramePadding="16px"
        signUpFrameGap="16px"
        frameDivCursor="pointer"
        vector1="/vector1.svg"
        vector2="/vector2.svg"
        navbarAlignSelf="stretch"
        navbarWidth="unset"
        signedInFrameMarginTop="unset"
        frame1165="/frame-1165.svg"
        communicationContactEMail="/communicationcontactemailsvgrepocom-1.svg"
        offerSvgrepoCom21="/offersvgrepocom2-1.svg"
        orderSvgrepoCom1="/ordersvgrepocom-1.svg"
        rewindButtonSvgrepoCom1="/rewindbuttonsvgrepocom-1.svg"
        saleSvgrepoCom1="/salesvgrepocom-1.svg"
        idWalletSvgrepoCom1="/idwalletsvgrepocom-1.svg"
      />
      <div className="self-stretch bg-primary flex flex-row items-center justify-between py-[100px] px-[145px] text-117xl border-b-[2px] border-solid border-black lg:pl-[145px] lg:box-border md:pl-20 md:pr-20 md:box-border sm:h-auto sm:flex-col sm:gap-[16px] sm:py-9 sm:px-[30px] sm:box-border mq840:flex-col mq840:gap-[24px] mq840:py-[72px] mq840:px-[60px] mq840:box-border">
        <div className="relative leading-[120%] font-medium lg:text-117xl md:text-[100px] sm:text-[60px] mq840:text-[72px]">
          Save
        </div>
        <div className="relative text-md leading-[130%] text-center inline-block w-[395px] shrink-0 md:text-regular sm:[white-space:break-spaces] sm:w-full">
          <p className="m-0">Real deals, unreal prices.</p>
          <p className="m-0">
            <span>{`Rest easy knowing Stake Atlas buyer protection has you covered, `}</span>
            <span className="font-medium font-general-text">
              every purchase, every time.
            </span>
          </p>
        </div>
      </div>
      <div className="self-stretch bg-text-white overflow-hidden flex flex-row items-start justify-start md:flex-col sm:flex-col mq840:flex-col">
        <RecentlyViewedList />
        <div className="self-stretch flex-1 flex flex-col items-start justify-start md:flex-row md:flex-[unset] md:self-stretch sm:h-auto sm:flex-col sm:flex sm:flex-[unset] sm:self-stretch mq840:h-auto mq840:flex-row mq840:flex-[unset] mq840:self-stretch">
          <div className="self-stretch flex-1 flex flex-col items-center justify-start py-9 px-14 gap-[24px] pl-[12%] pr-[12%] border-b-[2px] border-solid border-black lg:gap-[16px] lg:pt-6 lg:pb-6 lg:box-border md:pl-[4%] md:pr-[4%] md:border-r-[2px] md:border-solid md:border-black sm:gap-[16px] sm:pt-6 sm:pb-6 sm:box-border sm:pl-[4%] sm:pr-[4%] mq840:h-auto mq840:gap-[16px] mq840:pt-6 mq840:pb-9 mq840:box-border mq840:pl-[4%] mq840:pr-[4%] mq840:border-r-[2px] mq840:border-solid mq840:border-black">
            <div className="relative leading-[120%] lg:text-title sm:text-md mq840:text-md">
              Popular destinations
            </div>
            <div className="self-stretch flex-1 grid flex-row flex-wrap items-start justify-center [row-gap:24px] grid-cols-[repeat(auto-fill,_minmax(95px,_1fr))] text-md lg:[row-gap:10px] lg:grid lg:justify-center lg:grid-cols-[repeat(auto-fill,_minmax(70px,_1fr))] md:[row-gap:24px] md:grid md:justify-center md:grid-cols-[repeat(auto-fill,_minmax(80px,_1fr))] sm:[row-gap:24px] sm:grid sm:justify-center sm:grid-cols-[repeat(auto-fill,_minmax(100px,_1fr))] mq840:[row-gap:24px] mq840:grid mq840:justify-center mq840:grid-cols-[repeat(auto-fill,_minmax(88px,_1fr))]">
              <a className="[text-decoration:none] flex flex-col items-center justify-start gap-[12px] cursor-pointer text-[inherit] [&_.phones]:hover:[text-decoration:underline] [&_.phones]:hover:[transition:0.2s] sm:flex">
                <img
                  className="relative rounded-sm w-[90px] h-[90px] overflow-hidden shrink-0 lg:w-[70px] lg:h-[70px] lg:rounded-none sm:w-[87px] sm:h-[87px] sm:rounded-none mq840:w-20 mq840:h-20 mq840:rounded-none"
                  alt=""
                  src="/smartphonesvgrepocom-1.svg"
                />
                <div className="phones relative leading-[120%] lg:text-regular">
                  Phones
                </div>
              </a>
              <a className="[text-decoration:none] flex flex-col items-center justify-start gap-[12px] cursor-pointer text-[inherit] [&_.phones]:hover:[text-decoration:underline] [&_.phones]:hover:[transition:0.2s] sm:flex">
                <img
                  className="relative w-[90px] h-[90px] overflow-hidden shrink-0 lg:w-[70px] lg:h-[70px] sm:w-[87px] sm:h-[87px] mq840:w-20 mq840:h-20"
                  alt=""
                  src="/laptopsvgrepocom4-1.svg"
                />
                <div className="phones relative leading-[120%] lg:text-regular">
                  Laptops
                </div>
              </a>
              <a className="[text-decoration:none] flex flex-col items-center justify-start gap-[12px] cursor-pointer text-[inherit] [&_.phones]:hover:[text-decoration:underline] [&_.phones]:hover:[transition:0.2s]">
                <img
                  className="relative w-[90px] h-[90px] overflow-hidden shrink-0 lg:w-[70px] lg:h-[70px] sm:w-[87px] sm:h-[87px] mq840:w-20 mq840:h-20"
                  alt=""
                  src="/cpusvgrepocom3-1.svg"
                />
                <div className="phones relative leading-[120%] lg:text-regular">
                  PC Parts
                </div>
              </a>
              <a className="[text-decoration:none] flex flex-col items-center justify-start gap-[12px] cursor-pointer text-[inherit] [&_.phones]:hover:[text-decoration:underline] [&_.phones]:hover:[transition:0.2s]">
                <img
                  className="relative w-[90px] h-[90px] overflow-hidden shrink-0 lg:w-[70px] lg:h-[70px] sm:w-[87px] sm:h-[87px] mq840:w-20 mq840:h-20"
                  alt=""
                  src="/tablet.svg"
                />
                <div className="phones relative leading-[120%] lg:text-regular">
                  Tablets
                </div>
              </a>
              <a className="[text-decoration:none] flex flex-col items-center justify-start gap-[12px] cursor-pointer text-[inherit] [&_.phones]:hover:[text-decoration:underline] [&_.phones]:hover:[transition:0.2s]">
                <img
                  className="relative w-[90px] h-[90px] lg:w-[70px] lg:h-[70px] sm:w-[87px] sm:h-[87px] mq840:w-20 mq840:h-20"
                  alt=""
                  src="/frame-1028.svg"
                />
                <div className="phones relative leading-[120%] lg:text-regular">
                  Earphones
                </div>
              </a>
              <a className="[text-decoration:none] flex flex-col items-center justify-start gap-[12px] cursor-default-[point] text-[inherit] [&_.phones]:hover:[text-decoration:underline] [&_.phones]:hover:[transition:0.2s]">
                <img
                  className="relative w-[90px] h-[90px] overflow-hidden shrink-0 lg:w-[70px] lg:h-[70px] sm:w-[87px] sm:h-[87px] mq840:w-20 mq840:h-20"
                  alt=""
                  src="/sportsshoesvgrepocom-1.svg"
                />
                <div className="phones relative leading-[120%] lg:text-regular">
                  Sneakers
                </div>
              </a>
            </div>
          </div>
          <div className="self-stretch flex-1 bg-background flex flex-col items-center justify-start py-9 px-14 gap-[24px] pl-[12%] pr-[12%] border-b-[2px] border-solid border-black lg:gap-[16px] lg:pt-6 lg:pb-6 lg:box-border md:pl-[4%] md:pr-[4%] sm:h-auto sm:gap-[16px] sm:[transform:rotate(0deg)] sm:pt-6 sm:pb-6 sm:box-border sm:pl-[8%] sm:pr-[8%] sm:min-h-[350px] mq840:h-auto mq840:gap-[16px] mq840:pt-6 mq840:pb-6 mq840:box-border mq840:pl-[4%] mq840:pr-[4%]">
            <div className="relative leading-[120%] lg:text-title sm:text-md mq840:text-md">
              From our blog
            </div>
            <a
              className="[text-decoration:none] self-stretch flex-1 flex flex-col items-center justify-start gap-[12px] cursor-pointer text-regular text-[inherit] [&_.buyers-guide-for]:hover:cursor-pointer [&_.buyers-guide-for]:hover:[text-decoration:underline]"
              id="blog"
            >
              <div className="self-stretch flex-1 relative cursor-pointer border-[1px] border-solid border-black">
                <img
                  className="absolute top-[0px] left-[0px] w-full h-full object-cover"
                  alt=""
                  src="/image-41@2x.png"
                />
              </div>
              <p
                className="buyers-guide-for m-0 relative leading-[120%] cursor-pointer lg:text-regular"
                id="blog_title"
              >
                Buyers guide for 2009-2013 BMW 3 Series F30
              </p>
            </a>
          </div>
        </div>
      </div>
      <div className="self-stretch bg-new-black overflow-hidden flex flex-row items-center justify-start p-9 gap-[36px] text-center text-11xl text-text-white sm:flex-col sm:gap-[30px] sm:pl-[4%] sm:pr-[4%] mq840:flex-col">
        <div className="flex-1 flex flex-col items-center justify-center gap-[35px] pr-[2%] border-r-[1px] border-dashed border-gray-800 lg:gap-[24px] sm:gap-[0px] sm:[border-right:0] sm:[border-bottom:dashed] sm:pb-6 sm:flex-[unset] sm:self-stretch mq840:gap-[16px] mq840:[border-right:0] mq840:[border-bottom:dashed] mq840:pb-6 mq840:flex-[unset] mq840:self-stretch">
          <b className="self-stretch relative leading-[130%] lg:text-title md:text-title">
            You discover
          </b>
          <div className="self-stretch flex flex-row items-center justify-between text-[42px]">
            <img
              className="relative w-[130px] h-[124.1px] lg:w-1/5 sm:w-1/5 mq840:w-1/5"
              alt=""
              src="/frame-1025.svg"
            />
            <b className="relative leading-[130%] [text-shadow:4px_4px_0px_#ffdc25] lg:text-11xl md:text-11xl sm:font-bold sm:font-general-text sm:text-title">
              B A R G A I N S
            </b>
            <img
              className="relative w-[130.1px] h-[122.5px] lg:w-3/12 sm:w-3/12 mq840:w-3/12"
              alt=""
              src="/frame-1024.svg"
            />
          </div>
          <b className="self-stretch relative leading-[130%] lg:text-title md:text-title">
            We manage the rest
          </b>
        </div>
        <div className="self-stretch flex-1 flex flex-col items-center justify-center gap-[24px] text-left text-title sm:gap-[24px] sm:flex-[unset] sm:self-stretch mq840:flex-[unset] mq840:self-stretch">
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
            <img
              className="relative w-8 h-8 overflow-hidden shrink-0"
              alt=""
              src="/truck01svgrepocom-1.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-center gap-[8px] sm:gap-[4px]">
              <div className="relative leading-[130%] font-medium md:text-md sm:text-md">
                Seamless Delivery
              </div>
              <div className="self-stretch relative text-regular leading-[130%] font-light sm:text-regular">
                Get items delivered to your doorstep - Why limit your search
                just to your city
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px] sm:gap-[16px]">
            <img
              className="relative w-8 h-8 overflow-hidden shrink-0"
              alt=""
              src="/paymentcardsvgrepocom-1.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-center gap-[8px] sm:gap-[4px]">
              <div className="relative leading-[130%] font-medium md:text-md">
                Easy Payments
              </div>
              <div className="self-stretch relative text-regular leading-[130%] font-light sm:text-regular">
                Credit/Debit card, UPI, Netbanking - All the same for us but
                multiple options for you
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
            <img
              className="relative w-8 h-8 overflow-hidden shrink-0"
              alt=""
              src="/shieldchecksvgrepocom-1.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-center gap-[8px] sm:gap-[4px]">
              <div className="relative leading-[130%] font-medium md:text-md">
                Coin or Bill Purchase Protection
              </div>
              <div className="self-stretch relative text-regular leading-[130%] font-light sm:text-regular">
                What you see is what you get - Get your money back if the item
                doesn’t arrive or match the description.
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductList title={"New listings"} />
      <div className="self-stretch bg-text-white flex flex-col items-start justify-center py-9 px-[63px] gap-[24px] pl-[4%] pr-[4%] text-gray-300 border-b-[2px] border-solid border-black lg:gap-[16px] lg:pt-6 lg:pb-6 lg:box-border sm:gap-[16px] sm:pt-6 sm:pb-6 sm:box-border sm:pl-[4%] sm:pr-[4%] mq840:gap-[16px] mq840:pt-6 mq840:pb-9 mq840:box-border mq840:pl-[4%] mq840:pr-[4%]">
        <div className="relative leading-[140%] lg:text-title md:text-title sm:text-md mq840:text-md">
          Top sellers in your area
        </div>
        <div className="self-stretch flex flex-row items-center justify-start gap-[42px] [column-gap:24px] overflow-x-auto pt-1.5 pb-1 pr-0 pl-3 text-regular lg:flex lg:[column-gap:24px] lg:overflow-x-auto sm:flex sm:[column-gap:16px] sm:overflow-x-auto sm:pt-1.5 sm:pb-1 sm:pr-0 sm:pl-1.5 mq840:flex mq840:[column-gap:24px] mq840:overflow-x-auto mq840:pt-1.5 mq840:pb-1 mq840:pr-0 mq840:pl-3">
          <div className="flex-1 bg-background box-border flex flex-row items-center justify-between p-4 gap-[24px] min-w-[300px] [transition:0.2s] relative cursor-pointer border-[1px] border-solid border-black hover:top-[-3px] hover:shadow-[6px_6px] hover:[transition:0.2s] hover:left-[-3px] hover:bottom-[3px] active:[transition:0.2s] active:left-[-2px] active:shadow-[2px_2px] active:top-[-2px] active:bottom-[2px] lg:gap-[24px] lg:min-w-[350px] md:items-center md:justify-between md:gap-[0px] md:pt-3 md:pb-3 md:box-border md:min-w-[350px] sm:flex-1 sm:flex-row sm:gap-[16px] sm:pl-4 sm:box-border sm:min-w-[320px] mq840:flex-1 mq840:min-w-[300px]">
            <div className="flex flex-col items-start justify-start gap-[24px] lg:gap-[24px] sm:flex-1">
              <div className="flex flex-col items-start justify-start gap-[4px]">
                <div className="flex flex-row items-center justify-start gap-[4px] text-regular">
                  <div className="relative leading-[140%]">Rakesh Roshan</div>
                  <img
                    className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                    alt=""
                    src="/verifysvgrepocom-1.svg"
                  />
                </div>
                <div className="relative leading-[140%] text-xs">
                  Member since April, 2023
                </div>
                <div className="flex flex-row items-center justify-start gap-[4px]">
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/mapmarker2-1.svg"
                  />
                  <div className="relative leading-[140%] text-xs">
                    Ashok Vihar, Delhi
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-[4px]">
                <div className="flex flex-row items-start justify-start gap-[4px]">
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-5.svg"
                  />
                </div>
                <a
                  className="[text-decoration:underline] relative leading-[140%] text-xs"
                  id="sellercard_reviews"
                >
                  10 reviews and ratings
                </a>
              </div>
            </div>
            <div className="relative bg-text-white w-[114px] h-[114px] overflow-hidden shrink-0 rounded-[50%]">
              <img
                className="absolute top-[0.1px] left-[0px] w-full h-full object-cover"
                alt=""
                src="/image-43@2x.png"
              />
            </div>
          </div>
          <div className="flex-1 bg-background box-border flex flex-row items-center justify-between p-4 gap-[24px] min-w-[300px] [transition:0.2s] relative cursor-pointer border-[1px] border-solid border-black hover:top-[-3px] hover:shadow-[6px_6px] hover:[transition:0.2s] hover:left-[-3px] hover:bottom-[3px] active:[transition:0.2s] active:left-[-2px] active:shadow-[2px_2px] active:top-[-2px] active:bottom-[2px] lg:gap-[24px] lg:min-w-[350px] md:items-center md:justify-between md:gap-[0px] md:pt-3 md:pb-3 md:box-border md:min-w-[350px] sm:flex-1 sm:flex-row sm:gap-[16px] sm:pl-4 sm:box-border sm:min-w-[320px] mq840:flex-1 mq840:min-w-[300px]">
            <div className="flex flex-col items-start justify-start gap-[24px] lg:gap-[24px] sm:flex-1">
              <div className="flex flex-col items-start justify-start gap-[4px]">
                <div className="flex flex-row items-center justify-start gap-[4px] text-regular">
                  <div className="relative leading-[140%]">Rakesh Roshan</div>
                  <img
                    className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                    alt=""
                    src="/verifysvgrepocom-1.svg"
                  />
                </div>
                <div className="relative leading-[140%] text-xs">
                  Member since April, 2023
                </div>
                <div className="flex flex-row items-center justify-start gap-[4px]">
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/mapmarker2-1.svg"
                  />
                  <div className="relative leading-[140%] text-xs">
                    Ashok Vihar, Delhi
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-[4px]">
                <div className="flex flex-row items-start justify-start gap-[4px]">
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-5.svg"
                  />
                </div>
                <a
                  className="[text-decoration:underline] relative leading-[140%] text-xs"
                  id="sellercard_reviews"
                >
                  10 reviews and ratings
                </a>
              </div>
            </div>
            <div className="relative bg-text-white w-[114px] h-[114px] overflow-hidden shrink-0 rounded-[50%]">
              <img
                className="absolute top-[0.1px] left-[0px] w-full h-full object-cover"
                alt=""
                src="/image-43@2x.png"
              />
            </div>
          </div>
          <div className="flex-1 bg-background box-border flex flex-row items-center justify-between p-4 gap-[24px] min-w-[300px] [transition:0.2s] relative cursor-pointer border-[1px] border-solid border-black hover:top-[-3px] hover:shadow-[6px_6px] hover:[transition:0.2s] hover:left-[-3px] hover:bottom-[3px] active:[transition:0.2s] active:left-[-2px] active:shadow-[2px_2px] active:top-[-2px] active:bottom-[2px] lg:gap-[24px] lg:min-w-[350px] md:items-center md:justify-between md:gap-[0px] md:pt-3 md:pb-3 md:box-border md:min-w-[350px] sm:flex-1 sm:flex-row sm:gap-[16px] sm:pl-4 sm:box-border sm:min-w-[320px] mq840:flex-1 mq840:min-w-[300px]">
            <div className="flex flex-col items-start justify-start gap-[24px] lg:gap-[24px] sm:flex-1">
              <div className="flex flex-col items-start justify-start gap-[4px]">
                <div className="flex flex-row items-center justify-start gap-[4px] text-regular">
                  <div className="relative leading-[140%]">Rakesh Roshan</div>
                  <img
                    className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                    alt=""
                    src="/verifysvgrepocom-1.svg"
                  />
                </div>
                <div className="relative leading-[140%] text-xs">
                  Member since April, 2023
                </div>
                <div className="flex flex-row items-center justify-start gap-[4px]">
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/mapmarker2-1.svg"
                  />
                  <div className="relative leading-[140%] text-xs">
                    Ashok Vihar, Delhi
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-[4px]">
                <div className="flex flex-row items-start justify-start gap-[4px]">
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-1.svg"
                  />
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/star-5.svg"
                  />
                </div>
                <a
                  className="[text-decoration:underline] relative leading-[140%] text-xs"
                  id="sellercard_reviews"
                >
                  10 reviews and ratings
                </a>
              </div>
            </div>
            <div className="relative bg-text-white w-[114px] h-[114px] overflow-hidden shrink-0 rounded-[50%]">
              <img
                className="absolute top-[0.1px] left-[0px] w-full h-full object-cover"
                alt=""
                src="/image-43@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
