import jwtDecode from "jwt-decode";
import { compactVerify, importJWK } from "jose";

export default function checkTokens() {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = localStorage.getItem("accessToken");
    // console.log("Checking Access Token -> " + accessToken);
    if (!refreshToken && !accessToken) {
      return false;
    }

    // first check, if you have a valid access_token
    if (accessToken) {
      // accessToken may be invalid, or expired, or no refreshToken or refreshToken present or refreshToken may be invalid
      try {
        // decode the token
        // invalid or malformed token will throw error
        const atoken = jwtDecode(accessToken);

        let exp = null;

        if (atoken && atoken?.exp) {
          exp = atoken.exp;
        }

        // if no exp date or expired exp date
        if (!exp || exp < new Date().getTime() / 1000) {
          // invalid accessToken
          // now check for refreshToken
          if (refreshToken) {
            const rtoken = jwtDecode(refreshToken);
            let exp = null;

            if (rtoken && rtoken?.exp) {
              exp = rtoken.exp;
            }

            // if no exp date or expired exp date
            if (!exp || exp < new Date().getTime() / 1000) {
              return false;
            }
          } else {
            return false;
          }
        }
      } catch {
        // invalid accessToken
        // now check for refreshToken
        if (refreshToken) {
          const rtoken = jwtDecode(refreshToken);
          let exp = null;

          if (rtoken && rtoken?.exp) {
            exp = rtoken.exp;
          }

          // if no exp date or expired exp date
          if (!exp || exp < new Date().getTime() / 1000) {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      // we have refreshToken
      // check if refreshToken exists or not
      const rtoken = jwtDecode(refreshToken);
      let exp = null;

      if (rtoken && rtoken?.exp) {
        exp = rtoken.exp;
      }

      // if no exp date or expired exp date
      if (!exp || exp < new Date().getTime() / 1000) {
        return false;
      }
    }

    // valid token
    return true;
  } catch (e) {
    return false;
  }
}

// export default async function checkTokens() {
//   const jwk = {
//     kty: "RSA",
//     n: "stz6GXULQ_svMi2z4XchlG0B1Y346d5WNhfvG8wuxUPaP-CZ3POQJrNozW581YTNnww0T0454wNBl6ceEnXx1NpVx_ZiWunFMy8meR8lbLkPBbSoOsm4-P8Vdp6qb5lmhGH1WETFnpBziUGA0-4ypWf3s73pcBLvim2T74QFG5D0LkMXPSOEY4Lx2QUz0omskWeXdrQgtsH9apzj4hDwMNwS6cUhSEH9lCi2mkIGT6qubBomD0hJBlj0DtEB9xPETnjNViDT8WXPWgcczIc3MK_vDaPXP1GQMSOpoRKPBTtWdTJqgtWq31bN9_Zvd2Kcp0qQozMkUBIQFxpJCI8lGQ",
//     e: "AQAB", // Convert exponent to Base64Url
//     alg: "RS256",
//   };

//   console.log(jwk);

//   const accessToken = localStorage.getItem("accessToken");

//   try {
//     // Decode the JWT
//     const decodedToken = JSON.parse(atob(accessToken.split(".")[1]));

//     // Verify the JWT signature using the provided public key
//     const publicKey = await importJWK(jwk);
//     console.log("Decoded Token:", decodedToken);
//     console.log("Public Key:", publicKey);
//     const verified = await compactVerify(accessToken, publicKey);

//     // Check if the token is expired
//     if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
//       console.error("JWT has expired");
//       return false;
//       // Handle the case where the token is expired
//     } else {
//       // If verification is successful and the token is not expired, you can use the decoded payload
//       console.log("JWT Signature Verified:", decodedToken);
//       return true;
//     }
//   } catch (error) {
//     // If an error occurs during verification or decoding, handle it appropriately
//     console.error("JWT Verification Failed:", error.message);
//     return false;
//   }
// }

export const getTokens = () => {
  // check if the user has a valid or a access_token refresh_token
  if (checkTokens()) {
    return {
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
    };
  }

  removeTokens();
  return {
    accessToken: null,
    refreshToken: null,
  };
};

export const saveTokens = (accessToken, refreshToken) => {
  if (accessToken) localStorage.setItem("accessToken", accessToken);
  if (refreshToken) localStorage.setItem("refreshToken", refreshToken);
};

// fn to save new access token
export const saveAccessTokens = (accessToken) => {
  if (accessToken) localStorage.setItem("accessToken", accessToken);
};

// fn to remove tokens
export const removeTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  return;
};
