import { useEffect, useState, useMemo, useCallback, useContext } from "react";
import Ham from "./Ham";
import PortalDrawer from "./PortalDrawer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../App";
import { getUserNotifications, logoutUser } from "../redux/actions/auth";
import { socket } from "../service/socket";
import axiosInstance from "../axiosInstance";
import { io } from "socket.io-client";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [allUserNotifications, setAllUserNotifications] = useState([]);

  //const socket = io("http://localhost:4000");
  //const socket = useContext(SocketContext);

  socket.on("notificationUpdate", (newData) => {
    setAllUserNotifications(newData.data);
  });

  useEffect(() => {
    if (user) {
      socket.emit("setUserId", user._id);
      //console.log(user);
      socket.emit("getAll", user._id);
    }
  }, [user]);

  const handleNotificationClick = async (notificationId) => {
    try {
      console.log(notificationId);
      socket.emit("markAsRead", { notificationId: notificationId });
    } catch (error) {
      console.error("Error marking notification as read", error);
    }
  };

  useEffect(() => {
    //console.log(allUserNotifications);
  }, [allUserNotifications]);

  const [isHamOpen, setHamOpen] = useState(false);

  const openHam = useCallback(() => {
    setHamOpen(true);
  }, []);

  const closeHam = useCallback(() => {
    setHamOpen(false);
  }, []);

  return (
    <>
      <nav
        className="my-0 mx-[2%] bg-text-white box-border flex flex-row items-center justify-center text-left text-regular text-gray-100 font-general-text self-stretch border-[1.5px] border-solid border-black md:pl-0 md:pr-0 md:box-border sm:w-full sm:gap-[447px] sm:pl-0 sm:pr-4 sm:box-border sm:[border-top:0] sm:[border-left:0] sm:[border-right:0] sm:m-0 mq840:pl-2 mq840:pr-6 mq840:box-border mq840:m-0 mq840:[border-right:0] mq840:[border-top:0] mq840:[border-left:0]"
        id="navbar"
      >
        <div className="flex-1 flex flex-row items-center justify-between">
          <div className="self-stretch flex-1 flex flex-row items-center justify-start gap-[60px]">
            <div className="flex-1 flex flex-row items-center justify-start py-0 px-6 gap-[24px] lg:gap-[24px] md:gap-[16px] sm:hidden mq840:hidden">
              <img
                className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-row items-center justify-end"
                id="logo_buttn"
                onClick={() => {
                  navigate("/");
                }}
                src="/WWHM.svg"
              >
                {/* <div className="relative text-xl leading-[130%] font-general-text text-white border-[1px] border-solid border-black text-left lg:text-11xl mq840:hidden">
                  WhoWhatHowmuch
                </div> */}
              </img>
              <div className="bg-text-white flex flex-col items-end justify-center">
                <div className="bg-text-white flex flex-row items-center justify-start py-1.5 px-2 gap-[8px]">
                  <img
                    className="relative w-6 h-6"
                    alt=""
                    src="/frame-1165.svg"
                  />
                  <input
                    className="[border:none] font-general-text text-regular bg-[transparent] relative w-[190px] h-[22px] focus:[outline:none]"
                    id="search_navbar_input"
                    placeholder="iPhone 12 mini, skoda fabia, "
                    type="text"
                  />
                </div>
                <div className="self-stretch hidden flex-col items-end justify-center p-2 cursor-pointer">
                  <div className="relative leading-[22px]">{`iPhone 12 regular, skoda fabia, `}</div>
                </div>
                <div className="self-stretch hidden flex-col items-end justify-center p-2 cursor-pointer">
                  <div className="relative leading-[22px]">{`iPhone 12 regular, skoda fabia, `}</div>
                </div>
                <div className="self-stretch hidden flex-col items-end justify-center p-2 cursor-pointer">
                  <div className="relative leading-[22px]">{`iPhone 12 regular, skoda fabia, `}</div>
                </div>
                <div className="self-stretch hidden flex-col items-end justify-center p-2 cursor-pointer">
                  <div className="relative leading-[22px]">{`iPhone 12 regular, skoda fabia, `}</div>
                </div>
                <div className="self-stretch hidden flex-col items-end justify-center p-2 cursor-pointer">
                  <div className="relative leading-[22px]">{`iPhone 12 regular, skoda fabia, `}</div>
                </div>
              </div>
            </div>
            <div className="hidden flex-row items-center justify-start gap-[4px] text-title text-black sm:flex mq840:flex mq840:gap-[-16px]">
              <div
                className="flex flex-col items-center justify-start py-0 px-5 cursor-pointer sm:gap-[-1px] sm:pl-4 sm:pr-3 sm:box-border mq840:pl-4 mq840:pr-3 mq840:box-border"
                onClick={openHam}
              >
                <img
                  className="relative w-[20.7px] h-[13.4px]"
                  alt=""
                  src="/icon1.svg"
                />
              </div>
              <img
                className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-row items-center justify-end"
                id="logo_buttn"
                onClick={() => {
                  navigate("/");
                }}
                src="/WWHM.svg"
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[36px] text-black">
            <div className="flex flex-row items-center justify-start lg:flex lg:gap-[0px] md:flex md:gap-[0px] sm:hidden sm:gap-[0px] mq840:hidden">
              <div className="self-stretch flex flex-row items-center justify-center py-0 pr-9 pl-0 gap-[48px] lg:gap-[36px] md:gap-[24px] md:pr-6 md:box-border">
                <div className="self-stretch relative w-6 text-lg hover:block hover:z-[2] [&_.notification1]:hover:block [&_.notification1]:hover:z-[2]">
                  <div className="notification1 absolute top-[47.5px] left-[-18px] bg-background box-border w-[260px] overflow-hidden hidden flex-col items-start justify-start p-4 border-[1px] border-solid border-black hover:gap-[0px]">
                    <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-4 hover:pb-4 hover:box-border">
                      <div className="relative leading-[130%]">
                        {`Notifications (${allUserNotifications?.length})`}
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-end justify-center gap-[8px] text-regular">
                      <div className="self-stretch flex flex-col items-center justify-center hover:items-center hover:justify-center">
                        {allUserNotifications?.map((notification, index) => (
                          <a
                            className={`self-stretch overflow-hidden flex flex-row items-center justify-start mt-[-1px] p-2 gap-[8px] cursor-pointer [transition:0.2s] text-[inherit] border-[1px] border-solid border-black hover:bg-whitesmoke sm:pt-2 sm:pb-2 sm:box-border ${
                              notification?.status === "read"
                                ? "bg-gray-100"
                                : "bg-white"
                            }`}
                            id="notification_new_message"
                            //href={notification.link}
                            href={`${notification?.link}`}
                            onClick={() => {
                              handleNotificationClick(notification._id);
                            }}
                            key={index}
                          >
                            <img
                              className="relative w-6 h-6 overflow-hidden shrink-0"
                              alt=""
                              src="/communicationcontactemailsvgrepocom-1.svg"
                            />
                            <div className="flex flex-col items-start justify-center gap-[2px] sm:gap-[2px]">
                              <div className="relative leading-[130%] whitespace-nowrap overflow-hidden text-ellipsis max-w-[180px]">
                                {notification.title}
                              </div>
                              <div className="relative text-xs leading-[130%] whitespace-nowrap overflow-hidden text-ellipsis max-w-[180px]">
                                {notification.content}
                              </div>
                            </div>
                          </a>
                        ))}
                        {/* <a
                          className="[text-decoration:none] self-stretch bg-text-white overflow-hidden flex flex-row items-center justify-start p-2 gap-[8px] cursor-pointer [transition:0.2s] text-[inherit] border-[1px] border-solid border-black hover:bg-whitesmoke sm:pt-2 sm:pb-2 sm:box-border"
                          id="notification_new_message"
                        >
                          <img
                            className="relative w-6 h-6 overflow-hidden shrink-0"
                            alt=""
                            src={communicationContactEMail}
                          />
                          <div className="flex flex-col items-start justify-center gap-[2px] sm:gap-[2px]">
                            <div className="relative leading-[130%]">
                              New message
                            </div>
                            <div className="relative text-xs leading-[130%]">
                              Brand new Macbook Pro...
                            </div>
                          </div>
                        </a>
                        <a
                          className="[text-decoration:none] self-stretch bg-text-white overflow-hidden flex flex-row items-center justify-start p-2 gap-[8px] cursor-pointer [transition:0.2s] mt-[-1px] text-[inherit] border-[1px] border-solid border-black hover:bg-whitesmoke"
                          id="notification_offer_update"
                        >
                          <img
                            className="relative w-6 h-6 overflow-hidden shrink-0"
                            alt=""
                            src={offerSvgrepoCom21}
                          />
                          <div className="flex flex-col items-center justify-start gap-[2px]">
                            <div className="relative leading-[130%]">
                              Offer accepted! Pay now
                            </div>
                            <div className="relative text-xs leading-[130%]">
                              iPhone 12 85% battery health...
                            </div>
                          </div>
                        </a> */}
                        <div className="relative leading-[130%] hidden mt-[-1px]">
                          No new notifications
                        </div>
                        <button
                          className="cursor-pointer [border:none] p-0 bg-[transparent] relative text-regular [text-decoration:underline] leading-[130%] font-general-text text-black text-left hidden mt-[-1px]"
                          id="notif_signin_button"
                        >
                          Sign in to view your notifications
                        </button>
                      </div>
                      <button
                        className="cursor-pointer [border:none] p-0 bg-[transparent] relative text-xs [text-decoration:underline] leading-[130%] font-general-text text-black text-left inline-block"
                        id="notification_viewall_button"
                      >
                        View all
                      </button>
                    </div>
                  </div>
                  <a
                    className="[text-decoration:none] absolute top-[calc(50%_-_12.5px)] left-[calc(50%_-_12px)] w-6 h-6 hover:cursor-pointer"
                    id="notification_navbar_button"
                  >
                    <img
                      className="absolute top-[calc(50%_-_10px)] left-[calc(50%_-_8.5px)] w-4 h-[19px]"
                      alt=""
                      src="/vector.svg"
                    />
                  </a>
                </div>
                <div className="self-stretch relative w-14 hover:self-stretch hover:h-auto hover:block hover:z-[100] [&_.signed-in-frame]:hover:flex [&_.signed-in-frame]:hover:flex-nowrap [&_.sign-up-frame-wrapper]:hover:flex [&_.signed-in-frame-wrapper]:hover:flex [&_.signed-in-frame-wrapper]:hover:mt-2.5">
                  {user ? (
                    <div className="signed-in-frame-wrapper absolute top-[40px] left-[-27px] hidden flex-col items-center justify-start mt-2.5 text-title hover:z-[101]">
                      <div className="signed-in-frame rounded-sm bg-text-white box-border w-[230px] flex flex-col items-end justify-end p-4 gap-[12px] border-[1px] border-solid border-black">
                        <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
                          <img
                            className="relative rounded-[50%] w-[52px] h-[52px] object-cover"
                            alt=""
                            src="/ellipse-5@2x.png"
                          />
                          <div className="flex flex-col items-start justify-start gap-[2px]">
                            <div className="relative leading-[130%]">
                              Hi, {user?.first_name}
                            </div>
                            <div className="relative text-xs leading-[130%] text-gray-500">
                              Member since Jan 2022
                            </div>
                          </div>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-start">
                          <button
                            className="cursor-pointer py-2 px-2.5 bg-text-white self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px] [transition:0.2s] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
                            id="menud_orders_button"
                            onClick={() => navigate("/account/orders")}
                          >
                            <img
                              className="relative w-6 h-6 overflow-hidden shrink-0"
                              alt=""
                              src="/ordersvgrepocom-1.svg"
                            />
                            <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                              Orders
                            </div>
                          </button>
                          <button
                            className="cursor-pointer py-2 px-2.5 bg-text-white self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px] [transition:0.2s] mt-[-3px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
                            id="menud_rv_button"
                          >
                            <img
                              className="relative w-6 h-6 overflow-hidden shrink-0"
                              alt=""
                              src="/recentlyViewed.svg"
                            />
                            <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                              Recently Viewed
                            </div>
                          </button>
                          <button
                            className="cursor-pointer py-2 px-2.5 bg-text-white self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px] [transition:0.2s] mt-[-3px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
                            id="menud_offers_button"
                          >
                            <img
                              className="relative w-6 h-6 overflow-hidden shrink-0"
                              alt=""
                              src="/offersvgrepocom2-1.svg"
                            />
                            <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                              Offers
                            </div>
                          </button>
                          <button
                            className="cursor-pointer py-2 px-2.5 bg-text-white self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px] [transition:0.2s] mt-[-3px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
                            id="menud_selling_button"
                          >
                            <img
                              className="relative w-6 h-6 overflow-hidden shrink-0"
                              alt=""
                              src="/salesvgrepocom-1.svg"
                            />
                            <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                              Selling
                            </div>
                          </button>
                          <button
                            className="cursor-pointer py-2 px-2.5 bg-text-white self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px] [transition:0.2s] mt-[-3px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
                            id="menud_account_button"
                            onClick={() => navigate("/account/info")}
                          >
                            <img
                              className="relative w-6 h-6 overflow-hidden shrink-0"
                              alt=""
                              src="/idwalletsvgrepocom-1.svg"
                            />
                            <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                              Account
                            </div>
                          </button>
                        </div>
                        <button
                          className="cursor-pointer [border:none] p-0 bg-[transparent] relative text-xs leading-[130%] font-general-text text-black text-left inline-block hover:[text-decoration:underline] hover:[transition:0.5s]"
                          id="menud_logout_button"
                          onClick={() => {
                            dispatch(logoutUser());
                            navigate("/");
                          }}
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="sign-up-frame-wrapper absolute top-[41px] left-[-27px] hidden flex-col items-center justify-center mt-2.5">
                      <div className="rounded-sm bg-text-white box-border w-[180px] flex flex-col items-center justify-start p-4 gap-[16px] flex-nowrap border-[1px] border-solid border-black">
                        <a
                          className="[text-decoration:none] self-stretch rounded-sm bg-primary overflow-hidden flex items-center justify-center py-2 [transition:0.2s] cursor-pointer text-[inherit] border-[1px] border-solid border-black hover:ml-[-4px] hover:mt-[-4px] hover:shadow-[4px_4px] hover:mb-1 hover:[transition:0.2s] active:[transition:0.2s] active:shadow-[1px_1px] active:ml-[-2px] active:mr-[-2px] active:mt-[-2px] active:mb-0.5"
                          id="account_signin_button"
                          onClick={() => navigate("/signin")}
                        >
                          <div className="relative leading-[130%]">Sign in</div>
                        </a>
                        <div className="self-stretch flex flex-col items-center justify-start gap-[8px] text-xs">
                          <div className="relative leading-[130%]">
                            New here?
                          </div>
                          <a
                            className="[text-decoration:none] self-stretch rounded-sm bg-text-white overflow-hidden flex flex-row items-center justify-center py-2 [transition:0.2s] cursor-pointer text-regular text-[inherit] border-[1px] border-solid border-black hover:ml-[-4px] hover:mt-[-4px] hover:shadow-[4px_4px] hover:mb-1 hover:[transition:0.2s] active:[transition:0.2s] active:shadow-[1px_1px] active:ml-[-2px] active:mr-[-2px] active:mt-[-2px] active:mb-0.5"
                            id="account_signup_button"
                            onClick={() => navigate("/signup")}
                          >
                            <div className="relative leading-[130%]">
                              Sign up
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                  <a
                    className="[text-decoration:none] absolute top-[calc(50%_-_10px)] left-[0px] leading-[130%] text-[inherit] hover:cursor-pointer hover:[text-decoration:underline]"
                    id="account_navbar_button"
                    onClick={
                      user && localStorage.getItem("accessToken")
                        ? () => navigate("/account")
                        : () => navigate("/signin")
                    }
                  >
                    Account
                  </a>
                </div>
                <a
                  className="[text-decoration:none] relative leading-[130%] text-[inherit] hover:cursor-pointer hover:[text-decoration:underline]"
                  id="blog_navbar_button"
                >
                  Blog
                </a>
              </div>
              <a
                className="[text-decoration:none] bg-primary box-border flex flex-row items-center justify-center py-5 px-6 min-w-[80px] text-[inherit] border-r-[1.5px] border-solid border-black border-l-[1.5px] hover:bg-black hover:cursor-pointer hover:text-white"
                id="sellnow_navbar_button"
                onClick={() => {
                  navigate("/sellnow/drafts");
                }}
              >
                <div className="relative leading-[130%]">Sell Now</div>
              </a>
              <a
                className="[text-decoration:none] self-stretch flex flex-row items-center justify-start py-0 px-9 hover:cursor-pointer hover:bg-whitesmoke md:pl-6 md:pr-6 md:box-border"
                id="cart_navbar_button"
                onClick={() => {
                  navigate("/cart");
                }}
              >
                <img
                  className="relative w-[21.5px] h-[21.5px]"
                  alt=""
                  src="/vector1.svg"
                />
              </a>
            </div>
            <div className="h-[66px] hidden flex-row items-center justify-start sm:flex sm:h-[70px] mq840:flex mq840:h-[70px]">
              <div
                className="rounded-sm  flex flex-row items-start justify-start p-3.5 border-[1.5px] border-solid border-black sm:p-4 sm:box-border mq840:p-4 mq840:box-border"
                onClick={() => {
                  navigate("/cart");
                }}
              >
                <img className="relative w-4 h-4" alt="" src="/vector2.svg" />
              </div>
            </div>
          </div>
        </div>
      </nav>
      {isHamOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeHam}
        >
          <Ham onClose={closeHam} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Navbar;
