import { useState } from "react";
import Navbar from "../components/Navbar";

const TermsShippingDelivery = () => {
  const [frameInputValue, setFrameInputValue] = useState("");
  return (
    <div className="relative bg-whitesmoke-100 w-full overflow-hidden flex flex-col items-start justify-start pt-6 px-0 pb-6 box-border gap-[24px] text-left text-regular text-black font-general-text sm:pt-0 mq840:pt-0 sm:box-border">
      <Navbar
        icon1="/icon1.svg"
        vector="/vector.svg"
        ellipse5="/ellipse-5@2x.png"
        signUpFramePadding="16px"
        signUpFrameGap="16px"
        frameDivCursor="pointer"
        vector1="/vector1.svg"
        vector2="/vector2.svg"
        navbarAlignSelf="stretch"
        navbarWidth="unset"
        signedInFrameMarginTop="unset"
        frame1165="/frame-1165.svg"
        communicationContactEMail="/communicationcontactemailsvgrepocom-1.svg"
        offerSvgrepoCom21="/offersvgrepocom2-1.svg"
        orderSvgrepoCom1="/ordersvgrepocom-1.svg"
        rewindButtonSvgrepoCom1="/rewindbuttonsvgrepocom-1.svg"
        saleSvgrepoCom1="/salesvgrepocom-1.svg"
        idWalletSvgrepoCom1="/idwalletsvgrepocom-1.svg"
      />
      <div className="self-stretch flex flex-col items-start justify-center gap-[16px] pl-[4%] pr-[4%]">
        <div className="flex flex-row items-center justify-start gap-[4px]">
          <a className="cursor-pointer relative [text-decoration:underline] leading-[140%]">
            Home
          </a>
          <div className="relative leading-[140%]">{`>`}</div>
          <a className="cursor-pointer relative [text-decoration:underline] leading-[140%]">
            Help
          </a>
          <div className="relative leading-[140%]">{`>`}</div>
          <a className="cursor-pointer relative [text-decoration:underline] leading-[140%]">
            Shipping and Delivery
          </a>
        </div>
        <div className="flex flex-col relative leading-[140%] font-bold items-start justify-start text-title">
          Shipping and Delivery Policy
        </div>
        <div className="self-stretch bg-text-white flex flex-col items-start justify-start py-4 px-5 text-regular border-[1px] border-solid border-black">
          <p className="self-stretch relative leading-[50%]">
            <span className="font-regular text-regular leading-[150%]">
              CoinOrBill will provide the Seller with one (1) prepaid shipping
              label (“Label”) or QR code per order that supports shipments up to
              five pounds (5 lbs), to send the Item (or Items in the case of a
              Bundle order) to the Buyer. CoinOrBill reserves the right to
              discontinue providing Labels to any or all Users at any time and
              for any reason. Title to the Item transfers to Buyer upon
              shipment.
            </span>
            <br />
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              User Responsibilities
            </span>
            <br />
            <br />
            <ul className="leading-[150%] pl-4">
              <li className="">
                Buyers must pay for any shipping fees as described in our Fee
                Policy, Buyer Policy, or as otherwise described in this Shipping
                Policy.
              </li>
              <li className="mb-0">
                Seller will be fully responsible for contents of shipment and
                must comply with the CoinOrBill Terms of Service, including
                Prohibited Items Policy and terms and conditions of the shipping
                carrier.
              </li>
              <li className="mb-0">
                Seller must ship the Item(s) to the Buyer within seven (7) days
                from the date the order was purchased (as verified by an active
                shipping carrier scan). If Seller does not ship the Item(s)
                within this timeframe, Buyer can cancel the order, and
                CoinOrBill will notify the Seller not to proceed with shipment.
              </li>
              <li className="mb-0">
                Buyers and Sellers must maintain an accurate and up to date ship
                to ship from address with CoinOrBill. CoinOrBill reserves the
                right to charge a User additional fees if a User does not meet
                any of these obligations.
              </li>
              <li className="mb-0">
                Users are prohibited from using Label for any purpose other than
                shipping the purchased item(s) to the buyer. This includes,
                without limitation, altering Label from its original form or
                duplicate use of the same Label.
              </li>
            </ul>
            <br />
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              Shipping Issues
            </span>
            <br />
            <br />
            <p className="leading-[150%]">
              All Labels are prepaid through CoinOrBill’s account with its
              shipping provider. Users are encouraged to report any pre-shipment
              (defined as Label has been created but no acceptance scan is
              present as determined by the carrier tracking information) or
              in-transit (defined as acceptance scan is present but there is no
              delivery scan as determined by the carrier tracking information)
              issues encountered to CoinOrBill for review.
            </p>
            <p className="leading-[150%]">
              Buyer must report any post-delivery (defined as delivery scan is
              present as determined by the carrier tracking information) issues
              (including, but not limited to Unable to locate shipment,
              Undisclosed damage, Incorrect or missing item, Item not as
              described, Item is not authentic) to CoinOrBill within three (3)
              days after the date of delivery scan. Once Buyer confirms receipt
              and acceptance of Item(s) (acceptance can occur either by Buyer
              affirmatively notifying CoinOrBill or by taking no action within
              three (3) days of the date of delivery), CoinOrBill will credit
              Seller’s account with amount equal to purchase price, excluding
              taxes, CoinOrBill’s commission, and any applicable fees (the
              “Funds”) set forth in our Fee Policy.
            </p>
            <p className="leading-[150%]">
              CoinOrBill is not liable for damages due to improper or inadequate
              packaging by Seller. Please note, we cannot guarantee shipping
              times or choice of carriers. While most CoinOrBill carriers offer
              shipping with an estimated transit time of 3 days, CoinOrBill is
              not responsible for carrier delays, which may be caused by a
              number of factors, such as weather
            </p>
            <br />
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              Additional Requirements
            </span>
            <br />
            <br />
            <p className="leading-[150%]">
              For any avoidance of a doubt, User is responsible to ship in
              compliance with{" "}
              <a
                className="text-[inherit]"
                href="https://www.CoinOrBill.com/help/policies/payment-policies/unpaid-item-policy?id=4271"
                target="_blank"
              >
                Unpaid item policy
              </a>
            </p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsShippingDelivery;
