import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../components/Navbar";
import {
  clearProductDetails,
  getAllProducts,
  saveProductDetails,
} from "../redux/actions/product";
import Dropdown from "../components/DropdownSelect";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";
import { upload } from "@testing-library/user-event/dist/upload";
import { userActiveListings } from "../redux/actions/auth";
import { itemSpecifics } from "../redux/actions/category";
import SequentialDropdown from "../components/SequentialDropDown";
import { getUploadURL } from "../redux/actions/product";
import DropdownMultiSelect from "../components/DropdownMultiSelect";
import { getAllCategories } from "../redux/actions/category";
import heic2any from "heic2any";

const { nanoid } = require("nanoid");

function useOutsideAlerter(ref, func) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, func]);
}
const PostAnItem = () => {
  const { user } = useSelector((state) => state.auth);
  const { product } = useSelector((state) => state.product);
  const [info, setInfo] = useState(product);
  const [showRemove, setShowRemove] = useState({});
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [fileToUpload, setFileToUpload] = useState([]);
  const [imagesLink, setImagesLink] = useState([]);
  const [inputPrice, setInputPrice] = useState("");
  const [inputTitle, setInputTitle] = useState("");
  const [inputAddress, setInputAddress] = useState({
    address: "",
    city: "",
    state: "",
    pincode: "",
  });
  const [inputFullAddress, setInputFullAddress] = useState("");
  const [inputLocation, setInputLocation] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [inputSubcategory, setInputSubcategory] = useState("");
  const [makeAnOfferEnabled, setMakeAnOfferEnabled] = useState(false);
  const [inputCondition, setInputCondition] = useState(null);
  const [backEnabled, setBackEnabled] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [heading, setHeading] = useState(false);
  const [backHeading, setBackHeading] = useState("");
  const { categories } = useSelector((state) => state.category);
  const { item_specifics } = useSelector((state) => state.category);
  const [offerButton, setOfferButton] = useState(false);
  const [value, setValue] = useState("");
  const [dropShowing, setDropShowing] = useState(false);
  const [dropActive, setDropActive] = useState(false);
  const [selectedPath, setSelectedPath] = useState([]);
  const [currentCategories, setCurrentCategories] = useState(null);
  const [pastCategories, setPastCategories] = useState([]);
  const [isPrimary, setIsPrimary] = useState(0);
  const gmapkey = "AIzaSyDzt5uKoU9PIrfldJcdqDjf9X-KJNCrohk";
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [isPhotosValid, setIsPhotosValid] = useState(true);
  const [isConditionValid, setIsConditionValid] = useState(true);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [isCategoryValid, setIsCategoryValid] = useState(true);
  const [isModelValid, setIsModelValid] = useState(true);
  const [isPriceValid, setIsPriceValid] = useState(true);
  const [inputAddressValid, setInputAddressValid] = useState({
    address: true,
    city: true,
    state: true,
    pincode: true,
    lat: false,
    long: false,
  });
  const productGCPID = nanoid();
  const dispatch = useDispatch();

  const options = [
    { value: "NEW", label: "NEW", color: "newBlue" },
    { value: "USED", label: "USED", color: "usedYellow" },
    { value: "FORPARTS", label: "FOR PARTS", color: "partsRed" },
  ];
  const [selectedCondition, setSelectedCondition] = useState(
    inputCondition
      ? options.find((option) => option.value === inputCondition)
      : null
  );
  const handleMouseEnter = (index) => {
    setShowRemove((prev) => ({ ...prev, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setShowRemove((prev) => ({ ...prev, [index]: false }));
  };

  const [postingStep, setPostingStep] = useState("one");

  function numberWithIndianCommas(x) {
    // console.log(x.toLocaleString("en-IN"));
    return x.toLocaleString("en-IN");
  }
  useEffect(() => {
    dispatch(getAllCategories());
    const postFormHeight = getComputedStyle(
      document.getElementById("preview")
    ).height;
    document.getElementById("post_form").style.height =
      parseInt(postFormHeight) - 24 + "px";
  }, []);

  useEffect(() => {
    setCurrentCategories(categories);
  }, [categories]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setDropActive);

  const handleItemClick = (item) => {
    setSelectedPath([...selectedPath, item.name]);
    let s = "";
    for (let i = 0; i < selectedPath.length; i++) {
      s = s + selectedPath[i];
      s = s + " > ";
    }
    s = s + item.name;
    // let s = selectedPath.join(" > ")
    if (!item.sub_categories) {
      setCurrentCategories([]);
      console.log("one step here");
    } else {
      if (item.sub_categories.length == 0) {
        if (true) {
          console.log(item._id);
          setInfo({
            ...info,
            category: item._id,
            enable_shipping: item.shipping,
          });
          dispatch(itemSpecifics(item._id));
        }
      }
      //setting category at each call
      axiosInstance
        .get(`/admin/category/subCategories/${item._id}`)
        .then((res) => {
          const { data } = res.data;
          setCurrentCategories(data);
        })
        .catch(({ response }) => {
          try {
            switch (response.status) {
              case 400:
                //   add a toast
                toast.error("Invalid Data!");

                break;
              case 401:
                //   add a toast
                toast.error("Invalid credentials!");

                break;
              default:
                // server error
                toast.error("Oops, something went wrong");

                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
          }
        });
      // setCurrentCategories(item.sub_categories);
    }
    setPastCategories([...pastCategories, currentCategories]);
    setValue(s);
  };

  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const fillCurrentLocation = async () => {
    if ("geolocation" in navigator) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${gmapkey}`;

        try {
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data.results && data.results.length > 0) {
            const addressComponents = data.results[0].address_components;
            const addressDetails = {};

            const componentMapping = {
              premise: "premise",
              sublocality_level_2: "sublocality_level_2",
              sublocality_level_1: "sublocality_level_1",
              locality: "city",
              administrative_area_level_1: "state",
              postal_code: "pincode",
            };

            for (const component of addressComponents) {
              const componentType = component.types.find(
                (type) => componentMapping[type]
              );
              if (componentType) {
                addressDetails[componentMapping[componentType]] =
                  component.long_name;
              }
            }

            const address = [
              addressDetails.premise,
              addressDetails.sublocality_level_2,
              addressDetails.sublocality_level_1,
            ]
              .filter(Boolean)
              .join(", ");

            addressDetails.address_line1 = address;
            delete addressDetails.premise;
            delete addressDetails.sublocality_level_2;
            delete addressDetails.sublocality_level_1;

            console.log(addressDetails);
            const formattedAddress =
              addressDetails.address_line1 +
              `, ` +
              addressDetails.city +
              `, ` +
              addressDetails.state +
              `, ` +
              addressDetails.pincode;

            setInputAddress({
              address: addressDetails.address_line1,
              city: addressDetails.city,
              state: addressDetails.state,
              pincode: addressDetails.pincode,
            });

            setInfo({
              ...info,
              address: {
                lat: latitude,
                long: longitude,
                full: formattedAddress,
              },
            });
            console.log({ lat: latitude, long: longitude });
          } else {
            console.log("No address components found.");
            toast.error("Please Fill the Details");
          }
        } catch (error) {
          console.error("Error fetching geolocation data:", error);
        }
      } catch (error) {
        console.error("Error getting location:", error.message);
      }
    } else {
      toast.info("Geolocation is not supported by this browser.");
      console.log("Geolocation is not supported by this browser.");
    }
    setIsLocationLoading(false);
  };

  const convertAddressToLatLng = async () => {
    const mapsAddress = `${inputAddress.address}, ${inputAddress.city}, ${inputAddress.state}, ${inputAddress.pincode},  India`;
    //copy the above line
    const tempMapsAddress =
      "154 DREAM HOUSE SHARMA'S, Sector 51, Gurugram, Haryana, India";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      mapsAddress
    )}&key=${gmapkey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        const { lat, lng } = location;
        setInfo({
          ...info,
          address: { lat: lat, long: lng, full: mapsAddress },
        });
        setInputAddressValid({ ...inputAddressValid, lat: true, long: true });
        // setInfo((prevState) => {
        //   console.log("name : ", prevState);
        //   return prevState;
        // });
        console.log({ lat, lng, mapsAddress }, "inside convert to lat long");

        return { latitude: lat, longitude: lng };
      } else {
        console.error("No results found for the given address.");
        return null;
      }
    } catch (error) {
      console.error("Error converting address to coordinates:", error);
      return null;
    }
  };

  const handleBack = () => {
    setCurrentCategories(pastCategories[pastCategories.length - 1]);
    setPastCategories(pastCategories.slice(0, pastCategories.length - 1));
    setSelectedPath(selectedPath.slice(0, selectedPath.length - 1));
    let s = "";
    for (let i = 0; i < selectedPath.length - 1; i++) {
      s = s + selectedPath[i];
      if (i !== selectedPath.length - 2) s = s + " > ";
    }
    setValue(s);
  };
  // end of categories code

  // Upload the file to GCS
  const handleFileUpload = async (files) => {
    const updatedImages = [...uploadedImages];
    const updatedFiles = [...fileToUpload];

    for (const file of files) {
      // Check if the file is in HEIC format
      if (file.type === "image/heic") {
        // Use heic2any to convert HEIC to JPEG
        const jpegBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8, // Adjust quality as needed
        });

        // Create a new File with the converted JPEG
        const jpegFile = new File([jpegBlob], `${file.name}.jpg`, {
          type: "image/jpeg",
        });

        // Read the converted JPEG file as a data URL
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          updatedImages.push(base64String);
          updatedFiles.push(jpegFile);

          if (updatedImages.length === uploadedImages.length + files.length) {
            setUploadedImages(updatedImages);
            setFileToUpload(updatedFiles);
          }
        };

        reader.readAsDataURL(jpegFile);
      } else {
        // For non-HEIC files, proceed as usual
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          updatedImages.push(base64String);
          updatedFiles.push(file);

          if (updatedImages.length === uploadedImages.length + files.length) {
            setUploadedImages(updatedImages);
            setFileToUpload(updatedFiles);
          }
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedImages = uploadedImages.filter(
      (_, index) => index !== indexToRemove
    );
    const updatedFiles = fileToUpload.filter(
      (_, index) => index !== indexToRemove
    );
    setFileToUpload(updatedFiles);
    setUploadedImages(updatedImages);
  };

  const handleMakePrimary = (indexToMakePrimary) => {
    setUploadedImages((prevImages) => {
      if (indexToMakePrimary < 0 || indexToMakePrimary >= prevImages.length) {
        // Index out of bounds, no change needed
        return prevImages;
      }

      // Create a new array with the element at the specified index moved to the first position
      const updatedImages = [
        prevImages[indexToMakePrimary],
        ...prevImages.slice(0, indexToMakePrimary),
        ...prevImages.slice(indexToMakePrimary + 1),
      ];

      return updatedImages;
    });
  };

  const uploadFileToGCS = async () => {
    console.log(fileToUpload);

    for (const file of fileToUpload) {
      let photoID = nanoid();
      const privateURL = await getUploadURL(productGCPID, photoID);
      console.log(privateURL.signedURL[0]);

      const fileData = new FormData();
      fileData.append("file", file);

      try {
        await axiosInstance.put(privateURL.signedURL[0], file, {
          headers: {
            "Content-Type": "image/jpeg",
          },
        });
        setInfo((info) => {
          const prevImages = info.images || [];
          return {
            ...info,
            images: [...prevImages, privateURL.publicURL],
          };
        });
      } catch (error) {
        console.log(error);
        throw new Error("Error uploading file to GCS:", error);
      } finally {
        console.log("Uploaded");
      }
    }
  };

  // const handleFileUpload = (files) => {
  //   const updatedImages = [...uploadedImages];

  //   for (const file of files) {
  //     const reader = new FileReader();
  //     console.log(updatedImages);

  //     reader.onload = (event) => {
  //       const base64String = event.target.result;
  //       updatedImages.push(base64String);

  //       // Check if all images are processed
  //       if (updatedImages.length === uploadedImages.length + files.length) {
  //         setUploadedImages(updatedImages);
  //         setInfo({ ...info, images: updatedImages });
  //       }
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  const textAreaRef = useRef(null);
  useEffect(() => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  }, [inputDescription]);

  // const handleRemoveImage = (indexToRemove) => {
  //   const updatedImages = uploadedImages.filter(
  //     (_, index) => index !== indexToRemove
  //   );
  //   setUploadedImages(updatedImages);
  //   setInfo({ ...info, images: updatedImages });
  // };
  const validatePhotos = () => {
    if (uploadedImages.length >= 1) {
      setIsPhotosValid(true);
    } else {
      setIsPhotosValid(false);
    }
  };

  const validateCondition = () => {
    if (inputCondition !== null) {
      setIsConditionValid(true);
    } else {
      setIsConditionValid(false);
    }
  };

  const validateDescription = () => {
    if (inputDescription !== "") {
      setIsDescriptionValid(true);
    } else {
      setIsDescriptionValid(false);
    }
  };

  const validateCategory = () => {
    if (info.category !== "") {
      setIsCategoryValid(true);
    } else {
      setIsCategoryValid(false);
    }
  };

  const validateModel = () => {
    if (info.model !== "") {
      setIsModelValid(true);
    } else {
      setIsModelValid(false);
    }
  };

  const validatePrice = () => {
    if (inputPrice !== "") {
      setIsPriceValid(true);
    } else {
      setIsPriceValid(false);
      console.log("price validated", inputPrice);
    }
  };

  const validateTitle = () => {
    if (inputTitle !== "") {
      setIsTitleValid(true);
    } else {
      setIsTitleValid(false);
    }
  };

  // const validateAll = () => {
  //   validatePhotos();
  //   validateCondition();
  //   validateDescription();
  //   validateCategory();
  //   validateModel();
  //   validatePrice();
  //   validateTitle();
  //   validateAddress();
  //   validateCity();
  //   validateState();
  //   validatePincode();
  // };

  // const checkIfAllValid = () => {
  //   if (
  //     isPhotosValid &&
  //     isConditionValid &&
  //     isDescriptionValid &&
  //     isCategoryValid &&
  //     isModelValid &&
  //     isPriceValid &&
  //     isTitleValid &&
  //     inputAddressValid.address &&
  //     inputAddressValid.city &&
  //     inputAddressValid.state &&
  //     inputAddressValid.pincode &&
  //     inputAddressValid.lat &&
  //     inputAddressValid.long &&
  //     submitButtonClicked
  //   ) {
  //     handleListingSubmit();
  //   }
  // };

  const validateAddress = () => {
    if (inputAddress.address !== "") {
      setInputAddressValid((prevState) => ({ ...prevState, address: true }));
    } else {
      setInputAddressValid((prevState) => ({ ...prevState, address: false }));
    }
  };

  const validateCity = () => {
    if (inputAddress.city !== "") {
      setInputAddressValid((prevState) => ({ ...prevState, city: true }));
    } else {
      setInputAddressValid((prevState) => ({ ...prevState, city: false }));
    }
  };

  const validateState = () => {
    if (inputAddress.state !== "") {
      setInputAddressValid((prevState) => ({ ...prevState, state: true }));
    } else {
      setInputAddressValid((prevState) => ({ ...prevState, state: false }));
    }
  };

  const validatePincode = () => {
    if (/^[1-9]{1}[0-9]{2}[0-9]{3}$/.test(inputAddress.pincode)) {
      setInputAddressValid((prevState) => ({ ...prevState, pincode: true }));
    } else {
      setInputAddressValid((prevState) => ({ ...prevState, pincode: false }));
    }
  };
  const validateLatLong = () => {
    if (info?.address?.lat && info?.address?.long) {
      console.log(
        "setting latlong validation true " + info?.address?.lat,
        info?.address?.long
      );
      setInputAddressValid({ ...inputAddressValid, lat: true, long: true });
    } else {
      console.log(
        "setting latlong validation false " + info?.address?.lat,
        info?.address?.long
      );
      setInputAddressValid({ ...inputAddressValid, lat: false, long: false });
    }
  };

  // const validateTotalAddress = () => {
  //   validateAddress();
  //   validateCity();
  //   validateState();
  //   validatePincode();
  // };

  const handleListingSubmit = async () => {
    // if (info?.images?.length < fileToUpload.length) {
    //   uploadFileToGCS();
    // } else {
    //   handleListingSubmit();
    // }
    setPostingStep("three");
    setBackEnabled(false);
    setIsButtonLoading(true);
    console.log(info);

    setTimeout(() => {
      axiosInstance
        .post(`product/add`, info)
        .then((res) => {
          const { data } = res.data;
          toast.success("Item Listing Successfull! Waiting for Admin Review");
          dispatch(getAllProducts());
          setSubmitButtonClicked(false);
        })
        .catch(({ response }) => {
          setPostingStep("two");
          setSubmitButtonClicked(false);
          try {
            switch (response.status) {
              case 400:
                //   add a toast
                toast.error("Invalid Data!");
                break;
              case 401:
                //   add a toast
                toast.error("Invalid credentials!");
                break;
              case 403:
                //   add a toast
                toast.error("First you have to register yourself as a seller!");
                break;
              default:
                // server error
                toast.error("Oops, something went wrong");
                break;
            }
          } catch (e) {
            toast.error("Couldn't reach the server");
            setPostingStep("two");
            setSubmitButtonClicked(false);
          }
        });
      setIsButtonLoading(false);
      dispatch(clearProductDetails());
    }, 100);
  };

  const submitFinalProcess = async () => {
    console.log("inside of submitFinalProcess");
    console.log(fileToUpload.length, info?.images?.length);
    if (
      info?.images === undefined ||
      info?.images?.length < fileToUpload.length
    ) {
      console.log("uploading to gcs");
      await uploadFileToGCS();
      console.log("done uploading");
    }
    // if (!inputAddressValid.lat && !inputAddressValid.long) {
    //   console.log("converting address to lat long");
    //   await convertAddressToLatLng();
    // }
    // if (info?.images?.length === fileToUpload.length) {
    //   console.log("All checks complete, submitting listing now");
    //   await handleListingSubmit();

    //   return true;
    // }
  };

  const handleCheckboxChange = () => {
    setMakeAnOfferEnabled(!makeAnOfferEnabled);
    setInfo({ ...info, make_offer: !makeAnOfferEnabled });
  };

  useEffect(() => {
    // Validate all fields
    console.log({
      submitButtonClicked,
      isPhotosValid,
      isConditionValid,
      isDescriptionValid,
      isCategoryValid,
      isModelValid,
      isPriceValid,
      isTitleValid,
      inputAddressValid,
    });
    console.log("inside of useeffect for checking");
    if (submitButtonClicked) {
      validatePhotos();
      validateCondition();
      validateDescription();
      validateCategory();
      validateModel();
      validatePrice();
      validateTitle();
      validateAddress();
      validateCity();
      validateState();
      validatePincode();
      validateLatLong();
    }

    // Check if all validations are true
    const areAllValid =
      isPhotosValid &&
      isConditionValid &&
      isDescriptionValid &&
      isCategoryValid &&
      isModelValid &&
      isPriceValid &&
      isTitleValid &&
      submitButtonClicked;

    const areAllAddressValid =
      inputAddressValid.address &&
      inputAddressValid.city &&
      inputAddressValid.state &&
      inputAddressValid.pincode &&
      !inputAddressValid.lat &&
      !inputAddressValid.long;

    // If all validations are true, proceed to handleListingSubmit
    //console.log(fileToUpload.length, info.images);

    async function fetchlatlong() {
      await convertAddressToLatLng();
      console.log("fetchlatlong returned");
    }
    async function fetchData() {
      await submitFinalProcess();
    }

    if (submitButtonClicked && areAllValid && areAllAddressValid) {
      fetchlatlong();
    }
    if (
      submitButtonClicked &&
      areAllValid &&
      inputAddressValid.lat &&
      inputAddressValid.long
    ) {
      fetchData();
    }
  }, [
    submitButtonClicked,
    isPhotosValid,
    isConditionValid,
    isDescriptionValid,
    isCategoryValid,
    isModelValid,
    isPriceValid,
    isTitleValid,
    inputAddressValid.lat,
  ]);

  useEffect(() => {
    if (info?.images?.length === fileToUpload.length && submitButtonClicked) {
      handleListingSubmit();
    }

    console.log({ info });
  }, [info]);
  // useEffect;

  // useEffect(() => {
  //   validateLatLong();
  //   if (
  //     inputAddressValid.address &&
  //     inputAddressValid.city &&
  //     inputAddressValid.state &&
  //     inputAddressValid.pincode &&
  //     !inputAddressValid.lat &&
  //     !inputAddressValid.long
  //   ) {
  //     console.log("converting address to lat long");
  //     convertAddressToLatLng();
  //   }
  // }, [inputAddress]);

  // useEffect(() => {
  //   console.log(fileToUpload.length, info);
  //   if (info?.images?.length === fileToUpload.length && submitButtonClicked) {
  //     console.log("inside submit block");
  //     handleListingSubmit();
  //   }
  // }, [info]);

  // useEffect(() => {
  //   setSelectedCondition(
  //     inputCondition
  //       ? options.find((option) => option.value === inputCondition)
  //       : null
  //   );
  //   selectedCondition
  //     ? console.log(selectedCondition.color)
  //     : console.log("no color selected");
  // }, []);

  const handlePriceInputChange = (e) => {
    // Allow only numeric input
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    setInputPrice(numericValue);
    setInfo({ ...info, cost: e.target.value });
  };

  // useEffect(() => {
  //   console.log(item_specifics);
  // }, [item_specifics]);

  // useEffect(() => {
  //   console.log(categories);
  // }, [categories]);

  useEffect(() => {
    setSelectedCondition(
      inputCondition
        ? options.find((option) => option.value === inputCondition)
        : null
    );
    // selectedCondition
    //   ? console.log(selectedCondition.color)
    //   : console.log("no color selected");
    // console.log(
    //   inputCondition ? inputCondition : "Input condition not selected"
    //);
    setInfo({ ...info, condition: inputCondition });
  }, [inputCondition]);

  return (
    <div className="relative bg-whitesmoke-100 w-full overflow-hidden flex flex-col items-center justify-start pt-6 sm:pt-0 mq840:pt-0 px-0 pb-6 box-border gap-[24px] text-left font-general-text">
      <Navbar
        icon1="/icon1.svg"
        vector="/vector.svg"
        ellipse5="/ellipse-5@2x.png"
        signUpFramePadding="16px"
        signUpFrameGap="16px"
        frameDivCursor="pointer"
        vector1="/vector1.svg"
        vector2="/vector2.svg"
        navbarAlignSelf="stretch"
        navbarWidth="unset"
        signedInFrameMarginTop="unset"
        frame1165="/frame-1165.svg"
        communicationContactEMail="/communicationcontactemailsvgrepocom-1.svg"
        offerSvgrepoCom21="/offersvgrepocom2-1.svg"
        orderSvgrepoCom1="/ordersvgrepocom-1.svg"
        rewindButtonSvgrepoCom1="/rewindbuttonsvgrepocom-1.svg"
        saleSvgrepoCom1="/salesvgrepocom-1.svg"
        idWalletSvgrepoCom1="/idwalletsvgrepocom-1.svg"
        showSignedInFrame
      />
      <div className="self-stretch flex flex-row items-start justify-start gap-[24px] my-0 mx-[4%]">
        <div
          className="flex-0.8 w-[40%] flex flex-col items-start justify-start pt-6 px-0 pb-0 gap-[24px] lg:flex-1 md:flex-0.8 sm:flex-1 mq840:flex-1"
          id="post_form"
        >
          <div className="flex flex-row items-end justify-center gap-[8px] text-md sm:items-center sm:justify-center">
            <button
              className={`cursor-pointer [border:none] p-0 bg-[transparent] relative w-[30px] h-[30px] overflow-hidden shrink-0"
                ${backEnabled ? "" : "hidden"}`}
              onClick={() => {
                setHeading(backHeading);
                setPostingStep("one");
              }}
            >
              <img
                className="absolute h-[62.67%] w-[62.67%] top-[18.67%] right-[18.67%] bottom-[18.67%] left-[18.67%] max-w-full overflow-hidden max-h-full"
                alt=""
                src="/back.svg"
              />
            </button>
            <div className="relative tracking-[-0.01em] leading-[130%] font-medium text-title">
              List an item for sale
            </div>
          </div>
          {postingStep === "one" ? (
            <div className="self-stretch overflow-auto pl-1 flex flex-col items-start justify-start gap-[16px]">
              <div className="self-stretch width-[100%] flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div className="flex-1 shrink-0 flex flex-row items-center justify-start">
                    <p
                      className="m-0 relative text-regular leading-[135%] font-medium font-general-text  text-left"
                      id="primary_id_placeholder"
                    >
                      Photos
                      <span
                        className={`px-2 text-mini font-light ${
                          isPhotosValid ? "" : "text-red-500"
                        }`}
                      >
                        Please select atleast 5 photos
                      </span>
                    </p>
                  </div>
                  <div className="bg-tomato shrink-0 hidden flex-row items-center justify-end py-0 px-1">
                    <p
                      className="m-0 relative text-xs leading-[135%] font-general-text text-text text-left"
                      id="primary_id_placeholder"
                    >
                      invalid
                    </p>
                  </div>
                </div>
                <div className="flex flex-row self-stretch items-start justify-start gap-[8px] overflow-scroll py-1 pr-0 pl-1">
                  <label for="file-upload" className="items-center">
                    <div
                      className={`rounded-sm bg-text-white overflow-hidden flex flex-row items-start justify-start py-6 px-9 [transition:0.2s] border-[1px] border-solid hover:relative hover:[transition:0.2s] hover:mt-[-3px] hover:ml-[-3px] hover:mb-[3px] hover:mr-[3px] hover:shadow-[4px_4px] active:[transition:0.2s] active:mt-[-1px] active:ml-[-1px] active:mb-[1px] active:mr-[1px] active:shadow-[2px_2px] ${
                        isPhotosValid ? "border-black" : "border-red-500"
                      }`}
                    >
                      <img
                        className="relative w-[60px] h-[60px] overflow-hidden shrink-0 object-cover"
                        alt=""
                        src="/plus-square.svg"
                      />
                      <input
                        style={{ display: "none" }}
                        id="file-upload"
                        type="file"
                        className="focus:outline-none"
                        onChange={(e) => handleFileUpload(e.target.files)}
                        multiple
                      />
                    </div>
                  </label>
                  {uploadedImages.map((image, index) => (
                    <div
                      id="preview"
                      key={index}
                      className="self-stretch relative h-[108px] flex-col items-start justify-start border-[1px] border-solid border-text"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                      <img
                        id="imgtopreview"
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        className="relative max-h-full w-[132px] object-cover"
                      />
                      <div
                        className="h-[20px] self-stretch w-full cursor-pointer partsRed items-center flex flex-col justify-center absolute transition-all [transition:0.2s] left-[0px] bottom-[0px] z-10 hover:[transition:0.2s] hover:mt-[-3px] hover:ml-[-3px] hover:mb-[3px] hover:mr-[3px] hover:shadow-[4px_4px] active:[transition:0.2s] active:mt-[-1px] active:ml-[-1px] active:mb-[1px] active:mr-[1px] active:shadow-[2px_2px]"
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                        onClick={() => handleRemoveImage(index)}
                        style={{
                          visibility: showRemove[index] ? "visible" : "hidden",
                        }}
                      >
                        <a className="text-black text-xs opacity-100">Remove</a>
                      </div>
                      <div
                        className={`h-[20px] items-center flex flex-col justify-center self-stretch absolute left-[0px] top-[0px] z-10 w-full transition-all [transition:0.2s] ${
                          index === 0
                            ? "bg-white opacity-50"
                            : "cursor-pointer bg-primary hover:[transition:0.2s] hover:mt-[-3px] hover:ml-[-3px] hover:mb-[3px] hover:mr-[3px] hover:shadow-[3px_3px] active:[transition:0.2s] active:mt-[-1px] active:ml-[-1px] active:mb-[1px] active:mr-[1px] active:shadow-[2px_2px]"
                        }`}
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                        onClick={() => handleMakePrimary(index)}
                        style={{
                          visibility:
                            showRemove[index] || index === 0
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        <a className="text-black text-xs opacity-100">
                          {index === 0 ? "Primary" : "Make primary"}
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Dropdown
                valid={isConditionValid}
                title="Condition"
                setInfo={setInputCondition}
                type="end"
                options={options}
                previous={inputCondition}
              />
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-end justify-between">
                  <div className="flex-1 flex flex-row items-center justify-start">
                    <p
                      className="`m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                      id="primary_id_placeholder"
                    >
                      Title
                    </p>
                  </div>
                  <div className="bg-tomato shrink-0 hidden flex-row items-center justify-end py-0 px-1">
                    <p
                      className="m-0 relative text-xs leading-[135%] font-general-text text-text text-left"
                      id="primary_id_placeholder"
                    >
                      invalid
                    </p>
                  </div>
                </div>
                <input
                  className={`bg-text-white text-regular placeholder-gray-place self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                    isTitleValid ? "" : "border-red-500"
                  }`}
                  placeholder="Enter title"
                  type="text"
                  id="signup_name_input"
                  value={inputTitle}
                  onChange={(e) => {
                    setInputTitle(e.target.value);
                    setIsTitleValid(true);
                    setInfo({ ...info, title: e.target.value });
                  }}
                />
              </div>
              <DropdownMultiSelect
                options={currentCategories}
                title="Category"
                setInfo={setInfo}
                info={info}
                itemToEdit="category"
                type="multi"
              />
              {item_specifics && (
                <DropdownMultiSelect
                  options={item_specifics}
                  title="Choose model"
                  setInfo={setInfo}
                  itemToEdit="specification"
                  info={info}
                  type="multi"
                />
              )}

              {/* <div className="mt-1">
                <div ref={wrapperRef} className="relative">
                  <div className="relative w-full mt-1 xl:mt-1.5">
                    <input
                      readOnly
                      type="text"
                      value={value}
                      onChange={(e) => setValue(e.currentTarget.value)}
                      className={`w-full border-[1px] border-black py-1.5 pr-6 px-3 ${
                        dropActive || dropShowing
                          ? "rounded rounded-b-none"
                          : "rounded"
                      }`}
                      placeholder="Type to search"
                      onFocus={() => {
                        setDropShowing(true);
                        setDropActive(true);
                      }}
                      onBlur={() => {
                        if (!dropActive) {
                          setDropShowing(false);
                        }
                      }}
                    />
                    <span
                      className={`transition-all mr-1 duration-300 absolute right-0 top-1/2 -translate-y-1/2 ${
                        dropActive || dropShowing ? "-rotate-90" : "rotate-0"
                      }`}
                    ></span>
                  </div>
                  <div
                    className={`flex-col absolute w-full border-[1px] rounded rounded-t-none border-t-[0px] border-black bg-white ${
                      dropActive ? "flex" : "hidden"
                    }`}
                  >
                    {currentCategories && selectedPath.length ? (
                      <div className="relative py-1.5 mediumFont border-b-[1px] last:border-b-[0px] border-black px-3 flex">
                        <div>
                          <p className="boldFont">
                            {selectedPath[selectedPath.length - 1]}
                          </p>
                          <p className="text-sm">{selectedPath.join(" > ")}</p>
                        </div>
                        <span
                          className={`transition-all mr-1 duration-300 absolute rotate-90 right-0 top-1/2 -translate-y-1/2 ${
                            currentCategories.length ? "block" : "hidden"
                          }`}
                        ></span>
                      </div>
                    ) : null}
                    {currentCategories?.map((e, i) => {
                      return (
                        <div
                          key={`cat${i}`}
                          onClick={() => {
                            handleItemClick(e);
                          }}
                          className="relative cursor-pointer py-1.5 mediumFont border-b-[1px] last:border-b-[0px] border-black px-3 hover:bg-yellow-400"
                        >
                          <p>{e.name}</p>
                          <span
                            className={`transition-all mr-1 duration-300 absolute right-0 top-1/2 -translate-y-1/2 ${
                              e.sub_categories && e.sub_categories.length
                                ? "block"
                                : "hidden"
                            }`}
                          ></span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div> */}
              <div className="self-stretch flex flex-row items-start justify-start gap-[16px] md:flex-col sm:flex-col mq840:flex-col">
                <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
                  <div className=" self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="self-stretch flex flex-row items-end justify-between">
                      <div className="flex-1 self-stretch flex flex-row items-center justify-start">
                        <p
                          className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                          id="primary_id_placeholder"
                        >
                          Price
                        </p>
                      </div>
                      <div className="bg-tomato shrink-0 hidden flex-row items-center justify-end py-0 px-1">
                        <p
                          className="m-0 relative text-xs leading-[135%] font-general-text text-text text-left"
                          id="primary_id_placeholder"
                        >
                          invalid
                        </p>
                      </div>
                    </div>
                    <input
                      className={`bg-text-white placeholder-gray-place text-regular self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                        !isPriceValid ? "border-red-500" : ""
                      }`}
                      placeholder="Enter price"
                      type="text"
                      value={inputPrice}
                      onChange={(e) => {
                        handlePriceInputChange(e);
                        setIsPriceValid(true);
                      }}
                    />
                  </div>
                  <div class="flex items-center">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value=""
                      onClick={() => setOfferButton(!offerButton)}
                      onChange={handleCheckboxChange}
                      styles={{
                        webkitOuterSpinButton: "none",
                        webkitInnerSpinButton: "none",
                        mozAppearnace: "none",
                      }}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-black rounded focus:ring-blue-500 focus:ring-1"
                    />
                    <label
                      for="default-checkbox"
                      class="ms-2 relative text-xs leading-[135%] text-left"
                    >
                      Accept offers
                    </label>
                  </div>
                  {/* <div className="flex flex-row items-start justify-start gap-[8px]">
                    <div className="relative rounded-sm bg-text-white box-border w-4 h-4 overflow-hidden shrink-0 border-[1px] border-solid border-black" />
                    <div className="relative text-xs leading-[135%] text-left">
                      Accept offers
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <SequentialDropdown
                items={item_specifics}
                setInfo={setInfo}
                info={info}
                e
              /> */}
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-end justify-between">
                  <div className="flex-1 shrink-0 flex flex-row items-center justify-start">
                    <p
                      className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-left"
                      id="primary_id_placeholder"
                    >
                      Description
                    </p>
                  </div>
                  <div className="bg-tomato shrink-0 hidden flex-row items-center justify-end py-0 px-1">
                    <p
                      className="m-0 relative text-xs leading-[135%] text-left"
                      id="primary_id_placeholder"
                    >
                      Too short, minimum characters: 12
                    </p>
                  </div>
                </div>
                <textarea
                  className={`bg-text-white leading-6 font-general-text text-regular placeholder-gray-place resize-none text-regular self-stretch py-2.5 rounded-sm box-border break-words [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                    !isDescriptionValid ? "border-red-500" : ""
                  }`}
                  id="name_input"
                  placeholder="Enter description"
                  rows="1"
                  ref={textAreaRef}
                  value={inputDescription}
                  onChange={(event) => {
                    setInputDescription(event.target.value);
                    setInfo({ ...info, description: event.target.value });
                  }}
                />
              </div>

              {/* <Dropdown
                  title="Choose subcategory"
                  setInfo={setInputCondition}
                  type="end"
                  options={item_specifics}
                  previous=""
                /> */}
            </div>
          ) : (
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
              <Dropdown
                title="Select from saved addresses"
                setInfo={setInputFullAddress}
                type="end"
                options={options}
                previous={inputFullAddress}
              />

              <button
                className="cursor-pointer py-3 px-[68px] text-white text-regular bg-black self-stretch rounded-sm flex flex-row items-center justify-center top-[0px] left-[0px] bottom-[0px] [transition:0.2s] border-[1px] border-solid border-gray-700 hover:enabled:shadow-[4px_4px_#FFDC25] hover:enabled:[transition:all_0.2s] hover:enabled:left-[-4px] hover:enabled:top-[-4px] hover:enabled:bottom-[4px] hover:enabled:relative active:[transition:0.2s] active:shadow-[2px_2px_#FFDC25] active:left-[-2px] active:top-[-2px] active:bottom-[2px] active:relative sm:relative sm:top-[-2px] sm:left-[-2px] sm:bottom-[2px] sm:[transition:0.2s] sm:shadow-[4px_4px_#FFDC25] sm:hover:top-[-2px] sm:hover:left-[-2px] sm:active:relative sm:active:top-[-1px] sm:active:left-[-1px] sm:active:bottom-[1px] sm:active:[transition:0.2s] sm:active:shadow-[2px_2px_#FFDC252]"
                onClick={() => {
                  setIsLocationLoading(true);
                  fillCurrentLocation();
                }}
                disabled={isLocationLoading}
              >
                {isLocationLoading ? (
                  // Loading animation (e.g., a spinner)
                  <div className="loader"></div>
                ) : (
                  "Use current location"
                )}
              </button>
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-end justify-between">
                  <div className="flex-1 flex flex-row items-center justify-start">
                    <p
                      className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                      id="primary_id_placeholder"
                    >
                      Address
                    </p>
                  </div>
                  <div className="bg-tomato shrink-0 hidden flex-row items-center justify-end py-0 px-1">
                    <p
                      className="m-0 relative text-xs leading-[135%] font-general-text text-text text-left"
                      id="primary_id_placeholder"
                    >
                      invalid
                    </p>
                  </div>
                </div>
                <input
                  className={`bg-text-white text-regular placeholder-gray-place self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                    inputAddressValid.address ? "" : "border-red-500"
                  }`}
                  placeholder="Enter address"
                  type="text"
                  onChange={(e) => {
                    setInputAddress({
                      ...inputAddress,
                      address: e.target.value,
                    });
                    setInputAddressValid({
                      ...inputAddressValid,
                      address: true,
                    });
                  }}
                  value={inputAddress.address}
                />
              </div>
              <div className="self-stretch flex flex-row w-full items-start justify-start gap-[16px] md:flex-col sm:flex-col mq840:flex-col">
                <div className="w-full self-stretch flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch flex flex-row items-end justify-between">
                    <div className="flex-1 self-stretch flex flex-row items-center justify-start">
                      <p
                        className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                        id="primary_id_placeholder"
                      >
                        City
                      </p>
                    </div>
                    <div className="bg-tomato shrink-0 hidden flex-row items-center justify-end py-0 px-1">
                      <p
                        className="m-0 relative text-xs leading-[135%] font-general-text text-text text-left"
                        id="primary_id_placeholder"
                      >
                        invalid
                      </p>
                    </div>
                  </div>
                  <input
                    className={`bg-text-white placeholder-gray-place text-regular self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                      !inputAddressValid.city ? "border-red-500" : ""
                    }`}
                    placeholder="Enter city"
                    type="text"
                    onChange={(e) => {
                      setInputAddress({
                        ...inputAddress,
                        city: e.target.value,
                      });
                      setInputAddressValid({
                        ...inputAddressValid,
                        city: true,
                      });
                    }}
                    value={inputAddress.city}
                  />
                </div>
                <div className="w-full self-stretch flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch flex flex-row items-end justify-between">
                    <div className="flex-1 self-stretch flex flex-row items-center justify-start">
                      <p
                        className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                        id="primary_id_placeholder"
                      >
                        State
                      </p>
                    </div>
                    <div className="bg-tomato shrink-0 hidden flex-row items-center justify-end py-0 px-1">
                      <p
                        className="m-0 relative text-xs leading-[135%] font-general-text text-text text-left"
                        id="primary_id_placeholder"
                      >
                        invalid
                      </p>
                    </div>
                  </div>
                  <input
                    className={`bg-text-white placeholder-gray-place text-regular self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                      !inputAddressValid.state ? "border-red-500" : ""
                    }`}
                    placeholder="Enter state"
                    type="text"
                    onChange={(e) => {
                      setInputAddress({
                        ...inputAddress,
                        state: e.target.value,
                      });
                      setInputAddressValid({
                        ...inputAddressValid,
                        state: true,
                      });
                    }}
                    value={inputAddress.state}
                  />
                </div>
              </div>

              <div className="w-full self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-end justify-between">
                  <div className="flex-1 self-stretch flex flex-row items-center justify-start">
                    <p
                      className="m-0 relative text-regular leading-[135%] font-medium font-general-text text-text text-left"
                      id="primary_id_placeholder"
                    >
                      Pincode
                    </p>
                  </div>
                  {!inputAddressValid.pincode ? (
                    <p
                      className="m-0 relative text-xs leading-[135%] justify-end font-general-text text-red text-right"
                      id="primary_id_placeholder"
                    >
                      Please enter a valid pincode
                    </p>
                  ) : null}
                </div>
                <input
                  className={`bg-text-white placeholder-gray-place text-regular self-stretch rounded-sm box-border h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px] ${
                    !inputAddressValid.pincode ? "border-red-500" : ""
                  }`}
                  placeholder="Enter pincode"
                  type="text"
                  onChange={(e) => {
                    setInputAddress({
                      ...inputAddress,
                      pincode: e.target.value,
                    });
                    setInputAddressValid({
                      ...inputAddressValid,
                      pincode: true,
                    });
                  }}
                  value={inputAddress.pincode}
                />
              </div>
            </div>
          )}
          <div className="self-stretch flex flex-row items-end justify-end pl-[4px]">
            {/* <button className="cursor-pointer rounded-sm bg-new-black shrink-0 flex flex-row items-center justify-center py-2.5 px-6 border-[1px] border-solid border-black">
              <a className="relative text-regular leading-[140%] font-general-text text-text-white text-left">
                Save draft
              </a>
            </button> */}
            <button
              className="cursor-pointer rounded-sm bg-primary shrink-0 flex flex-row items-center justify-center py-2.5 px-[24px] border-[1px] border-solid border-black"
              onClick={() => {
                if (postingStep === "one") {
                  setBackHeading("List an item for sale - General");
                  setPostingStep("two");
                  setBackEnabled(true);
                  // if (fileToUpload.length > 0) {
                  //   uploadFileToGCS();
                  // }
                } else {
                  setSubmitButtonClicked(true);
                }
              }}
            >
              {isButtonLoading ? (
                <div className="loader"></div>
              ) : (
                <a className="relative text-regular leading-[140%] font-general-text text-black text-left">
                  {postingStep === "two" ? "Publish" : "Next"}
                </a>
              )}
            </button>
          </div>
        </div>
        <div
          className="sticky flex-1 bg-text-white box-border overflow-hidden flex flex-col items-start justify-start py-6 px-4 gap-[24px] border-[1px] border-solid border-black sm:hidden mq840:hidden"
          id="preview"
        >
          <div className="relative leading-[130%] font-medium text-title">
            Preview
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-regular">
            <div className="self-stretch bg-text-white flex flex-row items-center justify-start gap-[10px] grow-[1]">
              <div className="self-stretch flex-1 relative bg-gainsboro grow-[1] border-[1px] border-solid border-black">
                <img
                  className="absolute top-[0px] left-[0px] w-full h-full object-cover"
                  alt=""
                  src={uploadedImages[0] ? uploadedImages[0] : "/blank.svg"}
                />
              </div>
              <div className="shrink-0 flex flex-col items-start justify-start gap-[8px]">
                <img
                  className="relative w-[150px] h-[100px] object-cover border-[1px] border-solid lg:w-[100px] lg:h-[70px] lg:w-[100px] md:h-[70px] border-black"
                  alt=""
                  src={uploadedImages[1] ? uploadedImages[1] : "/blank.svg"}
                />
                <img
                  className="relative w-[150px] h-[100px] object-cover border-[1px] border-solid lg:w-[100px] lg:h-[70px] lg:w-[100px] md:h-[70px] border-black"
                  alt=""
                  src={uploadedImages[2] ? uploadedImages[2] : "/blank.svg"}
                />
              </div>
              <div className="shrink-0 flex flex-col items-start justify-start gap-[8px]">
                <img
                  className="relative w-[150px] h-[100px] object-cover border-[1px] border-solid lg:w-[100px] lg:h-[70px] lg:w-[100px] md:h-[70px] border-black"
                  alt=""
                  src={uploadedImages[3] ? uploadedImages[3] : "/blank.svg"}
                />
                <img
                  className="relative w-[150px] h-[100px] object-cover border-[1px] border-solid lg:w-[100px] lg:h-[70px] lg:w-[100px] md:h-[70px] border-black"
                  alt=""
                  src={uploadedImages[4] ? uploadedImages[4] : "/blank.svg"}
                />
              </div>
            </div>
            <div className="self-stretch flex flex-row text-xs items-start justify-center">
              <div className="box-border flex-[1] flex flex-col items-center overflow-hidden justify-start border-[1px] border-solid border-black">
                <div className="self-stretch bg-text-white flex flex-row pr-[12px] items-center justify-start gap-[12px]">
                  <div
                    className={`self-stretch overflow-hidden flex flex-row items-center justify-center py-[12px] px-3 gap-[10px] border-r-[1px] border-solid border-black ${
                      selectedCondition ? selectedCondition.color : ""
                    }`}
                    // style={{
                    //   backgroundColor: selectedCondition
                    //     ? `${selectedCondition.color}`
                    //     : "black",
                    // }}
                  >
                    <div className="relative text-mini leading-[130%]">
                      {inputCondition ? (
                        inputCondition
                      ) : (
                        <div className="w-[20px] rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                      )}
                    </div>
                    <div className="self-stretch rounded-4xs bg-gainsboro w-[60px] overflow-hidden hidden" />
                  </div>
                  <div className="flex-1 flex flex-col items-start break-words overflow-hidden justify-start py-2.5 pr-12px">
                    <p className="relative w-full items-start break-words text-regular leading-[130%]">
                      {inputTitle}
                    </p>
                    {inputTitle === "" && (
                      <div className="self-stretch rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                    )}
                  </div>
                </div>
                <div className="self-stretch bg-text-white overflow-hidden flex flex-col items-start justify-start py-2.5 px-3 gap-[4px] border-t-[1px] border-solid border-black">
                  <div className="relative leading-[130%]">Description</div>
                  <div className="self-stretch w-full relative break-words text-mini whitespace-pre-wrap leading-[140%] text-black">
                    {inputDescription}
                  </div>
                  {inputDescription === "" && (
                    <div className="self-stretch flex flex-col gap-[4px] ">
                      <div className="self-stretch rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                      <div className="self-stretch rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                      <div className="self-stretch w-[80%] rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                    </div>
                  )}
                </div>
                <div className="self-stretch bg-text-white overflow-hidden flex flex-col items-start justify-start py-2.5 px-3 gap-[4px] border-t-[1px] border-solid border-black">
                  <div className="relative leading-[130%]">Item specifics</div>
                  <div className="self-stretch w-full relative break-words text-mini leading-[140%] text-black">
                    <div className="self-stretch relative text-xs leading-[150%] text-black hidden">
                      <p className="m-0">Manufacturer: Volkswagen</p>
                      <p className="m-0">Model: Vento</p>
                      <p className="m-0">Year: 2012</p>
                      <p className="m-0">Trim: Highline</p>
                      <p className="m-0">Fuel: Diesel</p>
                      <p className="m-0">Transmission: Automatic</p>
                      <p className="m-0">Service Records: Available</p>
                    </div>
                  </div>
                  {inputSubcategory === "" && (
                    <div className="self-stretch flex flex-col gap-[4px] ">
                      <div className="self-stretch rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                      <div className="self-stretch rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                      <div className="self-stretch rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                      <div className="self-stretch rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                      <div className="self-stretch w-[80%] rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                    </div>
                  )}
                </div>
                <div className="self-stretch bg-text-white overflow-hidden flex flex-col items-start justify-start p-3 gap-[8px] border-t-[1px] border-solid border-black">
                  <div className="relative leading-[130%]">Buyers Guide</div>
                  <div className="self-stretch relative text-xs leading-[140%] text-black hidden">
                    <p className="m-0">
                      Installation of these headlights is relatively easy. You
                      require two T12 screwdrivers and a trim removal tool.
                      Follow these steps
                    </p>
                    <p className="m-0">Open the bonnet and find 2 T12 screws</p>
                  </div>
                </div>
              </div>
              <div className="flex-0.6 bg-background box-border flex flex-col items-center justify-start min-w-[30%] ml-[-1px] text-regular border-[1px] border-solid border-black">
                <div className="self-stretch bg-text-white flex flex-col items-end justify-center p-3 gap-[8px] text-right">
                  <div className="flex flex-col self-stretch items-end justify-center gap-[6px]">
                    <div className="relative leading-[130%]">
                      &#8377;{numberWithIndianCommas(Number(inputPrice))}
                    </div>
                    {inputLocation ? (
                      <div className="flex-col items-end justify-center gap-[4px] text-left text-mini">
                        <div className="relative text-right ">
                          Free delivery
                        </div>
                        <div className="relative">
                          <span>{`Arrives by `}</span>
                          <b>Nov 29</b>
                        </div>
                      </div>
                    ) : (
                      <div className="w-[50%] flex flex-col items-end justify-end gap-[4px] text-xs">
                        <div className="self-stretch rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                        <div className=" w-[80%] rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                      </div>
                    )}
                  </div>
                  <div className="self-stretch flex flex-col items-end justify-center gap-[4px] text-center text-xs">
                    <div className="self-stretch rounded-sm text-mini bg-primary flex flex-row items-center justify-start py-1 px-3 border-[1px] border-solid border-black">
                      <div className="flex-1 relative leading-[140%]">
                        Add to cart
                      </div>
                    </div>
                    <div
                      className={`self-stretch rounded-sm text-mini flex flex-row items-center justify-start py-1 px-3 border-[1px] border-solid border-black ${
                        !makeAnOfferEnabled ? "bg-gray-100" : "bg-white"
                      }`}
                    >
                      <div className="relative flex-1 leading-[140%]">
                        Make an Offer
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch bg-white flex flex-row items-center justify-between p-3 text-xs border-t-[1px] border-solid border-black">
                  <div className="relative [text-decoration:underline] max-w-[calc(100%-80px)] whitespace-nowrap overflow-hidden text-ellipsis leading-[130%]">
                    {user ? user.first_name : ""}
                  </div>
                  <div className=" flex flex-row items-center justify-start gap-[2px] text-center">
                    <img
                      className="relative w-3 h-3 overflow-hidden shrink-0 object-cover"
                      alt=""
                      src="/star-1.svg"
                    />
                    <img
                      className="relative w-3 h-3 overflow-hidden shrink-0 object-cover"
                      alt=""
                      src="/star-1.svg"
                    />
                    <img
                      className="relative w-3 h-3 overflow-hidden shrink-0 object-cover"
                      alt=""
                      src="/star-1.svg"
                    />
                    <img
                      className="relative w-3 h-3 overflow-hidden shrink-0 object-cover"
                      alt=""
                      src="/star-1.svg"
                    />
                    <img
                      className="relative w-3 h-3 overflow-hidden shrink-0 object-cover"
                      alt=""
                      src="/star-1.svg"
                    />
                    <div className="relative leading-[135%] text-xs">4.5</div>
                  </div>
                </div>
                <div className="self-stretch bg-text-white flex flex-col items-start justify-center p-3 gap-[8px] text-regular border-t-[1px] border-solid border-black">
                  <div className="relative leading-[130%] text-xs">
                    Location
                  </div>
                  {inputLocation !== "" ? (
                    <div className="self-stretch flex flex-col items-center justify-center gap-[8px] text-xs">
                      <iframe
                        class="rounded-sm border-[1px] border-black"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14006.98438512234!2d77.18340760000001!3d28.6373709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d02bcbb3b6d6b%3A0xaac7850da2a43dd9!2sRajinder%20Nagar%2C%20New%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1684321877094!5m2!1sen!2sin"
                        width="100%"
                        height="100px"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        tabindex="-1"
                      ></iframe>
                      <div className="relative w-full flex justify-center leading-[130%] text-mini">
                        Rajendra Nagar, New Delhi
                      </div>
                    </div>
                  ) : (
                    <div className="self-stretch flex flex-col items-center justify-center gap-[8px] text-xs">
                      <div className="self-stretch rounded-4xs bg-optionHover h-[100px] overflow-hidden" />
                      <div className=" w-[80%] rounded-4xs bg-optionHover h-[9px] overflow-hidden" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostAnItem;
