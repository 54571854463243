import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import axiosInstance from "../axiosInstance";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Product from "../components/Product";
import {
  getAllItemsBySeller,
  getAllOffersForSeller,
  getOfferDetailSeller,
} from "../redux/actions/product";
import OfferCountered from "../components/Modals/OfferCountered";
import OfferSent from "../components/Modals/OfferSent";

const Account = (subMenu) => {
  const [nameError, showNameError] = useState("");
  const [nameErrorOrMessage, setNameErrorOrMessage] = useState(true);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [isSellingExpanded, setIsSellingExpanded] = useState(
    /^selling/.test(subMenu.subMenu)
  );

  const [isBuyingExpanded, setIsBuyingExpanded] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [nameInput, setNameInput] = useState("");
  const [editNameProcessing, setNameProcessing] = useState(false);
  const [editInputDisabled, setEditInputDisabled] = useState(true);
  const [editNameDone, setEditNameDone] = useState(false);
  const [accountParams, setAccountParams] = useSearchParams();
  const [isActive, setIsActive] = useState(false);
  const [allOfferItems, setAllOfferItems] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [viewOfferClicked, setViewOfferClicked] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState();
  const [openOfferType, setOpenOfferType] = useState("");
  const [allSelleritems, setAllSellerItems] = useState();

  const [nameData, setNameData] = useState({
    first_name: "",
    last_name: "",
  });

  function nameEditButtonfunction() {
    if (editNameDone) {
      //for save button
      setEditInputDisabled(true);
    } else {
      //for edit button
      setEditInputDisabled(false);
      editInputDisabled && document.getElementById("name_edit_input").focus();
    }
  }

  const updateName = (e) => {
    setNameProcessing(true);
    console.log(nameInput);
    const isValid = /^[a-zA-Z]{2,}(?:\s[a-zA-Z]{2,})+$/.test(nameInput);
    if (!isValid) {
      setNameProcessing(false);
      setNameErrorOrMessage(true);
      showNameError("Please enter full name");
    } else {
      showNameError("");
      const nameArray = nameInput.split(" ");
      // let tempData = { ...nameData };
      console.log(nameArray[0]);
      console.log(nameArray[nameArray.length - 1]);
      setNameData({
        first_name: nameArray[0],
        last_name: nameArray[nameArray.length - 1],
      });
      // tempData.first_name = nameArray[0];
      // tempData.last_name = nameArray[nameArray.length - 1];
      // setNameData(tempData);
    }
  };

  useEffect(() => {
    if (user) {
      setNameInput(user?.first_name + " " + user?.last_name);
      setIsLoading(false);
    }
    //console.log({ user });
  }, [user]);

  useEffect(() => {
    if (nameData.first_name) {
      axiosInstance
        .post("/auth/updateMe", nameData)
        .then((response) => {
          setNameProcessing(false);
          setEditInputDisabled(true);
          // Check the response status
          if (response.status === 200) {
            // Handle success
            const responseData = response.data;
            setEditNameDone(true);
            setNameErrorOrMessage(false);
            showNameError("Changes saved succesfully");
            console.log(nameInput);
          } else {
            // Handle unexpected status code
            setEditNameDone(false);

            setNameErrorOrMessage(true);
            showNameError("Unexpected response status: " + response.status);
          }
        })
        .catch((error) => {
          // Handle error
          setNameProcessing(false);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const errorMessage =
              error.response.data.error || "An error occurred";
            setEditNameDone(false);
            setNameErrorOrMessage(true);
            showNameError(errorMessage);
          } else if (error.request) {
            // The request was made but no response was received
            setEditNameDone(false);
            setNameErrorOrMessage(true);
            showNameError("No response received from the server");
          } else {
            // Something happened in setting up the request
            setEditNameDone(false);
            setNameErrorOrMessage(true);
            showNameError("Error setting up the request: " + error.message);
          }
        });
    }
  }, [nameData]);

  //Selling>listings
  //Fetch all items listed by this seller

  useEffect(() => {
    if (subMenu.subMenu === "selling.listings") {
      const fetchData = async () => {
        const sellerItems = await getAllItemsBySeller();
        //console.log(sellerItems.data);
        //console.log(offerData);
        setAllSellerItems(sellerItems?.data);
      };
      fetchData();
    }
  }, []);

  //Selling>offers
  //Fetch all items listed by this seller that have an offer history
  //Fetch all offerhistoryarrays associated with that product

  useEffect(() => {
    if (subMenu.subMenu === "selling.offers") {
      const fetchData = async () => {
        const userOffersData = await getAllOffersForSeller();
        console.log(userOffersData);
        //console.log(offerData);
        setAllOfferItems(userOffersData);
      };
      fetchData();
    }
  }, []);

  async function handleViewOffer(offersInfo) {
    setViewOfferClicked(true);
    setOpenOfferType(offersInfo?.status);
    console.log(offersInfo);
    setSelectedOffer(offersInfo);
    setSearchParams({
      offer: offersInfo.arrayOfferHistoryId,
    });
    setViewOfferClicked(false);
    //const offerData = await getOfferDetailSeller(arrayOfferHistoryId);
  }

  //selling>Listings
  //phone

  return (
    <div className="relative bg-whitesmoke-100 w-full overflow-hidden flex flex-col items-start justify-start pt-6 px-0 pb-0 box-border gap-[24px] text-left text-regular text-black font-general-text sm:pt-0 mq840:pt-0 sm:box-border">
      <Navbar />
      <div className="self-stretch flex flex-row items-start justify-start gap-[36px] my-0 mx-[4%] min-h-[100vh] lg:gap-[24px]">
        <div className="flex-1 bg-text-white overflow-hidden flex flex-col items-start justify-start min-w-[250px] max-w-[250px] text-regular lg:min-w-[180px] lg:max-w-[180px] md:hidden">
          <div className="self-stretch flex flex-col items-start justify-start py-4 px-3 gap-[4px] text-title border-[1px] border-solid border-black">
            <div className="flex flex-row items-start justify-start gap-[4px]">
              <div className="relative">Hi,</div>
              <div className="relative">{user ? user?.first_name : "user"}</div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[4px] text-regular">
              <div className="relative">Member since</div>
              <div className="relative">year</div>
            </div>
          </div>
          <button
            className="cursor-pointer py-2 px-2.5 bg-[transparent] self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px] [transition:0.2s] [border-bottom:1px] mt-[-1px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
            id="menud_orders_button"
            href="/account/summary"
          >
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/ordersvgrepocom-11@2x.png"
            />
            <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
              Summary
            </div>
          </button>
          <div className="self-stretch overflow-hidden flex flex-col items-end justify-center mt-[-1px] border-[1px] border-solid border-black">
            <div
              className="self-stretch flex flex-row items-center justify-between py-2 px-3 [transition:0.2s] hover:bg-optionHover hover:[transition:0.2s]"
              onClick={() => {
                setIsBuyingExpanded(!isBuyingExpanded);
              }}
            >
              <div className="shrink-0 flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/ordersvgrepocom-1.svg"
                />
                <div
                  className="relative leading-[130%] hover:[text-decoration:underline] cursor-pointer"
                  onClick={() => {
                    navigate("/account/orders");
                  }}
                >
                  Buying
                </div>
              </div>
              <img
                className="relative w-5 h-5 overflow-hidden shrink-0 object-cover"
                alt=""
                src="/chevron-down.svg"
              />
            </div>
            <div
              className={`self-stretch flex-col items-end justify-center pt-0 px-0 pb-2 opacity-[1] text-regular ${
                isBuyingExpanded ? "flex" : "hidden"
              }`}
            >
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <span className="relative leading-[130%] hover:[text-decoration:underline] cursor-pointer">
                  Orders
                </span>
              </div>
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <div className="relative leading-[130%] hover:[text-decoration:underline] cursor-pointer">
                  Offers
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <div className="relative leading-[130%] hover:[text-decoration:underline] cursor-pointer">
                  Recently viewed
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <div className="relative leading-[130%] hover:[text-decoration:underline] cursor-pointer">
                  Watchlist
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch overflow-hidden flex flex-col items-end justify-center mt-[-1px] border-[1px] border-solid border-black">
            <div
              className="self-stretch flex flex-row items-center justify-between py-2 px-3 [transition:0.2s] hover:bg-optionHover hover:[transition:0.2s]"
              onClick={() => {
                setIsSellingExpanded(!isSellingExpanded);
              }}
            >
              <div className="shrink-0 flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/ordersvgrepocom-1.svg"
                />
                <div className="relative leading-[130%]">Selling</div>
              </div>
              <img
                className="relative w-5 h-5 overflow-hidden shrink-0 object-cover"
                alt=""
                src="/chevron-down.svg"
              />
            </div>
            <div
              className={`self-stretch flex-col items-end justify-center pt-0 px-0 pb-2 opacity-[1] text-regular ${
                isSellingExpanded ? "flex" : "hidden"
              }`}
            >
              <a
                className="self-stretch flex flex-row items-center justify-end hover:[text-decoration:underline] cursor-pointer py-1 px-3"
                href="/account/selling/listings"
              >
                <div className="relative leading-[130%]">Listings</div>
              </a>
              <a
                className={`self-stretch flex flex-row items-center justify-end py-1 px-3 hover:[text-decoration:underline] cursor-pointer `}
                href="/account/selling/offers"
              >
                <div className="relative leading-[130%]">Offers</div>
              </a>
            </div>
          </div>
          <button
            className="cursor-pointer py-2 px-2.5 bg-[transparent] self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px] [transition:0.2s] mt-[-1px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
            id="menud_orders_button"
          >
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/recentlyViewed.svg"
            />
            <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
              Recently Viewed
            </div>
          </button>
          <button
            className="cursor-pointer py-2 px-2.5 bg-[transparent] self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px] [transition:0.2s] mt-[-1px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
            id="menud_orders_button"
            onClick={() => {
              navigate("/account/info");
            }}
          >
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/idwalletsvgrepocom-1.svg"
            />
            <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
              Account
            </div>
          </button>
        </div>
        {/* Expand animation */}
        {/* <div>
          <button
            type="button"
            className="bg-gray-200 px-4 py-2 text-gray-600 rounded-t-md"
            onClick={() => setIsActive(!isActive)}
          >
            Open Collapsible
          </button>
          <div
            className={`${
              isActive ? "max-h-[300px]" : "max-h-0"
            } overflow-hidden transition-all duration-1000 ease-in-out`}
          >
            <p className="text-gray-600 px-4 py-2">Lorem ipsum...</p>
          </div>
        </div> */}
        {subMenu.subMenu === "orders" ? (
          <div className="flex-1 flex-col items-start justify-start pt-4 px-0 pb-0 gap-[24px]">
            <div className="self-stretch flex flex-row items-center justify-between">
              <b className="relative leading-[130%]">Orders</b>
              <div className="bg-text-white box-border w-[131px] overflow-hidden shrink-0 flex flex-row items-end justify-between py-2 px-3 text-regular border-[1px] border-solid border-black">
                <div className="shrink-0 flex flex-row items-start justify-start gap-[4px]">
                  <div className="relative leading-[130%]">Filter:</div>
                  <div className="relative leading-[130%]">All</div>
                </div>
                <img
                  className="relative w-5 h-5 overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/chevrondown-11@2x.png"
                />
              </div>
            </div>
            <div className="self-stretch h-24 overflow-hidden shrink-0 hidden flex-row items-start justify-center py-12 px-5 box-border text-lg">
              <div className="relative leading-[130%]">Nothing to see here</div>
            </div>
            <div className="self-stretch bg-text-white overflow-hidden flex flex-col items-start justify-start text-xs">
              <div className="self-stretch bg-text-white overflow-hidden flex flex-row items-start justify-between p-3 text-regular border-[1px] border-solid border-black sm:items-start sm:justify-between sm:gap-[0px] mq840:items-center mq840:justify-center">
                <div className="shrink-0 flex flex-row items-start justify-start gap-[24px] sm:items-center sm:justify-start">
                  <div className="shrink-0 flex flex-row items-start justify-start gap-[4px]">
                    <div className="relative leading-[135%] sm:hidden">
                      Order no:
                    </div>
                    <div className="relative [text-decoration:underline] leading-[135%]">
                      A107899200
                    </div>
                  </div>
                  <div className="shrink-0 flex flex-row items-start justify-start gap-[4px] sm:hidden mq840:hidden">
                    <div className="relative leading-[135%]">
                      Purchase date:
                    </div>
                    <div className="relative leading-[135%] inline-block w-[101px] shrink-0">
                      March 11 2023
                    </div>
                  </div>
                </div>
                <div className="shrink-0 flex flex-row items-center justify-start gap-[4px] sm:flex mq840:hidden">
                  <div className="relative leading-[135%]">Total:</div>
                  <div className="relative leading-[130%] font-medium">
                    ₹5,000
                  </div>
                </div>
              </div>
              <div className="self-stretch overflow-hidden flex flex-row items-start justify-start p-3 relative mt-[-1px] border-[1px] border-solid border-black sm:p-2 sm:box-border">
                <div className="shrink-0 flex flex-col items-center justify-start absolute gap-[10px] z-[2] top-[16px] left-[4px]">
                  <img
                    className="relative w-[60px] h-[26px] object-cover z-[0]"
                    alt=""
                    src="/vector4@2x.png"
                  />
                  <div className="self-stretch absolute my-0 mx-[!important] top-[calc(50%_-_7px)] left-[0px] leading-[120%] font-medium pl-2.5 pt-0 pr-0 pb-0 z-[1]">
                    USED
                  </div>
                </div>
                <div className="box-border w-[180px] h-[150px] overflow-hidden shrink-0 flex flex-col items-start justify-between z-[1] border-[1px] border-solid border-black sm:w-2/5 sm:h-[110px] sm:aspect-[1.1] sm:max-w-[120]">
                  <img
                    className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-contain"
                    alt=""
                    src="/img-3218@2x.png"
                  />
                </div>
                <div className="self-stretch flex-1 flex flex-col items-start justify-between py-0 px-3 z-[2] text-regular">
                  <div className="w-full flex flex-col items-start justify-center gap-[4px] whitespace-nowrap overflow-hidden text-ellipsis">
                    <p
                      className="m-0 relative leading-[130%] hidden whitespace-nowrap overflow-hidden text-ellipsis"
                      id="item_name"
                    >
                      Polo vento latest headlight type 2
                    </p>
                    <div className="hidden flex-col items-start justify-end sm:flex-col sm:gap-[0px] sm:items-start sm:justify-center">
                      <div className="relative leading-[130%] font-medium">
                        ₹2,500
                      </div>
                      <div className="relative text-xs leading-[130%] text-dimgray-100">
                        Free shipping
                      </div>
                    </div>
                    <p className="m-0 relative leading-[130%] hidden whitespace-nowrap overflow-hidden text-ellipsis">
                      Polo vento latest headlight type 2
                    </p>
                    <p className="m-0 relative leading-[130%] whitespace-nowrap overflow-hidden text-ellipsis sm:w-[120px] sm:whitespace-nowrap sm:overflow-hidden sm:text-ellipsis">
                      Polo vento latest headlight type 2
                    </p>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-center gap-[4px]">
                    <b className="relative leading-[130%]">Processing</b>
                    <div className="flex flex-row items-center justify-start gap-[4px]">
                      <div className="relative leading-[130%]">Number</div>
                      <div className="relative [text-decoration:underline] leading-[130%]">
                        Track
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shrink-0 flex flex-col items-end justify-end gap-[8px] z-[3] sm:flex mq840:flex">
                  <div className="relative w-9 h-9 hidden sm:flex mq840:flex">
                    <div className="absolute top-[46px] left-[-101px] bg-text-white overflow-hidden flex flex-col items-start justify-start sm:hidden mq840:hidden">
                      <button
                        className="cursor-pointer py-3 px-2.5 bg-[transparent] self-stretch overflow-hidden flex flex-row items-center justify-center [transition:0.2s] [border-bottom:1px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
                        id="menud_orders_button"
                      >
                        <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                          Leave feedback
                        </div>
                      </button>
                      <button
                        className="cursor-pointer py-3 px-2.5 bg-[transparent] self-stretch overflow-hidden flex flex-row items-center justify-center [transition:0.2s] mt-[-1px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
                        id="menud_orders_button"
                      >
                        <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                          Cancel order
                        </div>
                      </button>
                      <button
                        className="cursor-pointer py-3 px-2.5 bg-black self-stretch overflow-hidden flex flex-row items-center justify-center [transition:0.2s] mt-[-1px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
                        id="menud_orders_button"
                      >
                        <div className="relative text-regular leading-[130%] font-general-text text-text-white text-left">
                          Report a problem
                        </div>
                      </button>
                    </div>
                    <img
                      className="absolute top-[0px] left-[0px] w-9 h-9 overflow-hidden sm:flex mq840:flex"
                      alt=""
                      src="/morehorizontal-1.svg"
                    />
                  </div>
                  <div className="shrink-0 flex flex-col items-end justify-start gap-[8px] sm:hidden mq840:hidden">
                    <button className="cursor-pointer p-3 bg-text-white self-stretch rounded-sm shrink-0 flex flex-row items-center justify-center [transition:0.2s] top-[0px] left-[0px] right-[0px] border-[1px] border-solid border-gray-700 hover:relative hover:shadow-[4px_4px_#FFDC25] hover:[transition:0.2s] hover:top-[-4px] hover:left-[-4px] hover:bottom-[4px] active:[transition:0.2s] active:shadow-[2px_2px_#FFDC25] active:top-[-2px] active:left-[-2px] active:bottom-[2px]">
                      <div className="relative text-regular font-general-text text-black text-left">
                        Leave feedback
                      </div>
                    </button>
                    <button className="cursor-pointer p-3 bg-text-white self-stretch rounded-sm shrink-0 flex flex-row items-center justify-center [transition:0.2s] top-[0px] left-[0px] right-[0px] border-[1px] border-solid border-gray-700 hover:relative hover:shadow-[4px_4px_#FFDC25] hover:[transition:0.2s] hover:top-[-4px] hover:left-[-4px] hover:bottom-[4px] active:[transition:0.2s] active:shadow-[2px_2px_#FFDC25] active:top-[-2px] active:left-[-2px] active:bottom-[2px]">
                      <div className="relative text-regular font-general-text text-black text-left">
                        Cancel order
                      </div>
                    </button>
                    <button className="cursor-pointer p-3 bg-black self-stretch rounded-sm shrink-0 flex flex-row items-center justify-center [transition:0.2s] top-[0px] left-[0px] right-[0px] border-[1px] border-solid border-gray-700 hover:relative hover:shadow-[4px_4px_#FFDC25] hover:[transition:0.2s] hover:top-[-4px] hover:left-[-4px] hover:bottom-[4px] active:[transition:0.2s] active:shadow-[2px_2px_#FFDC25] active:top-[-2px] active:left-[-2px] active:bottom-[2px]">
                      <div className="relative text-regular font-general-text text-text-white text-left">
                        Report a problem
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="self-stretch overflow-hidden hidden flex-row items-start justify-start p-3 relative border-b-[1px] border-solid border-black">
                <div className="shrink-0 flex flex-col items-center justify-start absolute gap-[10px] z-[2] top-[16px] left-[4px]">
                  <img
                    className="relative w-[60px] h-[26px] object-cover z-[0]"
                    alt=""
                    src="/vector4@2x.png"
                  />
                  <div className="self-stretch absolute my-0 mx-[!important] top-[calc(50%_-_7px)] left-[0px] leading-[120%] font-medium pl-2.5 pt-0 pr-0 pb-0 z-[1]">
                    USED
                  </div>
                </div>
                <div className="box-border w-[180px] h-[150px] overflow-hidden shrink-0 flex flex-col items-start justify-between z-[1] border-[1px] border-solid border-black">
                  <img
                    className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-contain"
                    alt=""
                    src="/img-32181@2x.png"
                  />
                </div>
                <div className="self-stretch flex-1 flex flex-col items-start justify-between py-0 px-3 z-[2] text-regular">
                  <div className="self-stretch flex flex-col items-start justify-center gap-[4px]">
                    <div className="self-stretch relative leading-[130%]">
                      Polo vento latest headlight type 2
                    </div>
                    <div className="flex flex-row items-end justify-start gap-[4px]">
                      <div className="relative leading-[130%] font-medium">
                        ₹2,500
                      </div>
                      <div className="relative text-xs leading-[130%] text-dimgray-100">
                        Free shipping
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-center gap-[4px]">
                    <b className="relative leading-[130%]">Shipped</b>
                    <div className="flex flex-row items-center justify-start gap-[4px]">
                      <div className="relative leading-[130%]">
                        Tracking number:
                      </div>
                      <div className="relative leading-[130%]">Number</div>
                      <div className="relative [text-decoration:underline] leading-[130%]">
                        Track
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch shrink-0 flex flex-col items-start justify-between z-[3]">
                  <button className="cursor-pointer p-3 bg-text-white self-stretch rounded-sm shrink-0 flex flex-row items-center justify-center [transition:0.2s] top-[0px] left-[0px] right-[0px] border-[1px] border-solid border-gray-700 hover:relative hover:shadow-[4px_4px_#FFDC25] hover:[transition:0.2s] hover:top-[-4px] hover:left-[-4px] hover:bottom-[4px] active:[transition:0.2s] active:shadow-[2px_2px_#FFDC25] active:top-[-2px] active:left-[-2px] active:bottom-[2px]">
                    <div className="relative text-regular font-general-text text-black text-left">
                      Leave feedback
                    </div>
                  </button>
                  <button className="cursor-pointer p-3 bg-text-white self-stretch rounded-sm shrink-0 flex flex-row items-center justify-center [transition:0.2s] top-[0px] left-[0px] right-[0px] border-[1px] border-solid border-gray-700 hover:relative hover:shadow-[4px_4px_#FFDC25] hover:[transition:0.2s] hover:top-[-4px] hover:left-[-4px] hover:bottom-[4px] active:[transition:0.2s] active:shadow-[2px_2px_#FFDC25] active:top-[-2px] active:left-[-2px] active:bottom-[2px]">
                    <div className="relative text-regular font-general-text text-black text-left">
                      Cancel order
                    </div>
                  </button>
                  <button className="cursor-pointer p-3 bg-black self-stretch rounded-sm shrink-0 flex flex-row items-center justify-center [transition:0.2s] top-[0px] left-[0px] right-[0px] border-[1px] border-solid border-gray-700 hover:relative hover:shadow-[4px_4px_#FFDC25] hover:[transition:0.2s] hover:top-[-4px] hover:left-[-4px] hover:bottom-[4px] active:[transition:0.2s] active:shadow-[2px_2px_#FFDC25] active:top-[-2px] active:left-[-2px] active:bottom-[2px]">
                    <div className="relative text-regular font-general-text text-text-white text-left">
                      Report a problem
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : subMenu.subMenu === "summary" ? (
          <div className="flex-1 flex flex-col items-start justify-start pt-4 px-0 pb-0 gap-[24px]">
            <div className="self-stretch flex flex-row items-center justify-start text-title">
              <b className="relative leading-[130%]">Summary</b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-md gap-[16px] sm:flex-col">
              <div className="flex-1 bg-text-white overflow-hidden flex flex-col items-start justify-center p-4 gap-[18px] border-[1px] border-solid border-black sm:flex-[unset] sm:self-stretch">
                <div className="relative leading-[130%]">Items for sale</div>
                <div className="relative t leading-[130%]">3</div>
              </div>
              <div className="flex-1 bg-text-white overflow-hidden flex flex-col items-start justify-center p-4 gap-[18px] border-[1px] border-solid border-black sm:flex-[unset] sm:self-stretch">
                <div className="relative leading-[130%]">Offers sent</div>
                <div className="relative t leading-[130%]">3</div>
              </div>
              <div className="flex-1 bg-text-white overflow-hidden flex flex-col items-start justify-center p-4 gap-[18px] border-[1px] border-solid border-black sm:flex-[unset] sm:self-stretch">
                <div className="relative leading-[130%]">
                  Offers to be reviewed
                </div>
                <div className="relative t leading-[130%]">3</div>
              </div>
            </div>
            <div className="self-stretch bg-text-white overflow-hidden flex flex-col items-start justify-start py-[57px] px-0 border-[1px] border-solid border-black">
              <div className="self-stretch overflow-hidden flex flex-row items-start justify-center py-12 px-5">
                <div className="relative leading-[130%]">
                  Nothing to see here
                </div>
              </div>
            </div>
          </div>
        ) : subMenu.subMenu === "info" ? (
          <div className="flex-1 flex flex-col items-start justify-start pt-4 px-0 pb-0 gap-[24px]">
            <div className="self-stretch flex flex-row items-center justify-start text-title">
              <b className="relative leading-[130%]">Account</b>
            </div>
            {isLoading ? (
              <div className="self-stretch bg-text-white overflow-hidden flex flex-col items-start justify-start py-4 px-5 gap-[16px] text-regular border-[1px] border-solid border-black">
                <div className="self-stretch flex flex-col items-start justify-start gap-[8px] skeleton-loader">
                  <div className="self-stretch rounded-4xs min-h-4 w-full skeleton-loader" />
                  <div className="self-stretch rounded-4xs min-h-[1000px] w-full skeleton-loader sm:hidden mq840:hidden" />
                </div>
              </div>
            ) : (
              <div className="flex flex-row gap-[12px] w-full sm:flex-col mq840:flex-col">
                <div className="self-stretch bg-text-white overflow-hidden flex-1 flex flex-col items-start justify-start py-4 px-5 gap-[16px] text-regular border-[1px] border-solid border-black">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative text-regular leading-[135%]">
                      Full name
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                      <input
                        className="bg-text-white w-full rounded-tl-sm rounded-tr-none rounded-br-none rounded-bl-sm m-0 text-regular box-border overflow-scroll h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                        id="name_edit_input"
                        disabled={editInputDisabled}
                        onChange={(e) => setNameInput(e.target.value)}
                        value={nameInput}
                        type="text"
                        //Have a happy day and enjoy the rest of the night, hope you have a loverly rest of the day and the next jnjiwbtr3e`
                      />
                      <button
                        className="cursor-pointer py-3 px-4 bg-primary rounded-tl-none rounded-tr-sm rounded-br-sm rounded-bl-none box-border h-11 overflow-hidden inline-block flex flex-row items-center justify-center [transition:0.2s] border-[1px] border-solid border-black hover:[transition:0.2s] hover:mt-[-3px] hover:ml-[-3px] hover:mb-[3px] hover:mr-[3px] hover:shadow-[4px_4px] active:[transition:0.2s] active:mt-[-1px] active:ml-[-1px] active:mb-[1px] active:mr-[1px] active:shadow-[2px_2px] disabled:{background-color:black} disabled:cursor-default"
                        // onClick={() => {
                        //   sendOTP();
                        // }}
                        onClick={() => {
                          !editInputDisabled && updateName();
                          nameEditButtonfunction();
                        }}
                      >
                        {editNameProcessing ? (
                          <div className="loader"></div>
                        ) : (
                          <a className="relative text-regular leading-[135%] font-general-text text-black text-centre whitespace-nowrap">
                            {editInputDisabled ? "Edit" : "Save"}
                          </a>
                        )}
                      </button>
                    </div>
                    {nameError && (
                      <div
                        className={`self-stretch overflow-hidden flex-row items-center justify-start p-3 ${
                          nameErrorOrMessage ? "bg-error" : "bg-message" // Conditional class based on focus state
                        }`}
                      >
                        <div className="flex-1 relative">{nameError}</div>
                      </div>
                    )}
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative text-regular leading-[135%]">
                      Email
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                      <input
                        className="bg-text-white w-full rounded-tl-sm rounded-tr-none rounded-br-none rounded-bl-sm m-0 text-regular box-border overflow-scroll h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                        id="signin_ID_input"
                        disabled={true}
                        value={user ? user.email : ""}
                        type="text"
                      />
                      <button
                        className="cursor-pointer py-3 px-4 bg-primary rounded-tl-none rounded-tr-sm rounded-br-sm rounded-bl-none box-border h-11 overflow-hidden inline-block flex flex-row items-center justify-center [transition:0.2s] border-[1px] border-solid border-black hover:[transition:0.2s] hover:mt-[-3px] hover:ml-[-3px] hover:mb-[3px] hover:mr-[3px] hover:shadow-[4px_4px] active:[transition:0.2s] active:mt-[-1px] active:ml-[-1px] active:mb-[1px] active:mr-[1px] active:shadow-[2px_2px] disabled:bg-disabled disabled:cursor-default"
                        // onClick={() => {
                        //   sendOTP();
                        // }}
                        disabled={false}
                      >
                        <a className="relative text-regular leading-[135%] font-general-text text-black text-centre whitespace-nowrap">
                          Edit
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative text-regular leading-[135%]">
                      Phone
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                      <input
                        className="bg-text-white w-full rounded-tl-sm rounded-tr-none rounded-br-none rounded-bl-sm m-0 text-regular box-border overflow-scroll h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                        id="signin_ID_input"
                        disabled={true}
                        value={user ? user.phone : ""}
                        type="text"
                      />
                      <button
                        className="cursor-pointer py-3 px-4 bg-primary rounded-tl-none rounded-tr-sm rounded-br-sm rounded-bl-none box-border h-11 overflow-hidden inline-block flex flex-row items-center justify-center [transition:0.2s] border-[1px] border-solid border-black hover:[transition:0.2s] hover:mt-[-3px] hover:ml-[-3px] hover:mb-[3px] hover:mr-[3px] hover:shadow-[4px_4px] active:[transition:0.2s] active:mt-[-1px] active:ml-[-1px] active:mb-[1px] active:mr-[1px] active:shadow-[2px_2px] disabled:bg-disabled disabled:cursor-default"
                        // onClick={() => {
                        //   sendOTP();
                        // }}
                        disabled={false}
                      >
                        <a className="relative text-regular leading-[135%] font-general-text text-black text-centre whitespace-nowrap">
                          Edit
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                    <div className="relative text-regular leading-[135%]">
                      Password
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                      <input
                        className="bg-text-white w-full rounded-tl-sm rounded-tr-none rounded-br-none rounded-bl-sm m-0 text-regular box-border overflow-scroll h-11 [transition:0.2s] top-[0] left-[0] bottom-[0] py-0 px-3 border-[1px] border-solid border-black focus:relative focus:shadow-[4px_4px] focus:[transition:0.2s] focus:[outline:none] focus:bottom-[4px] focus:top-[-4px] focus:left-[-4px]"
                        id="signin_ID_input"
                        disabled={true}
                        value={"********"}
                        type="text"
                      />
                      <button
                        className="cursor-pointer py-3 px-4 bg-primary rounded-tl-none rounded-tr-sm rounded-br-sm rounded-bl-none box-border h-11 overflow-hidden inline-block flex flex-row items-center justify-center [transition:0.2s] border-[1px] border-solid border-black hover:[transition:0.2s] hover:mt-[-3px] hover:ml-[-3px] hover:mb-[3px] hover:mr-[3px] hover:shadow-[4px_4px] active:[transition:0.2s] active:mt-[-1px] active:ml-[-1px] active:mb-[1px] active:mr-[1px] active:shadow-[2px_2px] disabled:bg-disabled disabled:cursor-default"
                        // onClick={() => {
                        //   sendOTP();
                        // }}
                        disabled={false}
                      >
                        <a className="relative text-regular leading-[135%] font-general-text text-black text-centre whitespace-nowrap">
                          Edit
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" bg-text-white overflow-hidden flex-1 flex flex-col items-start justify-start py-4 px-5 gap-[16px] text-regular border-[1px] border-solid border-black">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-regular">
                    <p className="relative text-regular leading-[130%]">
                      Saved addresses
                    </p>
                    {user?.addresses?.length > 0 ? (
                      user?.addresses?.map((e, i) => (
                        <div
                          className={`self-stretch rounded-sm flex flex-row items-start justify-between py-3 px-4 border-[1px] border-solid border-black`}
                          onClick={() => {
                            // setSelectedIndex(i);
                          }}
                        >
                          <div className="shrink-0 flex flex-col items-start justify-start gap-[6px]">
                            <div className="relative leading-[130%]">
                              {e.address.name}
                            </div>
                            <div className="relative leading-[130%]">
                              {e.address.phone}
                            </div>
                            <div className="relative leading-[130%]">
                              <p className="m-0 max-w-[300px] whitespace-nowrap overflow-hidden text-ellipsis">
                                {e.address.address_line1},
                              </p>
                              <p className="m-0">
                                {e.address.city}, {e.address.state}
                              </p>
                              <p className="m-0">{e.address.pincode}</p>
                            </div>
                          </div>
                          <div className="self-stretch shrink-0 flex flex-col items-end justify-between text-smi">
                            <div className="relative cursor-pointer hover:[text-decoration:underline] leading-[130%]">
                              Edit
                            </div>
                            <div className="relative cursor-pointer hover:[text-decoration:underline] text-partsRed leading-[130%]">
                              Remove
                            </div>
                            {e.is_primary ? (
                              <div className="relative leading-[130%]">
                                Primary{" "}
                              </div>
                            ) : (
                              <div
                                className="relative leading-[130%] hover:[text-decoration:underline] cursor-pointer"
                                onClick={() => {
                                  // setPrimaryAddress(i);
                                }}
                              >
                                Set default{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="self-stretch flex flex-row items-center justify-center py-10 px-0">
                        <div className="relative leading-[130%]">
                          No saved addresses
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : subMenu.subMenu === "selling.offers" ? (
          <div className="self-stretch flex-1 flex flex-col items-start justify-start pt-4 px-0 pb-0 gap-[24px_0px] text-regular">
            <div className="self-stretch flex flex-row items-center justify-between text-title">
              <b className="relative leading-[130%]">Offers</b>
              <div className="w-[131px] bg-white box-border overflow-hidden shrink-0 flex flex-row items-end justify-between py-2 px-3 text-regular border-[1px] border-solid border-black">
                <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                  <div className="relative leading-[130%]">Filter:</div>
                  <div className="relative leading-[130%]">All</div>
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0"
                  alt=""
                  src="/chevron-down.svg"
                />
              </div>
            </div>
            <div className="w-[702px] flex-1 overflow-hidden hidden flex-row items-start justify-center py-12 px-5 box-border text-lg">
              <div className="relative leading-[130%]">Nothing to see here</div>
            </div>

            {allOfferItems?.map(
              (product, index) =>
                product.offersInfo.length !== 0 && (
                  <div className="w-full h-[100%] flex flex-row items-start justify-start gap-[0px_12px] sm:hidden mq840:hidden">
                    <Product product={product.productInfo} key={index} />
                    {/* {console.log(product.offersInfo)} */}
                    <div className="flex-1 flex-grow overflow-hidden flex flex-col items-start justify-start gap-[12px_0px]">
                      <div className="self-stretch flex flex-row items-start justify-between">
                        <div className="w-[131px] bg-white box-border overflow-hidden shrink-0 flex flex-row items-end justify-between py-2 px-3 border-[1px] border-solid border-black">
                          <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                            <div className="relative leading-[130%]">
                              Filter:
                            </div>
                            <div className="relative leading-[130%]">All</div>
                          </div>
                          <img
                            className="w-5 relative h-5 overflow-hidden shrink-0"
                            alt=""
                            src="/chevron-down.svg"
                          />
                        </div>
                        <div className=" bg-white box-border overflow-hidden shrink-0 flex flex-row items-end justify-between py-2 px-3 border-[1px] border-solid border-black">
                          <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                            <div className="relative leading-[130%]">Sort:</div>
                            <div className="relative leading-[130%]">
                              Most recent first
                            </div>
                          </div>
                          <img
                            className="w-5 relative h-5 overflow-hidden shrink-0"
                            alt=""
                            src="/chevron-down.svg"
                          />
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start text-base text-gray-1200">
                        <div className="self-stretch flex flex-row items-start justify-start text-white border-black border-solid border-[1px]">
                          <div className="flex-1 flex bg-black items-center justify-center py-2.5 px-4">
                            Time
                          </div>
                          <div className="flex-1 flex bg-black items-center justify-center py-2.5 px-4 ">
                            Buyer
                          </div>
                          <p className="flex-1 flex bg-black items-center justify-center py-2.5 px-4 ">
                            Rating
                          </p>
                          <div className="flex-1 flex bg-black items-center justify-center py-2.5 px-4 ">
                            Offer
                          </div>
                          <div className="flex-1 flex bg-black items-center justify-center py-2.5 px-4 ">
                            Status
                          </div>
                          <div className="flex-1 flex bg-black items-center justify-center py-2.5 px-4 ">
                            Respond
                          </div>
                        </div>
                        <div className="self-stretch flex flex-col items-start overflow-scroll h-full">
                          {product?.offersInfo?.map((offer, index) => [
                            <div className="self-stretch flex flex-row items-start justify-start mt-[-1px] border-black border-solid border-[1px]">
                              <div className="flex-1 bg-white flex flex-row items-center relative leading-[130%] justify-center py-2.5 px-2 ">
                                {offer?.timediff?.days === 0 &&
                                offer?.timediff?.hours === 0
                                  ? "Now"
                                  : offer?.timediff?.hours === 0
                                  ? `${offer?.timediff?.days}d`
                                  : `${offer?.timediff?.days}d ${offer?.timediff?.hours}h`}
                              </div>
                              <p className="flex-1 bg-white flex items-center justify-center relative leading-[130%] py-2.5 px-2 block whitespace-nowrap overflow-hidden text-ellipsis">
                                {`${offer?.buyerFirstName} ${offer?.buyerLastName}`}
                              </p>
                              <div className="self-stretch flex-1 bg-white flex flex-row relative leading-[135%] font-medium items-center justify-center py-2 px-2 gap-[0px_4px] text-center text-black">
                                <img
                                  className="w-4 relative h-4"
                                  alt=""
                                  src="/star-1.svg"
                                />
                                4.5
                              </div>
                              <div className="flex-1 bg-white flex flex-row relative leading-[130%] items-center justify-center py-2.5 px-2 ">
                                ₹{Number(offer?.amount).toLocaleString("en-IN")}
                              </div>
                              <div className="flex-1 bg-white flex flex-row relative leading-[130%] items-center justify-center py-2.5 px-2 ">
                                {offer?.status}
                              </div>
                              <div
                                className="flex-1 cursor-pointer bg-primary flex flex-row relative leading-[130%] items-center justify-center py-2.5 px-2 border-black border-solid border-l-[1px]"
                                onClick={() => {
                                  //console.log(offer);
                                  handleViewOffer(offer);
                                }}
                              >
                                View
                              </div>
                            </div>,
                          ])}
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}

            <div className="self-stretch hidden flex-col items-start justify-start gap-[12px_0px] sm:flex mq840:flex">
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="w-[131px] bg-white box-border overflow-hidden shrink-0 flex flex-row items-end justify-between py-2 px-3 border-[1px] border-solid border-black">
                  <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                    Filter: All
                  </div>
                  <img
                    className="w-5 relative h-5 overflow-hidden shrink-0"
                    alt=""
                    src="/chevron-down.svg"
                  />
                </div>
                <div className="bg-white overflow-hidden flex flex-row items-end justify-start py-2 px-3 gap-[0px_16px] border-[1px] border-solid border-black">
                  <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                    Sort: Newest to oldest
                  </div>
                  <img
                    className="w-5 relative h-5 overflow-hidden shrink-0"
                    alt=""
                    src="/chevron-down.svg"
                  />
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start text-base">
                <div className="self-stretch bg-white flex flex-row items-start justify-start p-2 gap-[0px_10px] border-[1px] border-solid border-black lg:p-1.5 lg:box-border sm:self-stretch sm:w-auto">
                  <img
                    className="h-[49px] w-[49px] relative rounded-12xs object-cover"
                    alt=""
                    src="/whatsapp-image-20221130-at-1214@2x.png"
                  />
                  <div className="flex-1 flex flex-col items-start justify-center gap-[7px_0px]">
                    <div className="self-stretch relative leading-[130%]">
                      Nvidia Zotac RTX 3060...
                    </div>
                    <div className="self-stretch flex flex-row items-center justify-start gap-[0px_4px] sm:gap-[0px_4px] sm:py-1.5 sm:px-2 sm:box-border">
                      <div className="relative leading-[130%] text-regular sm:text-regular">
                        ₹25,000
                      </div>
                      <div className="relative text-smi leading-[130%] text-regular sm:text-4xs">
                        + Free shipping
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start mt-[-1px] text-gray-1200">
                  <div className="self-stretch bg-white overflow-hidden flex flex-col items-center justify-start border-[1px] border-solid border-black">
                    <div className="self-stretch overflow-hidden flex flex-row items-center justify-start">
                      <div className="flex-1 flex flex-col items-start justify-center py-2 px-3 gap-[4px_0px]">
                        <div className="bg-white flex flex-row items-center justify-start gap-[0px_8px]">
                          <div className="relative leading-[130%]">
                            Akshat Tripathi
                          </div>
                          <div className="flex flex-row items-center justify-center gap-[0px_4px] text-center text-black">
                            <img
                              className="w-4 relative h-4"
                              alt=""
                              src="/vector.svg"
                            />
                            4.5
                          </div>
                        </div>
                        <div className="relative  leading-[130%] font-medium text-black text-regular">
                          ₹25,000
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-end justify-between py-2 px-4">
                        <i className="relative leading-[130%]">Received</i>
                        <div className="relative leading-[130%]">2d</div>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch bg-white overflow-hidden flex flex-col items-center justify-start mt-[-1px] border-[1px] border-solid border-black">
                    <div className="self-stretch overflow-hidden flex flex-row items-center justify-start">
                      <div className="flex-1 flex flex-col items-start justify-center py-2 px-3 gap-[4px_0px]">
                        <div className="bg-white flex flex-row items-center justify-start gap-[0px_8px]">
                          Akshat Tripathi
                          <div className="flex flex-row items-center justify-center gap-[0px_4px] text-center text-black">
                            <img
                              className="w-4 relative h-4"
                              alt=""
                              src="/vector.svg"
                            />
                            <div className="relative leading-[135%] font-medium">
                              4.5
                            </div>
                          </div>
                        </div>
                        <div className="relative  leading-[130%] font-medium text-black text-regular sm:text-regular">
                          ₹25,000
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-end justify-between py-2 px-4">
                        <i className="relative leading-[130%]">Sent</i>
                        <div className="relative leading-[130%]">2d</div>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch bg-white overflow-hidden flex flex-col items-center justify-start mt-[-1px] border-[1px] border-solid border-black">
                    <div className="self-stretch overflow-hidden flex flex-row items-center justify-start">
                      <div className="flex-1 flex flex-col items-start justify-center py-2 px-3 gap-[4px_0px]">
                        <div className="bg-white flex flex-row items-center justify-start gap-[0px_8px]">
                          <div className="relative leading-[130%]">
                            Akshat Tripathi
                          </div>
                          <div className="flex flex-row items-center justify-center gap-[0px_4px] text-center text-black">
                            <img
                              className="w-4 relative h-4"
                              alt=""
                              src="/vector.svg"
                            />
                            <div className="relative leading-[135%] font-medium">
                              4.5
                            </div>
                          </div>
                        </div>
                        <div className="relative  leading-[130%] font-medium text-black text-regular sm:text-regular">
                          ₹25,000
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-end justify-between py-2 px-4">
                        <i className="relative leading-[130%]">Sent</i>
                        <div className="relative leading-[130%]">2d</div>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch bg-white overflow-hidden flex flex-col items-center justify-start mt-[-1px] border-[1px] border-solid border-black">
                    <div className="self-stretch overflow-hidden flex flex-row items-center justify-start">
                      <div className="flex-1 flex flex-col items-start justify-center py-2 px-3 gap-[4px_0px]">
                        <div className="bg-white flex flex-row items-center justify-start gap-[0px_8px]">
                          <div className="relative leading-[130%]">
                            Akshat Tripathi
                          </div>
                          <div className="flex flex-row items-center justify-center gap-[0px_4px] text-center text-black">
                            <img
                              className="w-4 relative h-4"
                              alt=""
                              src="/vector.svg"
                            />
                            <div className="relative leading-[135%] font-medium">
                              4.5
                            </div>
                          </div>
                        </div>
                        <div className="relative  leading-[130%] font-medium text-black text-regular sm:text-regular">
                          ₹25,000
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col items-end justify-between py-2 px-4">
                        <i className="relative leading-[130%]">Sent</i>
                        <div className="relative leading-[130%]">2d</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch rounded-t-none rounded-b bg-black flex flex-row items-center justify-center py-2 px-3 gap-[0px_4px] mt-[-1px] text-regular text-white sm:gap-[0px_4px] sm:py-1.5 sm:px-2 sm:box-border">
                  <div className="relative leading-[130%]">Hide</div>
                  <img
                    className="w-5 relative h-5 overflow-hidden shrink-0 object-contain"
                    alt=""
                    src="/chevron-down.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : subMenu.subMenu === "selling.listings" ? (
          <div className="self-stretch flex-1 flex flex-col items-start justify-start pt-4 px-0 pb-0 gap-[24px_0px] text-regular">
            <div className="self-stretch flex flex-row items-center justify-between text-title">
              <b className="relative leading-[130%]">Your listings</b>
              <div className="w-[131px] bg-white box-border overflow-hidden shrink-0 flex flex-row items-end justify-between py-2 px-3 text-regular border-[1px] border-solid border-black">
                <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                  <div className="relative leading-[130%]">Filter:</div>
                  <div className="relative leading-[130%]">All</div>
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0"
                  alt=""
                  src="/chevron-down.svg"
                />
              </div>
            </div>
            <div className="w-[702px] flex-1 overflow-hidden hidden flex-row items-start justify-center py-12 px-5 box-border text-lg">
              <div className="relative leading-[130%]">Nothing to see here</div>
            </div>

            <div className="self-stretch grid flex-row items-center justify-start gap-[42px] grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))] [row-gap:36px] lg:grid lg:grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))] lg:[row-gap:24px] lg:max-w-[96%] md:flex md:[column-gap:24px] md:max-w-full md:overflow-x-scroll md:pt-1.5 md:pb-1 md:pr-0 md:pl-[15px] sm:flex sm:max-w-full sm:[column-gap:16px] sm:[justify-content:start] sm:overflow-scroll sm:pt-1.5 sm:pb-1 sm:pr-0 sm:pl-[15px] mq840:flex mq840:[column-gap:24px] mq840:overflow-y-visible mq840:max-w-full mq840:overflow-x-scroll mq840:pt-1.5 mq840:pb-1 mq840:pr-0 mq840:pl-3">
              {allSelleritems?.map((product, index) => (
                <div className="w-full flex flex-col gap-[12px]">
                  <div
                    className="rounded-sm bg-white self-stretch flex flex-row items-center justify-center py-4 leading-[130%] text-black border-[1px] border-solid border-black w-[210px] lg:w-[185px] sm:w-[150px] mq840:w-[185px]sm:py-3"
                    // onClick={openMakeanofferPopup}
                  >
                    Active
                  </div>
                  <Product product={product} key={index} />
                  <div
                    className="rounded-sm bg-white self-stretch flex flex-row items-center justify-center py-4 leading-[130%] text-black border-[1px] border-solid border-black w-[210px] lg:w-[185px] sm:w-[150px] mq840:w-[185px]sm:py-3"
                    // onClick={openMakeanofferPopup}
                  >
                    Edit listing
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex-col hidden items-start justify-start text-regular sm:fixed sm:left-[0px] sm:bottom-[0px] sm:z-[10] sm:w-full mq840:fixed mq840:bottom-[0px] mq840:left-[0px] mq840:w-full mq840:z-[10] sm:flex mq840:flex">
        <div
          className={`self-stretch bg-text-white overflow-hidden flex flex-col items-start justify-start md:hidden sm:flex mq840:flex pullup ${
            menuOpen ? "visible h-[300px]" : "invisible h-0"
          }`}
        >
          <div className="self-stretch flex flex-col items-start justify-start py-4 px-3 gap-[4px] t border-[1px] border-solid border-black">
            <div className="flex flex-row items-start justify-start gap-[4px]">
              <div className="relative">Hi,</div>
              <div className="relative">{user ? user?.first_name : "user"}</div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[4px] text-regular">
              <div className="relative">Member since</div>
              <div className="relative">year</div>
            </div>
          </div>
          <button
            className="cursor-pointer p-2.5 bg-[transparent] self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px] [transition:0.2s] [border-bottom:1px] mt-[-1px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
            id="menud_orders_button"
            onClick={() => {
              setMenuOpen(!menuOpen);
              navigate("/account");
            }}
          >
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/ordersvgrepocom-1.svg"
            />
            <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
              Summary
            </div>
          </button>
          <div className="self-stretch overflow-hidden flex flex-col items-end justify-center mt-[-1px] border-[1px] border-solid border-black [&_.frame-parent31]:active:flex">
            <div className="self-stretch flex flex-row items-center justify-between py-2.5 px-3 [transition:0.2s] hover:bg-optionHover hover:[transition:0.2s]">
              <div
                className="shrink-0 flex flex-row items-center justify-start gap-[8px]"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  navigate("/account/orders");
                }}
              >
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/ordersvgrepocom-1.svg"
                />
                <div className="relative leading-[130%]">Buying</div>
              </div>
              <img
                className="relative w-5 h-5 overflow-hidden shrink-0 object-cover"
                alt=""
                src="/chevron-down-1.svg"
              />
            </div>
            <div
              className="self-stretch hidden flex-col items-end justify-center pt-0 px-0 pb-2 [&.animate]:animate-[0.5s_ease-in-out_0s_1_normal_forwards_slide-in-top] opacity-[0] text-regular"
              id="buying_expanded"
              data-animate-on-scroll
            >
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <div className="relative leading-[130%]">Orders</div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <div className="relative leading-[130%]">Offers</div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <div className="relative leading-[130%]">Recently viewed</div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <div className="relative leading-[130%]">Watchlist</div>
              </div>
            </div>
          </div>
          <div className="self-stretch overflow-hidden flex flex-col items-end justify-center mt-[-1px] border-[1px] border-solid border-black [&_.frame-parent32]:active:flex">
            <div className="self-stretch flex flex-row items-center justify-between py-2.5 px-3 [transition:0.2s] hover:bg-optionHover hover:[transition:0.2s]">
              <div className="shrink-0 flex flex-row items-center justify-start gap-[8px]">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/ordersvgrepocom-1.svg"
                />
                <div className="relative leading-[130%]">Selling</div>
              </div>
              <img
                className="relative w-5 h-5 overflow-hidden shrink-0 object-cover"
                alt=""
                src="/chevrondown-1@2x.png"
              />
            </div>
            <div
              className="self-stretch hidden flex-col items-end justify-center pt-0 px-0 pb-2 [&.animate]:animate-[0.5s_ease-in-out_0s_1_normal_forwards_slide-in-top] opacity-[0] text-regular"
              id="buying_expanded"
              data-animate-on-scroll
            >
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <div className="relative leading-[130%]">Orders</div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <div className="relative leading-[130%]">Offers</div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <div className="relative leading-[130%]">Recently viewed</div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-end py-1 px-3">
                <div className="relative leading-[130%]">Watchlist</div>
              </div>
            </div>
          </div>
          <button
            className="cursor-pointer p-2.5 bg-[transparent] self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px] [transition:0.2s] mt-[-1px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
            id="menud_orders_button"
          >
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/ordersvgrepocom-1.svg"
            />
            <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
              Recently Viewed
            </div>
          </button>
          <button
            className="cursor-pointer p-2.5 bg-[transparent] self-stretch overflow-hidden flex flex-row items-center justify-start gap-[12px] [transition:0.2s] mt-[-1px] border-[1px] border-solid border-black hover:bg-optionHover hover:[transition:0.2s]"
            id="menud_orders_button"
            onClick={() => {
              setMenuOpen(!menuOpen);
              navigate("/account/info");
            }}
          >
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/ordersvgrepocom-1.svg"
            />
            <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
              Account
            </div>
          </button>
        </div>
        <div
          className="self-stretch cursor-pointer bg-black box-border h-[45px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-0 mt-[-1px] text-text-white border-t-[1px] border-solid border-black border-r-[1px]"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <div className="relative leading-[130%]">Orders</div>
        </div>
      </div>
      <OfferCountered
        // product={product}
        title={"New offer"}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        open={
          searchParams.get("offer")
            ? true && openOfferType === "received"
            : false
        }
        offer={selectedOffer}
        type={"seller"}
      />
      <OfferSent
        // product={product}
        title={"New offer"}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        open={
          searchParams.get("offer") ? true && openOfferType === "sent" : false
        }
        offer={selectedOffer}
        type={"seller"}
      />
    </div>
  );
};

export default Account;
