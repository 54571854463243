import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../redux/actions/auth";

const Ham = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div
      className="bg-background w-[390px] h-full overflow-hidden flex flex-col items-center justify-start [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-left] opacity-[0] max-w-[90%] text-left text-md text-text-white font-general-text sm:overflow-y-scroll"
      data-animate-on-scroll
    >
      <div className="self-stretch bg-new-black flex flex-row items-start justify-between pt-6 pb-[22px] pr-5 pl-6">
        <div className="flex flex-col items-start justify-start gap-[8px]">
          <div className="relative leading-[130%] text-md">Hi,</div>
          {user ? (
            <div className="self-stretch flex flex-row items-start justify-start gap-[16px] text-title flex items-end justify-center ">
              <p className="m-0 relative leading-[130%] inline-block w-[197px] h-[29px] shrink-0 whitespace-nowrap overflow-hidden text-ellipsis text-title">
                {user?.first_name}
              </p>
              <button
                className="cursor-pointer py-1 px-3 bg-text-white flex flex-row items-center justify-start border-[1px] border-solid border-nav-bar sm:flex"
                id="logout_button"
                onClick={() => {
                  dispatch(logoutUser());
                  navigate("/");
                }}
              >
                <div className="relative text-xs leading-[130%] font-sub-text text-text text-left">
                  Logout
                </div>
              </button>
            </div>
          ) : (
            <button
              className="cursor-pointer py-2 px-4 bg-text-white flex flex-row items-center justify-center border-[1px] border-solid border-text-white sm:flex mq840:flex"
              id="sign_in_button"
              onClick={() => {
                onClose();
                navigate("/signin");
              }}
            >
              <div className="relative text-regular leading-[130%] font-light font-general-text text-black text-left">
                Sign in or register
              </div>
            </button>
          )}
        </div>
        <img
          className="relative w-6 h-6 overflow-hidden shrink-0 cursor-pointer"
          alt=""
          src="/crosssvgrepocom4-1.svg"
          onClick={onClose}
        />
      </div>
      <div className="self-stretch flex-1 flex flex-col items-center justify-between py-4 px-6 gap-[24px] overflow-y-scroll text-regular text-black">
        <div className="self-stretch flex flex-col items-center justify-start gap-[16px] overflow-y-scroll">
          <div className="self-stretch bg-background flex flex-col items-center justify-center gap-[12px]">
            <button
              className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch flex flex-row items-center justify-between"
              id="notif_expand_button"
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
            >
              <span className="relative text-title leading-[130%] font-general-text text-black text-left">
                Notifications
              </span>
              <button
                className="cursor-pointer [border:none] p-0 bg-[transparent] relative w-4 h-[9px]"
                id="notif_expand_button"
              >
                <img
                  className="absolute top-[0px] left-[0px] w-4 h-[9px]"
                  alt=""
                  src="/icon2.svg"
                />
              </button>
            </button>
            <div
              className={`self-stretch flex flex-col items-end justify-center gap-[8px] ${
                isExpanded ? "" : "hidden"
              }`}
            >
              <div className="self-stretch flex flex-col items-start justify-start">
                <a className="[text-decoration:none] self-stretch bg-text-white overflow-hidden flex flex-row items-center justify-start py-2 px-3 gap-[12px] text-[inherit] border-[1px] border-solid border-black sm:pt-2 sm:pb-2 sm:box-border">
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/communicationcontactemailsvgrepocom-1.svg"
                  />
                  <div className="flex flex-col items-start justify-center gap-[2px] sm:gap-[2px]">
                    <div className="relative leading-[130%]">New message</div>
                    <div className="relative text-xs leading-[130%]">
                      Brand new Macbook Pro...
                    </div>
                  </div>
                </a>
                <a className="[text-decoration:none] self-stretch bg-text-white overflow-hidden flex flex-row items-center justify-start py-2 px-3 gap-[12px] text-[inherit] border-r-[1px] border-solid border-black border-b-[1px] border-l-[1px]">
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/offersvgrepocom2-1.svg"
                  />
                  <div className="flex flex-col items-center justify-start gap-[2px]">
                    <div className="relative leading-[130%]">
                      Offer accepted! Pay now
                    </div>
                    <div className="relative text-xs leading-[130%]">
                      iPhone 12 85% battery health...
                    </div>
                  </div>
                </a>
                <div className="self-stretch bg-text-white overflow-hidden hidden flex-row items-center justify-center py-3 px-2 border-[1px] border-solid border-black">
                  <div className="relative leading-[130%]">
                    No new notifications
                  </div>
                </div>
                <div className="self-stretch bg-text-white overflow-hidden hidden flex-row items-center justify-center py-3 px-2 border-[1px] border-solid border-black">
                  <button
                    className="cursor-pointer [border:none] p-0 bg-[transparent] relative text-regular [text-decoration:underline] leading-[130%] font-general-text text-black text-left inline-block"
                    id="notif_signin_button"
                  >
                    Sign in to view your notifications
                  </button>
                </div>
              </div>
              <a
                className="[text-decoration:underline] relative text-xs leading-[130%] text-[inherit]"
                id="notif_viewall_button"
              >
                View all
              </a>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-center gap-[12px] text-title">
            <div className="relative leading-[130%]">Menu</div>
            <div className="self-stretch flex flex-col items-center justify-start">
              <button
                className="cursor-pointer py-2.5 px-3 bg-text-white self-stretch flex flex-row items-center justify-start gap-[12px] border-[1px] border-solid border-nav-bar"
                id="menu_home_button"
              >
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/homesmartsvgrepocom-1.svg"
                />
                <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                  Home
                </div>
              </button>
              <button
                className="cursor-pointer py-2.5 px-3 bg-text-white self-stretch flex flex-row items-center justify-start gap-[12px] mt-[-1px] border-[1px] border-solid border-nav-bar"
                id="menu_blog_button"
              >
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/blogcommenteditsvgrepocom-1.svg"
                />
                <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                  Blog
                </div>
              </button>
              <button
                className="cursor-pointer py-2.5 px-3 bg-text-white self-stretch flex flex-row items-center justify-start gap-[12px] mt-[-1px] border-[1px] border-solid border-nav-bar"
                id="menu_rv_button"
              >
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/rewindbuttonsvgrepocom-1.svg"
                />
                <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                  Recently viewed
                </div>
              </button>
              <button
                className="cursor-pointer py-2.5 px-3 bg-text-white self-stretch flex flex-row items-center justify-start gap-[12px] mt-[-1px] border-[1px] border-solid border-nav-bar"
                id="menu_offers_button"
              >
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/offersvgrepocom2-1.svg"
                />
                <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                  Offers
                </div>
              </button>
              <button
                className="cursor-pointer py-2.5 px-3 bg-text-white self-stretch flex flex-row items-center justify-start gap-[12px] mt-[-1px] border-[1px] border-solid border-nav-bar"
                id="menu_orders_button"
                onClick={() => {
                  onClose();
                  navigate("/account/orders");
                }}
              >
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/ordersvgrepocom-1.svg"
                />
                <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                  Orders
                </div>
              </button>
              <button
                className="cursor-pointer py-2.5 px-3 bg-text-white self-stretch flex flex-row items-center justify-start gap-[12px] mt-[-1px] border-[1px] border-solid border-nav-bar"
                id="menu_selling_button"
              >
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/salesvgrepocom-1.svg"
                />
                <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                  Selling
                </div>
              </button>
              <button
                className="cursor-pointer py-2.5 px-3 bg-text-white self-stretch flex flex-row items-center justify-start gap-[12px] mt-[-1px] border-[1px] border-solid border-nav-bar"
                id="menu_account_button"
                onClick={() => {
                  onClose();
                  navigate("/account");
                }}
              >
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/idwalletsvgrepocom-1.svg"
                />
                <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                  Account
                </div>
              </button>
              <button
                className="cursor-pointer py-2.5 px-3 bg-text-white self-stretch flex flex-row items-center justify-start gap-[12px] mt-[-1px] border-[1px] border-solid border-nav-bar"
                id="menu_help_button"
              >
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/questionhelpsvgrepocom-1.svg"
                />
                <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
                  Help and Support
                </div>
              </button>
            </div>
          </div>
        </div>
        <button
          className="cursor-pointer py-3 px-0 bg-primary self-stretch shadow-[4px_4px_0px_#000] flex flex-row items-center justify-center border-[1px] border-solid border-gray-1300"
          id="sell_now_button"
          onClick={() => {
            onClose();
            user ? navigate("/sellnow/drafts") : navigate("/signin");
          }}
        >
          <div className="relative text-regular leading-[130%] font-general-text text-black text-left">
            Sell Now
          </div>
        </button>
      </div>
    </div>
  );
};

export default Ham;
