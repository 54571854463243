import { useState } from "react";
import Navbar from "../components/Navbar";

const TermsOrderCancellation = () => {
  const [frameInputValue, setFrameInputValue] = useState("");
  return (
    <div className="relative bg-whitesmoke-100 w-full overflow-hidden flex flex-col items-start justify-start pt-6 px-0 pb-6 box-border gap-[24px] text-left text-regular text-black font-general-text sm:pt-0 mq840:pt-0 sm:box-border">
      <Navbar
        icon1="/icon1.svg"
        vector="/vector.svg"
        ellipse5="/ellipse-5@2x.png"
        signUpFramePadding="16px"
        signUpFrameGap="16px"
        frameDivCursor="pointer"
        vector1="/vector1.svg"
        vector2="/vector2.svg"
        navbarAlignSelf="stretch"
        navbarWidth="unset"
        signedInFrameMarginTop="unset"
        frame1165="/frame-1165.svg"
        communicationContactEMail="/communicationcontactemailsvgrepocom-1.svg"
        offerSvgrepoCom21="/offersvgrepocom2-1.svg"
        orderSvgrepoCom1="/ordersvgrepocom-1.svg"
        rewindButtonSvgrepoCom1="/rewindbuttonsvgrepocom-1.svg"
        saleSvgrepoCom1="/salesvgrepocom-1.svg"
        idWalletSvgrepoCom1="/idwalletsvgrepocom-1.svg"
      />
      <div className="self-stretch flex flex-col items-start justify-center gap-[16px] pl-[4%] pr-[4%]">
        <div className="flex flex-row items-center justify-start gap-[4px]">
          <a className="cursor-pointer relative [text-decoration:underline] leading-[140%]">
            Home
          </a>
          <div className="relative leading-[140%]">{`>`}</div>
          <a className="cursor-pointer relative [text-decoration:underline] leading-[140%]">
            Customer service
          </a>
          <div className="relative leading-[140%]">{`>`}</div>
          <a className="cursor-pointer relative [text-decoration:underline] leading-[140%]">
            Order cancellation and refunds
          </a>
        </div>
        <div className="flex flex-col relative leading-[140%] font-bold items-start justify-start text-title">
          Order cancellation and refunds
        </div>
        <div className="self-stretch bg-text-white flex flex-col items-start justify-start py-4 px-5 text-regular border-[1px] border-solid border-black">
          <p className="self-stretch relative leading-[50%]">
            <span className="font-regular text-regular leading-[150%]">
              We understand that there may be times when orders need to be
              canceled. Buyers can request a cancellation, but generally only
              sellers can cancel an order. We encourage sellers to accept a
              buyer's cancellation request as long as they haven't yet shipped
              the item.
            </span>
            <br />
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              When a seller can cancel an order
            </span>
            <br />
            <br />
            <span className="leading-[150%]">
              A seller can cancel an order if:
            </span>
            <ul className="leading-[150%] pl-4">
              <li className="">
                The buyer asks to cancel the order and they haven't shipped the
                item yet
              </li>
              <li className="mb-0">
                The buyer hasn't paid within the time allowed
              </li>
              <li className="mb-0">
                <span>
                  <span>
                    The buyer used the wrong shipping address when they
                    completed their purchase
                  </span>
                </span>
              </li>
              <li className="mb-0">
                <span>The item is out of stock (this will result in a </span>
                <a
                  className="text-[inherit]"
                  href="https://www.CoinOrBill.com/help/policies/member-behavior-policies/order-cancellation-policy?id=5298#defect"
                  target="_blank"
                >
                  <span className="[text-decoration:underline]">
                    transaction defect
                  </span>
                </a>
                )
              </li>
            </ul>
            <span className="leading-[150%]">
              Sellers won't be able to cancel an order if the buyer has reported
              that the item hasn't arrived, or has opened a return request.
            </span>
            <br />
            <span className="leading-[150%]">
              When a buyer purchases more than one item, the entire order will
              be canceled. Sellers can't cancel individual items when multiple
              items are purchased as part of the same order.
            </span>
            <br />
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              Refunds
            </span>
            <br />
            <br />
            <span className="leading-[150%]">
              If the buyer completed payment through CoinOrBill checkout and the
              seller cancels the order, a full refund will be issued to the
              buyer's original payment method.
            </span>
            <br />
            <span className="leading-[150%]">
              If payment was not completed through CoinOrBill checkout and the
              seller cancels the order (for example, the buyer paid by check or
              cash), the seller will be responsible for issuing a full refund to
              the buyer.
            </span>
            <br />
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              Abusing the order cancellation process
            </span>
            <br />
            <br />
            <span className="leading-[150%]">
              Sellers aren't allowed to abuse the order cancellation process. A
              seller should never cancel an order and select an incorrect
              cancellation reason, including falsely claiming that the buyer
              failed to pay. Learn more in our{" "}
              <a
                className="text-[inherit]"
                href="https://www.CoinOrBill.com/help/policies/payment-policies/unpaid-item-policy?id=4271"
                target="_blank"
              >
                Unpaid item policy
              </a>
            </span>
            <br />
            <span className="leading-[150%]">
              Activity on CoinOrBill is required to:
            </span>
            <ul className="leading-[150%] pl-4">
              <li className="mb-0">
                <span>Follow all applicable laws</span>
              </li>
              <li className="mb-0">
                <span>Respect the rights of third parties</span>
              </li>
              <li className="mb-0">
                <span>Follow the CoinOrBill User Agreement</span>
              </li>
              <li className="mb-0">
                <span>Follow this CoinOrBill policy</span>
              </li>
            </ul>
            <span className="leading-[150%]">
              If activity doesn't meet these requirements, CoinOrBill may take
              action consistent with applicable laws and the CoinOrBill{" "}
              <a
                className="text-[inherit]"
                href="https://www.CoinOrBill.com/help/policies/payment-policies/unpaid-item-policy?id=4271"
                target="_blank"
              >
                User Agreement
              </a>
              , and may even be legally required to do so. Such actions may
              include, as an example only: administratively ending listings or
              canceling transactions, hiding or lowering the placement of
              listings in search results, lowering seller rating, restricting
              buying or selling, forfeiture of fees, loss of buyer or seller
              protections, restricting access to member communication and rating
              tools, removing feedback, and account suspension.
            </span>
            <span className="leading-[150%]">
              When a buyer purchases more than one item, the entire order will
              be canceled. Sellers can't cancel individual items when multiple
              items are purchased as part of the same order.
            </span>
            <br />
            <br />
            <br />
            <span className="text-md leading-[120%] font-medium font-regular">
              Other items and related policies
            </span>
            <br />
            <br />
            <ul className="leading-[150%] pl-4">
              <li className="mb-0">
                <span>Once an order has been canceled, it can't be undone</span>
              </li>
              <li className="mb-0">
                <span>
                  A seller may be eligible for{" "}
                  <a className="text-[inherit]" href="" target="">
                    fee credits
                  </a>{" "}
                  for canceled orders
                </span>
              </li>
              <li className="mb-0">
                <span>
                  Buying an item or bidding on an auction on CoinOrBill is a
                  commitment to complete a purchase. Buyers should pay for items
                  within 4 calendar days or the seller can choose to cancel the
                  order. If buyers have excessive canceled orders for unpaid
                  items, they may be subject to the consequences outlined in our
                  <a className="text-[inherit]" href="" target="_blank">
                    Unpaid item policy
                  </a>{" "}
                  and{" "}
                  <a className="text-[inherit]" href="" target="_blank">
                    Abusive buyer policy
                  </a>{" "}
                </span>
              </li>
              <li className="mb-0">
                <span>
                  A buyer can still leave feedback on a canceled transaction,
                  unless the order was canceled because they didn't pay for the
                  item. Learn more in our{" "}
                  <a
                    className="text-[inherit]"
                    href="https://www.CoinOrBill.com/help/policies/payment-policies/unpaid-item-policy?id=4271"
                    target="_blank"
                  >
                    Unpaid item policy
                  </a>
                </span>
              </li>
              <li className="mb-0">
                <span>
                  Canceling an order because the item is out of stock will
                  result in a transaction defect, as sellers are responsible for
                  fulfilling the items they've sold. Learn more about the
                  factors that affect a{" "}
                  <a
                    className="text-[inherit]"
                    href="https://www.CoinOrBill.com/help/policies/payment-policies/unpaid-item-policy?id=4271"
                    target="_blank"
                  >
                    seller's performance level
                  </a>
                </span>
              </li>
              <li className="mb-0">
                <span>
                  If the buyer has paid and the seller declines or doesn't
                  respond to the buyer's request to cancel, they'll need to wait
                  until the item is received and then{" "}
                  <a
                    className="text-[inherit]"
                    href="https://www.CoinOrBill.com/help/policies/payment-policies/unpaid-item-policy?id=4271"
                    target="_blank"
                  >
                    start a return request
                  </a>{" "}
                  instead
                </span>
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOrderCancellation;
