import { useSelector } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState, useMemo, useRef } from "react";

const OfferAccepted = ({
  product,
  searchParams,
  setSearchParams,
  open,
  locked,
  children,
  data,
  type,
  ...props
}) => {
  const { user } = useSelector((state) => state.auth);
  const [amount, setAmount] = useState("");
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [error, showerror] = useState("");
  const modalRef = useRef(null);

  // Eventlistener: trigger onclose when cancel detected
  const onClose = useCallback((e) => {
    setSearchParams({});
    const { current: el } = modalRef;
    el.close();
  }, []);

  // Eventlistener: trigger onclose when cancel detected
  const onCancel = useCallback(
    (e) => {
      e.preventDefault();
      if (!locked) onClose();
    },
    [locked, onClose]
  );

  // Eventlistener: trigger onclose when click outside
  const onClick = useCallback(
    ({ target }) => {
      const { current: el } = modalRef;
      if (target === el) onClose();
    },
    [onClose]
  );

  // Eventlistener: trigger close click on anim end
  const onAnimEnd = useCallback(() => {
    const { current: el } = modalRef;
    if (!open) el.close();
  }, [open]);

  // when open changes run open/close command
  useEffect(() => {
    const { current: el } = modalRef;
    if (open) el.showModal();
  }, [open]);

  const initializePayment = (data) => {
    console.log(data);
    const options = {
      key: "rzp_test_Yjas4yyrRospJ4",
      amount: data.amount,
      currency: data.currency,
      name: product?.title,
      description: "Test Transaction",
      image: product?.images[0],
      order_id: data.id,
      method: {
        netbanking: false,
        card: true,
        wallet: true,
        upi: true,
      },
      handler: async (response) => {
        try {
          console.log(response);
          const { data } = await axiosInstance.post(
            "/order/verifyrazorpaypayment",
            {
              ...response,
              transactionType: "offer",
              offerAmount: amount,
              productId: product?._id,
              userId: user?.data?._id,
            }
          );
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async () => {
    try {
      setIsPaymentLoading(true);
      //console.log(product);
      const response = await axiosInstance.post("/order/createrazorpayorder", {
        amount: amount,
        productId: product?._id,
      });
      //console.log(response);

      if (response?.data?.success) {
        setIsPaymentLoading(false);
        setIsPaymentProcessing(true);
        initializePayment(response?.data.order);
        setIsPaymentLoading(false);
        setIsPaymentProcessing(false);
        return;
      } else console.log(response?.data);
    } catch (err) {
      setIsPaymentProcessing(false);
      setIsPaymentLoading(false);
      console.log(err);
    }
  };

  return (
    <dialog
      className="top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]"
      ref={modalRef}
      onClose={onClose}
      onCancel={onCancel}
      onClick={onClick}
      onAnimationEnd={onAnimEnd}
    >
      <div className="w-[450px] rounded-sm bg-white box-border overflow-hidden flex flex-col items-center justify-start py-6 px-5 gap-[16px] text-title text-black font-general-text border-[1px] border-solid border-black">
        <h3 className="self-stretch text-center justify-start font-medium m-0 relative text-title leading-[135%]">
          Offer accepted
        </h3>
        <section className="self-stretch relative text-regular leading-[130%] text-black text-left">
          <span>{`The seller has accepted your offer for 5,000. Your payment method has been charged for the deposit amount. Please make a payment for the remaining amount within the next `}</span>
          <span className="font-medium">72 hours</span>
        </section>
        <button className="self-stretch cursor-pointer p-3 bg-black flex-1 flex flex-row items-center justify-center border-[1px] border-solid border-black hover:bg-darkslategray-400 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-400">
          <a className="flex-1 relative text-regular leading-[135%] text-white text-center ">
            Pay now
          </a>
        </button>
      </div>
    </dialog>
  );
};

export default OfferAccepted;
