import { hideModal } from "./modal";
import {
  ADD_ADDRESS,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SET_CART_ADDRESS,
  USER_CART,
} from "./types";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import { useSelector } from "react-redux";
import {
  handleShipmentCost,
  handleShipmentCostUser,
} from "../../utils/ShipmentCost";
import { useState } from "react";
import { getCurrentUser } from "./auth";

export const addAddress = (address) => {
  return function (dispatch) {
    dispatch({
      type: ADD_ADDRESS,
      payload: address,
    });
    dispatch(hideModal());
  };
};

export const setCartAddress = (address) => {
  return function (dispatch) {
    dispatch({
      type: SET_CART_ADDRESS,
      payload: address,
    });
    dispatch(hideModal());
  };
};

export const addProductToCart = (productId, cartId) => {
  return function (dispatch) {
    // saving on the backend
    //console.log(productId, cartId);
    return axiosInstance
      .post(`/cart/add/`, { productId, cartId })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          toast.success("Item added to Cart!");
          console.log(cartId);
          return dispatch(getUserCart(cartId));
          // Return the promise generated by dispatch(getUserCart())
        } else {
          toast.error("Error adding item to cart");
          console.log(cartId);
          return dispatch(getUserCart(cartId));
          // Propagate the error to the caller
          throw new Error("Error adding item to cart");
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.response) {
          switch (error.response.status) {
            default:
              toast.error("Error adding item to cart");
              break;
          }
        } else {
          toast.error("Couldn't reach the server");
        }
        // Propagate the error to the caller
        throw error;
      });
  };
};

export const removeProductFromCart = (productId, cartId) => {
  return function (dispatch) {
    // removing from backend
    console.log(productId, cartId);
    return axiosInstance
      .post(`/cart/remove/`, { productId, cartId })
      .then((response) => {
        toast.success("Item removed from Cart!");
        dispatch(getUserCart(cartId));
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            default:
              // server error
              toast.error("Oops, something went wrong");

              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
      });
  };
};

export const getUserCart = (cartId) => {
  return function (dispatch) {
    // Return the promise generated by the asynchronous operation
    //console.log(cartId);
    return axiosInstance
      .get(`/user/cart`)
      .then((response) => {
        //console.log(response);
        dispatch({
          type: USER_CART,
          payload: response.data.data,
        });
        return response.data.data;
      })
      .catch(({ response }) => {
        try {
          switch (response.status) {
            default:
              // server error
              toast.error("Oops, something went wrong");
              break;
          }
        } catch (e) {
          toast.error("Couldn't reach the server");
        }
        // Propagate the error to the caller
        throw response;
      });
  };
};
