import { useNavigate } from "react-router";

const ProductCard = ({
  product,
  isSelling,
  isBuying,
  data,
  remove,
  status,
  offerPrice,
  offer,
}) => {
  function numberWithIndianCommas(x) {
    // console.log(x.toLocaleString("en-IN"));
    if (x) return x.toLocaleString("en-IN");
  }
  // Address
  let formattedAddress = product?.address?.full;
  if (formattedAddress) {
    const parts = formattedAddress.split(",");
    if (parts.length >= 3) {
      const lastIndex = parts.length - 3;
      const secondToLastIndex = lastIndex - 1;
      const result = `${parts[secondToLastIndex].trim()}, ${parts[
        lastIndex
      ].trim()}`;

      formattedAddress = result;
    }
  }

  // if (!data)
  //   data = {
  //     id: 1,
  //     seller: "@AkshatTripathi",
  //     title: "Hyundai Venue",
  //     desc: "Interested Buyers Contact Me Shipping All Over India",
  //     shipping: "200.00",
  //     deliverydate: "12 Dec 2022",
  //     price: "₹4,60,000",
  //     acceptprice: "₹4,00,000",
  //     img: require("../assets/new design/car/car_img1.png"),
  //     location: " Ashok Vihar Delhi",
  //     year: "2019",
  //     time: "02 D 12 Hr",
  //     distance: "5000 KMS",
  //     ownership: "1st",
  //   };

  // const toggleToWishlist = () => {
  //   // code to add to wishlist
  //   settoggleliked(!toggleliked);
  // };

  return (
    <a
      className="cursor-pointer rounded-sm bg-text-white box-border w-[212px] flex flex-col items-start justify-start relative [transition:0.2s] text-left text-xs text-black font-general-text border-[1px] border-solid border-black hover:[transition:0.2s] hover:mt-[-3px] hover:ml-[-3px] hover:mb-[3px] hover:mr-[3px] hover:shadow-[5px_5px] active:[transition:0.2s] active:mt-[-1px] active:ml-[-1px] active:mb-[1px] active:mr-[1px] active:shadow-[2px_2px] lg:w-[187px] sm:w-[152px] mq840:w-[187px]"
      id="product_card"
      href={`/product/${product?._id}`}
    >
      <div className="flex flex-col items-center justify-start absolute gap-[10px] z-[4] top-[10px] left-[-8px]">
        {product?.condition === "NEW" ? (
          <img
            className="relative w-[54px] h-[27px] z-[0]"
            alt=""
            src={require("../assets/icons/vector8.svg").default}
          />
        ) : product?.condition === "FORPARTS" ? (
          <img
            className="relative w-[97px] h-[27px] z-[0]"
            alt=""
            src={require("../assets/icons/forparts.svg").default}
          />
        ) : (
          <img
            className="relative w-[62px] h-[27px] z-[0]"
            alt=""
            src={require("../assets/icons/vector3.svg").default}
          />
        )}

        <div
          className={`self-stretch absolute leading-[120%] top-[calc(50%_-_8px)] font-regular  pt-0 pr-0 pb-0 z-[1] ${
            product?.condition === "NEW"
              ? "pl-2.5"
              : product?.condition === "FOR PARTS"
              ? "pl-2.5"
              : "pl-2.5"
          }`}
        >
          {product?.condition === "FORPARTS" ? "FOR PARTS" : product?.condition}
        </div>
      </div>
      <div className="relative box-border border-b border-solid border-black  w-[210px] overflow-hidden h-[210px] rounded-t-[1px] z-[3] lg:w-[185px] lg:h-[185px] sm:w-[150px] sm:h-[150px]  mq840:w-[185px] mq840:h-[185px] ">
        <img
          className="absolute top-[0px] left-[0px] w-full h-full object-cover "
          alt="thumbnail"
          src={
            Array.isArray(product?.images)
              ? product.images[0]
              : product.image
              ? product.image
              : null
          }
        />
      </div>
      <div className="self-stretch flex flex-col mt-1.5 items-start justify-start z-[3] text-gray-200">
        <div className="flex flex-col items-start justify-start pt-0 px-0 pb-1.5">
          {isBuying && (
            <div className="flex flex-row items-center justify-start pt-0 pb-0.5 pr-2 pl-1.5 gap-[2px] sm:flex sm:gap-[2px] sm:pl-1.5 sm:pr-2 sm:box-border">
              <img
                className="relative w-3 h-3 overflow-hidden shrink-0"
                alt=""
                src="/mapmarker2-2.svg"
              />
              <p className="m-0 relative leading-[130%] font-regular text-xs w-[150px] h-[16px] inline-block shrink-0 whitespace-nowrap overflow-hidden text-ellipsis sm:w-[130px] sm:h-[16px] sm:text-xs">
                {formattedAddress}
              </p>
            </div>
          )}
          <p className="m-0 items-start justify-start py-0 px-2 relative leading-[130%] font-regular whitespace-nowrap overflow-hidden text-ellipsis text-regular w-[198px] display:inline-block shrink-0 lg:w-[172px] sm:text-regular sm:w-[138px]">
            {product?.title}
          </p>
        </div>
        <div className="self-stretch bg-black flex flex-row inline-block items-center justify-start py-2 px-3 gap-[7px] text-mini text-text-white hover:rounded-none sm:gap-[5px] sm:py-1.5 sm:px-2 sm:box-border">
          <span className="relative font-general-text leading-[130%] text-regular sm:text-regular">
            &#8377;{numberWithIndianCommas(product?.price)}
          </span>
          <span className="relative text-xs leading-[130%] whitespace-nowrap overflow-hidden text-ellipsis lg:text-xs sm:text-xs">
            + Free shipping
          </span>
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
